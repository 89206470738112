import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Deposit.module.sass'
import { Icon } from '../Icon/Icon'
import { useAppSelector } from 'app/AppStore'
import { selectPspList } from 'entities/psp/model/slice'
import { useOtherPspQuery } from 'entities/psp/api/pspApi'
import { selectMe } from 'entities/session/model/slice'
import styled from 'styled-components'
import { priceToView } from 'shared/utils/format/currency'
import { PaymentCard } from 'features/buy/ui/PaymentInfo/PaymentCard'
import { useCreateTransactionMutation } from 'entities/accounts/api/accountsApi'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'
import QRCode from 'qrcode.react'

const OtherMethods = ({
  items,
  handleClickBack,
  handleClickOtherItem,
  account,
}) => {
  const intl = useIntl()

  return (
    <>
      <div className={cn('h4', styles.title)}>
        <button className={styles.back} onClick={() => handleClickBack()}>
          <Icon name='arrow-left' size='32' />
        </button>
        {intl.formatMessage({
          id: 'deposit',
          defaultMessage: 'Deposit',
        })}{' '}
      </div>
      <div className={styles.wrap}>
        <div className={styles.category}>
          {intl.formatMessage({
            id: 'totalBalance',
            defaultMessage: 'Total Balance',
          })}
        </div>
        <div className={styles.details}>
          <div className={styles.currency}>
            {priceToView(account.balance, account.currency)}
          </div>
        </div>
      </div>
      <OtherWrapper>
        {items.map((item, idx) => (
          <li className={styles.liWrapper} key={idx} onClick={() => handleClickOtherItem(item)}>
            <img src={item.logo} alt={item.title} />
          </li>
        ))}
      </OtherWrapper>
    </>
  )
}

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(() => {
    alert('Copied to clipboard!');
  }).catch(err => console.error('Failed to copy:', err));
};

const BankDetails: React.FC<{ data: any }> = ({ data }) => {
  const bankFields = [
    { key: 'bank_number', label: 'Account Number' },
    { key: 'bank_holder', label: 'Account Holder' },
    { key: 'bank_iban', label: 'IBAN' },
    { key: 'bank_swift', label: 'Bank Swift Code' },
    { key: 'bank_recipient_address', label: 'Recipient Address' },
    { key: 'bank_routing_number', label: 'Routing Number' },
    { key: 'bank_beneficiary_name', label: 'Bank Beneficiary Name' },
    { key: 'bank_address', label: 'Bank Address' },
    { key: 'bank_code', label: 'Bank Code' },
    { key: 'bank_description', label: 'Description' }
  ];

  // Фильтруем только заполненные поля
  const filteredFields = bankFields.filter(field => data[field.key]);

  if (filteredFields.length === 0) {
    return <div>No bank details available.</div>;
  }

  return (
      <div style={{
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '12px',
        maxWidth: '600px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',

        fontFamily: 'Arial, sans-serif'
      }}>
        <h2 style={{ marginBottom: '20px', textAlign: 'center', }}>
          Bank Details
        </h2>

        {filteredFields.map(field => (
            <div key={field.key} style={{
              marginBottom: '12px',
              padding: '10px',
              borderRadius: '8px',

              boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <div>
                <strong>{field.label}: </strong> {data[field.key]}
              </div>
              <button
                  onClick={() => copyToClipboard(data[field.key])}
                  className="button"
                  onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#0056b3')}
                  onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#007bff')}
              >
                Copy
              </button>
            </div>
        ))}
      </div>
  );
};

export const Deposit = ({
  account,
  goNext = () => null,
  goBack = () => null,
}) => {
  const intl = useIntl()
  const psp = useAppSelector(selectPspList)
  const me = useAppSelector(selectMe)
  const [createBalance] = useCreateTransactionMutation()
  const { data } = useOtherPspQuery({ brand_id: me.brand_id })

  const [activeIndex, setActiveIndex] = useState(0)
  const [visibleBody, setVisibleBody] = useState(false)
  const [visibleHead, setVisibleHead] = useState(true)
  const [activeTitle, setActiveTitle] = useState('')

  const [state, setState] = useState({
    card_number: '',
    card_holder: '',
    expiration: '',
    cvc: '',
    amount: 0,
    currency: 'USD',
  })

  const onChange = e => {
    setState(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleClick = (value, item) => {
    setActiveIndex(value)
    setActiveTitle(item)
    setVisibleBody(true)
    setVisibleHead(false)
    goNext()
  }

  const handleClickBack = () => {
    setVisibleBody(false)
    setVisibleHead(true)
    goBack()
  }

  const handleCreateBalance = async () => {
    const data = {
      account_id: account.id,
      transaction_mode: 1, // 1 - deposit 2 - withdrawal
      amount: state.amount,
      type: 1, // 1 - balance, 2 - credit
      transaction_type: 1, // 1-real/2-fake
      currency: state.currency,

      card_data: state,
    }
    try {
      await createBalance(data).unwrap()
      handleClickBack()
      toast.success('You create deposit successfully!', { theme: 'dark' })
    } catch (error) {
      console.error(error)
    }
  }

  const handleClickOtherItem = item => {
    window.open(item.link, '_blank')
  }

  const copyToClipboard = text => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success('Copied to clipboard!', { theme: 'dark' });
      })
      .catch(error => {
        console.error('Failed to copy: ', error);
      });
  };

  const items = [
    {
      title: 'Crypto',
      content: [
        {
          title: `on ${activeTitle?.title}`,
          note: 'deposits are available after 5-10 min.',
          color: '#9757D7',
          qrCode: '/images/content/qr-code-1.jpg',
        },
      ],
    },
    {
      title: 'USDT ERC20 Wallet',
      content: [
        {
          title: 'USDT ERC20 Wallet',
          note: 'deposits are available after 5-10 min.',
          color: '#3772FF',
          qrCode: '/images/content/qr-code-2.jpg',
        },
      ],
    },
    {
      title: 'Credit/Debit Card cc-ext',
      content: [
        {
          title: 'Credit/Debit Card cc-ext',
          note: 'deposits are available after 5-10 min.',
          color: '#3772FF',
          qrCode: '/images/content/qr-code-2.jpg',
        },
      ],
    },
    {
      title: 'Other',
      content: [
        {
          title: 'Other',
          note: 'USDTTRON deposits are available after 0 network confirmations.',
          color: '#3772FF',
          qrCode: '/images/content/qr-code-2.jpg',
        },
      ],
    },
  ]

  console.log(activeTitle)

  return (
    <div className={styles.deposit}>
      {visibleHead && (
        <div className={styles.head}>
          <div className={styles.icon}>
            <Icon name='wallet' size='24' />
          </div>
          <div className={styles.info}>
            {intl.formatMessage({
              id: 'selectMethods',
              defaultMessage: 'Select methods',
            })}
          </div>
          <div className={styles.btns}>
            {[...psp, { title: 'Other', type: 3 }]?.map((x, index) => (
              <button
                className={cn('button', styles.button)}
                key={index}
                onClick={() => handleClick(index, x)}
              >
                {x.title}
              </button>
            ))}
          </div>
        </div>
      )}
      {visibleBody && (
        <div className={styles.body}>
          {activeTitle?.title === 'Other' ? (
            <OtherMethods
              items={data}
              handleClickBack={handleClickBack}
              handleClickOtherItem={handleClickOtherItem}
              account={account}
            />
          ) : (
            items[activeIndex].content.map((x, index) => (
              <div className={styles.item} key={index}>
                <div className={cn('h4', styles.title)}>
                  <button
                    className={styles.back}
                    onClick={() => handleClickBack()}
                  >
                    <Icon name='arrow-left' size='32' />
                  </button>
                  {intl.formatMessage({
                    id: 'deposit',
                    defaultMessage: 'Deposit',
                  })}{' '}
                </div>
                <div className={styles.subtitle}>{x.title}</div>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  <Icon name='wallet' size='24' />
                </div>
                <div className={styles.wrap}>
                  <div className={styles.category}>
                    {intl.formatMessage({
                      id: 'totalBalance',
                      defaultMessage: 'Total Balance',
                    })}
                  </div>
                  <div className={styles.details}>
                    <div className={styles.currency}>
                      {priceToView(account.balance, account.currency)}
                    </div>
                  </div>
                </div>
                {activeTitle?.psp_type === 2 ? (
                  <PaymentCard
                    account={account}
                    onChange={onChange}
                    state={state}
                    onSave={handleCreateBalance}
                  />
                ) : activeTitle?.psp_type === 3 ? (
                    <BankDetails data={activeTitle} />
                ) : (
                  <>
                    <div
                      className={styles.preview}
                      onClick={() => copyToClipboard(activeTitle?.wallet)}
                    >
                      <QRCode value={activeTitle?.wallet} size={128} />
                    </div>
                    <div className={styles.code}>
                      <div className={styles.number}>
                        {activeTitle?.wallet || '-'}
                      </div>
                      <button
                        onClick={() => copyToClipboard(activeTitle?.wallet)}
                        className={styles.copy}
                      >
                        <Icon name='copy' size='24' />
                      </button>
                    </div>
                    <div className={styles.note}>{x.note}</div>
                  </>
                )}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  )
}

const OtherWrapper = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 20px;
`

const OtherItem = styled.li`

`
