import React, { Suspense, useEffect } from 'react'

import { Provider as ReduxProvider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { appRouter } from 'app/AppRouter'
import { Localization } from 'services/Localization/Localization'

import { appStore, persistedStore } from 'app/AppStore'
import ErrorBoundary from 'services/ErrorBoundary/ErrorBoundary'
import { Loader } from 'shared/ui'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { removeSpaces } from 'shared/utils/string/removeSpaces'
import clickAudio from 'shared/audio/click.mp3'
import {sessionApi} from "../entities/session";
import * as Sentry from '@sentry/react'
import { Fallback } from 'services/ErrorBoundary/FallBack'

export const App = () => {
  document.title = process.env.REACT_APP_DEFAULT_BRAND_TITLE || process.env.REACT_APP_DEFAULT_BRAND

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.href = `/config/${removeSpaces(
      process.env.REACT_APP_DEFAULT_BRAND,
    )}/images/favicon.ico`
  }, [])

  const playIncomeMessage = () => {
    const audio = new Audio(clickAudio)
    audio.play()
  }

  useEffect(() => {
    const playAudio = () => {
      playIncomeMessage()
    }

    window.addEventListener('click', playAudio)

    return () => {
      window.removeEventListener('click', playAudio)
    }
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={() => <Fallback />}>
      <ReduxProvider store={appStore}>
        <PersistGate loading={null} persistor={persistedStore}>
          <Suspense
            fallback={
              <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
                <Loader className='' color='' />
              </div>
            }
          >
            <Localization>
              <ToastContainer />
              <RouterProvider router={appRouter()} />
            </Localization>
          </Suspense>
        </PersistGate>
      </ReduxProvider>
    </Sentry.ErrorBoundary>
  )
}
