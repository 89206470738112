import React, {type ReactElement, useEffect} from 'react'
import { Navigate, createBrowserRouter } from 'react-router-dom'
import { lazyWithRetry } from '../shared/routing/lazyWithRetry'
import * as Sentry from '@sentry/react'

import { useAppSelector, appStore } from 'app/AppStore'

import { getQueryParams } from 'shared/utils/browser/getQueryParams'

import {selectIsAuthorized, sessionApi} from 'entities/session'

// import { featureToggleLoader } from 'entities/App'

import { autologinThunk, selectMe } from 'entities/session/model/slice'

// import { useSubscribeToEventsQuery } from 'services/SocketService/SocketService'

const LoginPage = lazyWithRetry(() => import('pages/LoginPage'))

const NewPasswordPage = lazyWithRetry(() => import('pages/NewPasswordPage'))

const RegistrationPage = lazyWithRetry(() => import('pages/SignUpPage'))

const ErrorPage = lazyWithRetry(() => import('pages/ErrorPage'))

const ForgotPasswordPage = lazyWithRetry(
  () => import('pages/ForgotPasswordPage'),
)

// const HomePage = lazyWithRetry(() => import('pages/HomePage'))

const ExchangePage = lazyWithRetry(() => import('pages/ExchangePage'))

const BuyPage = lazyWithRetry(() => import('pages/Buy'))

const SellPage = lazyWithRetry(() => import('pages/SellPage'))

const DepositPage = lazyWithRetry(() => import('pages/DepositPage'))

// const MarketPage = lazyWithRetry(() => import('pages/MarketPage'))

// const LearnPage = lazyWithRetry(() => import('pages/Learn'))

const AutoLoginPage = lazyWithRetry(() => import('pages/AutoLogin'))

const ActivityPage = lazyWithRetry(() => import('pages/ActivityPage'))

const WaalletOverviewPage = lazyWithRetry(
  () => import('pages/WalletOverviewPage'),
)

const WaalletOverviewDetailsPage = lazyWithRetry(
  () => import('pages/WalletOverviewDetailsPage'),
)

const WalletMarginPage = lazyWithRetry(() => import('pages/WalletMarginPage'))

const FiatAndSpotPage = lazyWithRetry(() => import('pages/FiatAndSpotPage'))

const ProfileInfoPage = lazyWithRetry(() => import('pages/ProfileInfoPage'))

const ReferralsPage = lazyWithRetry(() => import('pages/Referrals'))

const ApiKeysPage = lazyWithRetry(() => import('pages/ApiKeysPage'))

const SessionAndLoginHistoryPage = lazyWithRetry(
  () => import('pages/SessionAndLoginHistoryPage'),
)

const NotificationPage = lazyWithRetry(() => import('pages/NotificationsPage'))

const TwoFaPage = lazyWithRetry(() => import('pages/TwoFa'))

const ChangePasswordPage = lazyWithRetry(
  () => import('pages/ChangePasswordPage'),
)

const SupportPage = lazyWithRetry(() => import('pages/SupportPage'))

const DocumentsPage = lazyWithRetry(() => import('pages/DocumentsPage'))

const PrivacyPolicyPage = lazyWithRetry(() => import('pages/PrivacyPolicy'))

const PolicyAMLPage = lazyWithRetry(() => import('pages/PolicyAMLPage'))

const PolicyKYCPage = lazyWithRetry(() => import('pages/PolicyKYCPage'))

type GuestGuardProps = {
  children: ReactElement
}

type AuthGuardProps = {
  children: ReactElement
}

function GuestGuard({ children }: GuestGuardProps) {
  const isAuthorized = useAppSelector(selectIsAuthorized)
  const authUser = useAppSelector(selectMe)

  if (!isAuthorized) return <Navigate to='/sign-in' />

  Sentry.init({
    release: 'Cappara',
    dsn: 'https://afc6784d84b04b804d2f936cda1f60c0@o4508614170640384.ingest.de.sentry.io/4508632882282576',
    ignoreErrors: [
      'Error: Network Error',
      'Error: ResizeObserver loop limit exceeded',
      'NetworkError',
      'ResizeObserver loop limit exceeded',
      'Non-Error promise rejection captured with keys',
    ],
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'console') {
        return null
      }
      return breadcrumb
    },

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      Sentry.browserTracingIntegration(),
      // Sentry.replayIntegration(),
    ],
  })

  if (authUser?.id) {
    Sentry.setUser({
      username: `%${authUser?.firt_name} ${authUser?.last_name}`,
      id: authUser?.id,
      company_name: authUser?.company_data?.company_name,
      company_id: authUser?.company_id,
      app_version: 'unknown',
    })
  }

  return children
}

function AuthGuard({ children }: AuthGuardProps) {
  const isAuthorized = useAppSelector(selectIsAuthorized)

  if (isAuthorized) return <Navigate to='/profile-info' />

  return children
}

export const appRouter = () => {
  // if (window.location.search) {
  //   const query = getQueryParams()
  //
  //   appStore.dispatch(autologinThunk(query))
  // }

  return createBrowserRouter([
    {
      element: (
        <AuthGuard>
          <LoginPage />
        </AuthGuard>
      ),
      errorElement: <ErrorPage />,
      path: '/sign-in',
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
    {
      element: (
        <AuthGuard>
          <RegistrationPage />
        </AuthGuard>
      ),
      errorElement: <ErrorPage />,
      path: '/sign-up',
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
    {
      element: (
        <AuthGuard>
          <ForgotPasswordPage />
        </AuthGuard>
      ),
      errorElement: <ErrorPage />,
      path: '/forgot-password',
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },

    {
      element: (
        <AuthGuard>
          <NewPasswordPage />
        </AuthGuard>
      ),
      errorElement: <ErrorPage />,
      path: '/change-forgot-password',
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },

    {
      path: '/',
      element: (
        <GuestGuard>
          <ExchangePage />
          {/* <HomePage /> */}
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return console.log('test')
      // },
    },
    {
      path: '/policy',
      element: (
        <GuestGuard>
          <PrivacyPolicyPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/buy',
      element: (
        <GuestGuard>
          <BuyPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
    {
      path: '/sell',
      element: (
        <GuestGuard>
          <SellPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
    {
      path: '/deposit',
      element: (
        <GuestGuard>
          <DepositPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
    {
      path: '/policy-aml',
      element: (
        <GuestGuard>
          <PolicyAMLPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/policy-kyc',
      element: (
        <GuestGuard>
          <PolicyKYCPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
    },
    // },
    {
      path: '/cfd/secured-auto-login',
      element: (
          <AutoLoginPage />
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
    {
      path: '/activity',
      element: (
        <GuestGuard>
          <ActivityPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
    {
      path: '/wallet-overview',
      element: (
        <GuestGuard>
          <WaalletOverviewPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
    {
      path: '/wallet-overview/:id',
      element: (
        <GuestGuard>
          <WaalletOverviewDetailsPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
    {
      path: '/wallet-margin',
      element: (
        <GuestGuard>
          <WalletMarginPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
    {
      path: '/fiat-and-spot',
      element: (
        <GuestGuard>
          <FiatAndSpotPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
    {
      path: '/profile-info',
      element: (
        <GuestGuard>
          <ProfileInfoPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },

    {
      path: '/referrals',
      element: (
        <GuestGuard>
          <ReferralsPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },

    {
      path: '/api-keys',
      element: (
        <GuestGuard>
          <ApiKeysPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
    {
      path: '/sessions-and-login-history',
      element: (
        <GuestGuard>
          <SessionAndLoginHistoryPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
    {
      path: '/notifications',
      element: (
        <GuestGuard>
          <NotificationPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
    {
      path: '/2fa',
      element: (
        <GuestGuard>
          <TwoFaPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
    {
      path: '/change-password',
      element: (
        <GuestGuard>
          <ChangePasswordPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
    {
      path: '/support',
      element: (
        <GuestGuard>
          <SupportPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },

    {
      path: '/documents',
      element: (
        <GuestGuard>
          <DocumentsPage />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      // loader: async () => {
      //   return await featureToggleLoader(appStore.dispatch)
      // },
    },
  ])
}
