import React, { useState } from 'react'
import cn from 'classnames'
import { NavLink, Link, useLocation } from 'react-router-dom'
import styles from './Wallet.module.sass'
import { Icon } from '../Icon/Icon'
import { Modal } from '../Modal/Modal'
import { Withdraw } from '../Withdraw/Withdraw'
import { Transfer } from '../Transfer/Transfer'
import { Autotrader } from '../Autotrader/Autotrader'
import { useAppSelector } from 'app/AppStore'
import {
  useTransferMutation,
  useAccountsQuery,
  useCreateTransactionMutation,
} from 'entities/accounts/api/accountsApi'
import { selectAccountDropdownList } from 'entities/accounts/model/slice'
import { useIntl } from 'react-intl'
import { selectAppSettings } from 'entities/session/model/slice'

export const Wallet = ({ className, children }) => {
  const { pathname } = useLocation()
  const intl = useIntl()

  const accountsList = useAppSelector(selectAccountDropdownList)
  const appSettings = useAppSelector(selectAppSettings)
  const { refetch } = useAccountsQuery()
  const [createTransfer] = useTransferMutation()
  const [createBalance] = useCreateTransactionMutation()
  const [visibleWithdraw, setVisibleWithdraw] = useState(false)
  const [visibleTransfer, setVisibleTransfer] = useState(false)
  const [visibleMenu, setVisibleMenu] = useState(false)
  const [visibleAutoTrader, setVisibleAutoTrader] = useState(false)

  const messages = {
    overview: intl.formatMessage({
      id: 'overview',
      defaultMessage: 'Overview',
    }),
    makeTransfer: intl.formatMessage({
      id: 'makeTransfer',
      defaultMessage: 'Make Transfer',
    }),
    details: intl.formatMessage({
      id: 'details',
      defaultMessage: 'Details',
    }),
    buy: intl.formatMessage({
      id: 'buy',
      defaultMessage: 'Buy',
    }),
    sell: intl.formatMessage({
      id: 'sell',
      defaultMessage: 'Sell',
    }),
    withdrawal: intl.formatMessage({
      id: 'withdrawal',
      defaultMessage: 'Withdrawal',
    }),
    deposit: intl.formatMessage({
      id: 'deposit',
      defaultMessage: 'Deposit',
    }),
    autoTrader: intl.formatMessage({
      id: 'autoTrader',
      defaultMessage: 'Auto Trader',
    }),
  }

  const navigation = [
    {
      title: messages.overview,
      color: '#23262F',
      url: '/wallet-overview',
      visible: true,
    },
    {
      title: messages.makeTransfer,
      icon: 'arrow-next',
      visible: !!appSettings?.transfer_btn,
    },
    {
      title: messages.buy,
      icon: 'wallet',
      url: '/buy',
      visible: !!appSettings?.buy_btn,
    },
    {
      title: messages.sell,
      icon: 'sell',
      url: '/sell',
      visible: !!appSettings?.sell_btn,
    },
  ]

  const handleClickTransfer = () => {
    setVisibleTransfer(true)
  }

  const handleCreateBalance = async data => {
    try {
      await createBalance(data).unwrap()
      // refetch(accId)
    } catch (error) {
      console.error(error)
    }
  }

  const onCreateTransfer = async data => {
    try {
      await createTransfer(data).unwrap()
      setVisibleTransfer(false)
      refetch()
    } catch (error) {
      console.error(error)
    }
  }

  const activeItem = navigation.find(x => pathname.includes(x.url))

  return (
    <>
      <div className={cn(className, styles.wallet)}>
        <div className={styles.sidebar}>
          <div className={cn(styles.group, { [styles.active]: visibleMenu })}>
            <div
              className={styles.top}
              onClick={() => setVisibleMenu(!visibleMenu)}
            >
              <div
                className={styles.bg}
                style={{ backgroundColor: activeItem.color }}
              ></div>
              {activeItem.title}
            </div>
            <div className={styles.menu}>
              {navigation
                .filter(item => item.visible)
                .map((item, index) =>
                  item.url ? (
                    <NavLink
                      className={cn(styles.item, {
                        [styles.separator]: item.separator,
                      })}
                      activeClassName={styles.active}
                      to={item.url}
                      key={index}
                    >
                      {item.color && (
                        <div
                          className={styles.bg}
                          style={{ backgroundColor: item.color }}
                        ></div>
                      )}
                      {item.icon && <Icon name={item.icon} size='20' />}
                      {item.title}
                    </NavLink>
                  ) : (
                    <button
                      className={cn(styles.item, {
                        [styles.separator]: item.separator,
                      })}
                      key={index}
                      onClick={handleClickTransfer}
                      disabled={accountsList.length < 2}
                    >
                      {item.icon && <Icon name={item.icon} size='20' />}
                      {item.title}
                    </button>
                  ),
                )}
            </div>
          </div>
          <div className={styles.btns}>
            {!!appSettings?.deposit_btn && <Link
              className={cn('button button-small', styles.button)}
              to='/deposit'
            >
              {messages.deposit}
            </Link>}
            {!!appSettings?.withdrawal_btn && (
              <button
                className={cn('button-stroke button-small', styles.button)}
                onClick={() => setVisibleWithdraw(!visibleWithdraw)}
              >
                {messages.withdrawal}
              </button>
            )}
            {!!appSettings?.autotrader_btn && (
              <button
                className={cn('button-stroke button-small', styles.button)}
                onClick={() => setVisibleAutoTrader(!visibleAutoTrader)}
              >
                {messages.autoTrader}
              </button>
            )}

            {!!appSettings?.transfer_btn && (
              <button
                className={cn('button-stroke button-small', styles.button)}
                onClick={handleClickTransfer}
                disabled={accountsList?.length < 2}
              >
                {messages.makeTransfer}
              </button>
            )}
          </div>
        </div>
        <div className={styles.wrapper}>{children}</div>
      </div>
      <Modal
        visible={visibleWithdraw}
        onClose={() => setVisibleWithdraw(false)}
      >
        <Withdraw
          account={null}
          accounts={accountsList}
          onCreate={handleCreateBalance}
        />
      </Modal>
      <Modal
        visible={visibleTransfer}
        onClose={() => setVisibleTransfer(false)}
      >
        <Transfer
          accounts={accountsList}
          account={null}
          onCreate={onCreateTransfer}
        />
      </Modal>
      <Modal
        visible={visibleAutoTrader}
        onClose={() => setVisibleAutoTrader(false)}
      >
        <Autotrader onCancel={() => setVisibleAutoTrader(false)} />
      </Modal>
    </>
  )
}
