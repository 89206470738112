import { ORDER_VOLUME_TYPE } from 'entities/orders/lib/constants'

type Direction = 'BUY' | 'SELL'
type InstrumentType =
  | 'crypto'
  | 'forex'
  | 'stocks'
  | 'metals'
  | 'indices'
  | 'commodities'
type OrderVolumeType =
  (typeof ORDER_VOLUME_TYPE)[keyof typeof ORDER_VOLUME_TYPE]

interface ExchangeRates {
  [key: string]: number
}

export const getPipSize = (symbol: string): number => {
  const quoteCurrency = symbol.slice(3, 6).toUpperCase()

  return quoteCurrency === 'JPY' ? 0.01 : 0.0001
}

const calculateForexPipValue = (
  lotSize: number,
  pip: number,
  quoteToUSD: number,
): number => {
  if (!quoteToUSD || quoteToUSD <= 0) {
    throw new Error('Invalid quoteToUSD value for pip calculation.')
  }
  return (lotSize * pip) / quoteToUSD
}

const adjustVolume = (
  volume: number,
  entryPrice: number,
  unit: OrderVolumeType,
  type: InstrumentType,
  lotSize: number,
): number => {
  const adjusters = {
    crypto: (v: number) =>
      unit === ORDER_VOLUME_TYPE.CURRENCY ? v / entryPrice : v,
    forex: (v: number) =>
      unit === ORDER_VOLUME_TYPE.LOTS || unit === ORDER_VOLUME_TYPE.UNIT
        ? v * lotSize
        : v / entryPrice,
    stocks: (v: number) =>
      unit === ORDER_VOLUME_TYPE.LOTS || unit === ORDER_VOLUME_TYPE.UNIT
        ? v * lotSize
        : v / entryPrice,
    metals: (v: number) =>
      unit === ORDER_VOLUME_TYPE.LOTS || unit === ORDER_VOLUME_TYPE.UNIT
        ? v * lotSize
        : v / entryPrice,
    indices: (v: number) =>
      unit === ORDER_VOLUME_TYPE.LOTS || unit === ORDER_VOLUME_TYPE.UNIT
        ? v * lotSize
        : v,
    commodities: (v: number) =>
      unit === ORDER_VOLUME_TYPE.LOTS || unit === ORDER_VOLUME_TYPE.UNIT
        ? v * lotSize
        : v / entryPrice,
  }

  return adjusters?.[type]?.(volume)
}

export const calculatePNL = (
  entryPrice: number,
  currentPrice: number,
  volume: number,
  direction: Direction,
  unit: OrderVolumeType,
  type: InstrumentType,
  lotSize: number,
  symbol: string,
  rates: ExchangeRates,
  // pip: number = 0.0001,
): number | string => {
  if (isNaN(entryPrice) || isNaN(currentPrice) || isNaN(volume)) {
    return 'Invalid input'
  }

  const adjustedVolume = adjustVolume(volume, entryPrice, unit, type, lotSize)
  const directionMultiplier =
    direction !== 'BUY'
      ? entryPrice < currentPrice
        ? -1
        : 1
      : entryPrice < currentPrice
      ? 1
      : -1

  let pnl = 0

  if (type === 'forex') {
    // 1. Определяем курс котировки к USD (quoteToUSD)
    const quoteCurrency = symbol.slice(3, 6).toUpperCase()
    const quoteToUSD = rates[quoteCurrency] || 1

    if (!quoteToUSD || quoteToUSD <= 0) {
      throw new Error('Invalid quoteToUSD value for pip calculation.')
    }

    const pip = getPipSize(symbol)

    // 2. Рассчитываем стоимость одного пипса
    const pipValue = calculateForexPipValue(adjustedVolume, pip, quoteToUSD)

    // 3. Рассчитываем разницу в пипсах
    const pipDifference = Math.abs(currentPrice - entryPrice) / pip

    // 4. Рассчитываем PnL
    pnl = pipDifference * pipValue
  } else {
    // Для других инструментов (например, акции, металлы)
    pnl =
      direction === 'SELL'
        ? (entryPrice - currentPrice) * adjustedVolume
        : (currentPrice - entryPrice) * adjustedVolume
  }

  if (type === 'metals') {
    return pnl.toFixed(6)
  }

  return (pnl * directionMultiplier).toFixed(6)
}
