import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { rootReducer } from './rootReducer'
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux'
import { sessionSlice, sessionApi } from 'entities/session'
import { chartsSlice } from 'entities/charts/model/slice'
import { baseApi } from 'shared/api'
import { invalidateAccessTokenListener } from 'features/auth/invalidateAccessToken'
import { loginSuccessListener } from 'entities/session/api/sessionMiddleware'
import { ordersListener } from 'entities/orders/api/ordersMeddlewaer'
import { getQueryParams } from 'shared/utils/browser/getQueryParams'
import {useEffect} from "react";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [sessionSlice.name, chartsSlice.name],
}

export function makeStore() {
  const store = configureStore({
    reducer: persistReducer(
      persistConfig,
      rootReducer,
    ) as unknown as typeof rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .concat(baseApi.middleware, invalidateAccessTokenListener.middleware)
        .prepend(loginSuccessListener.middleware, ordersListener.middleware),
  })

  setupListeners(store.dispatch)

  return store
}

export const appStore = makeStore()
export const persistedStore = persistStore(appStore, null, () => {
  const token = appStore.getState().session.accessToken

  const sendIsActiveReq = async () => {
    if (token) {
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/cfd/is-active`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      } catch (error) {
        console.error('Failed to send is-active request:', error)
      }
    }
  }

  // Запуск интервала
  let intervalId: NodeJS.Timeout | null = null

  const startActiveInterval = () => {
    if (!intervalId) {
      sendIsActiveReq()
      intervalId = setInterval(sendIsActiveReq, 15000)
    }
  }

  const stopActiveInterval = () => {
    if (intervalId) {
      clearInterval(intervalId)
      intervalId = null
    }
  }

  // Обработчик изменения видимости страницы
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      startActiveInterval()
    } else {
      stopActiveInterval()
    }
  }

  if (token) {
    appStore.dispatch(sessionApi.endpoints.me.initiate())
    if (document.visibilityState === 'visible') {
      startActiveInterval()
    }

    // Подписываемся на события видимости
    document.addEventListener('visibilitychange', handleVisibilityChange)
  }

  return () => {
    document.removeEventListener('visibilitychange', handleVisibilityChange)
    stopActiveInterval()
  }
})

export type RootState = ReturnType<typeof appStore.getState>
export type AppDispatch = typeof appStore.dispatch

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useAppDispatch = useDispatch<AppDispatch>
