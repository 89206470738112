import { createSlice, createSelector } from '@reduxjs/toolkit'

import { RootState } from 'app/AppStore'

import { ordersApi } from '../api/ordersApi'
import { IOrdersData } from '../types'
import { calculateMargin } from 'shared/utils/tranding/calculateMargin'

type ordersSliceState = {
  active: IOrdersData[]
  current: IOrdersData
  history: IOrdersData[]
  freeMargin: number
  margin: number
}

const initialState: ordersSliceState = {
  active: [],
  current: {},
  history: [],
  freeMargin: 0,
  margin: 0,
}

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    clearData: state => {
      state.active = []
      state.current = {}
      state.history = []
    },
    setOrder: (state, { payload }) => {
      state.active = state.active.map(item =>
        item.id === payload.id ? payload : item,
      )
      state.history = state.history.map(item =>
        item.id === payload.id ? payload : item,
      )
    },
    setNewOrder: (state, { payload }) => {
      state.active = [payload, ...state.active]
    },

    setActiveOrders: (state, { payload }) => {
      state.active = payload
    },
    onChangeOrderAction: (state, { payload }) => {
      state.active[payload.index][payload.name] = payload.value
    },

    setFreeMarginOrder: (state, { payload }) => {
      state.freeMargin = payload
    },

    setMarginOrder: (state, { payload }) => {
      state.margin = payload
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      ordersApi.endpoints.orders.matchFulfilled,
      (state: ordersSliceState, { payload }) => {
        state.active = payload
      },
    )
    builder.addMatcher(
      ordersApi.endpoints.ordersHistory.matchFulfilled,
      (state: ordersSliceState, { payload }) => {
        state.history = payload
      },
    )
    builder.addMatcher(
      ordersApi.endpoints.createOrder.matchFulfilled,
      (state: ordersSliceState, { payload }) => {
        state.active = [payload, ...state.active]
        // state.active.push(payload)
      },
    )
    builder.addMatcher(
      ordersApi.endpoints.updateOrder.matchFulfilled,
      (state: ordersSliceState, { payload }) => {
        if (payload.status === 2) {
          state.history = [payload, ...state.history]
          state.active = state.active.filter(item => item.id !== payload.id)
        } else {
          state.active = state.active.map(item =>
            item.id === payload?.id ? payload : item,
          )
        }
      },
    )
  },
})

export const selectCurrentChart = (state: RootState) => state.session.me

export const activeOrders = (state: RootState) => state.orders.active

export const historyOrders = (state: RootState) => state.orders.history

export const freeMargin = (state: RootState) => state.orders.freeMargin

export const margin = (state: RootState) => state.orders.margin

export const selectLeverage = (state: RootState) =>
  state?.accounts?.current?.sg_settings

export const selectSymbols = (state: RootState) => state?.symbols?.symbols

export const selectActiveOrders = createSelector(
  [activeOrders],
  orders => orders,
)

export const selectFreeMargin = createSelector(
  [freeMargin],
  freeMargin => freeMargin,
)

// export const selectMargin = createSelector(
//   [margin],
//   margin => margin,
// )

export const selectClosedOrdersPnlTotal = createSelector(
  [historyOrders],
  orders => orders.reduce((total, item) => (total += item.pnl), 0),
)

export const selectActiveOrdersPnlTotal = createSelector(
  [activeOrders],
  orders => orders.reduce((total, item) => (total += item.pnl), 0),
)

export const selectHistoryOrders = createSelector(
  [historyOrders],
  orders => orders,
)

export const selectMargin = createSelector(
  [activeOrders, selectLeverage, selectSymbols],
  calculateMargin,
)

export const {
  clearData,
  onChangeOrderAction,
  setOrder,
  setNewOrder,
  setActiveOrders,
  setFreeMarginOrder,
  setMarginOrder,
} = ordersSlice.actions
