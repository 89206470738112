import { createSlice, createSelector } from '@reduxjs/toolkit'

import { RootState } from 'app/AppStore'

import { getValueFromLS, setValueToLS } from 'shared/hooks/useLocalStorage'

const ACTIVE_CHART = 'active_chart'

const CHARTS_LIST = 'charts_list'

interface ITrade {
  index: number
  symbol: string
}

type trandingSliceState = {
  trades: ITrade[]
  current: ITrade
}

const initialState: trandingSliceState = {
  trades: getValueFromLS(CHARTS_LIST)?.list || [],
  current: getValueFromLS(ACTIVE_CHART) || {},
}

export const trandingSlice = createSlice({
  name: 'tranding',
  initialState,
  reducers: {
    clearData: state => {
      state.trades = []
      state.current = {}
    },

    setActiveTrade: (state, { payload }) => {

      if (state.current && state.current.symbol === payload.symbol) {
        return;
      }

      const existTrade = state.trades.find(
        item => item.symbol === payload.symbol,
      )

      if (!existTrade) {
        setValueToLS(CHARTS_LIST, { list: [...state.trades, payload] })
        state.trades = [...state.trades, payload]
      }
      state.current = payload
      setValueToLS(ACTIVE_CHART, payload)
    },
    removeActiveTrade: (state, { payload }) => {
      const filtered = state.trades?.filter(
        item => item.symbol !== payload.symbol,
      )
      setValueToLS(CHARTS_LIST, { list: filtered })
      setValueToLS(ACTIVE_CHART, filtered?.[0])
      state.trades = filtered
      state.current = filtered?.[0] || {}
    },
  },
  extraReducers: builder => {},
})

export const selectCurrentChart = (state: RootState) => state.session.me

export const activeTradesList = (state: RootState) => state.tranding.trades

export const currentTrade = (state: RootState) => state.tranding.current

export const selectCurrentTrade = createSelector([currentTrade], trade => trade)

export const selectTradesList = createSelector(
  [activeTradesList],
  trades => trades,
)

export const selectTradesListIds = createSelector([activeTradesList], trades =>
  trades.map(item => item.id),
)

export const { clearData, setActiveTrade, removeActiveTrade } =
  trandingSlice.actions
