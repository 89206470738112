export const messages = {
  en: {
    _name: 'English',
    policy: 'Privacy Policy',
    policyAML: 'AML Policy',
    policyKYC: 'KYC Policy',
    locense: 'License',
    view_all: 'View All',
    sell_btn: 'Sell',
    buy_btn: 'Buy',
    currency_action: 'currency',

    importantAccountDetails: 'Important account details',
    viewAdditionalSettings: 'View additional settings',
    switchDarkMode: 'Switch dark/light mode',
    writeProblem: 'Write your problem to us',
    darkMode: 'Dark mode',

    yay: 'Yay',
    purchased: 'You successfully purchased',
    from: 'from',
    walet: 'Wallet',
    trade: 'Trade',

    withdrawDetails: 'Withdrawal details',
    saveDetails: 'Save details for next time',
    amountToWithdraw: 'Amount to withdraw',
    withdrawLimit: '$1,000,000.00 daily withdrawal limit remaining.',

    serviceFee: 'Service fee',
    youWillPay: 'You will pay',
    cancel: 'Cancel',
    understand: 'I understand, continue',

    select: 'Select',
    enterAmount: 'Enter amount',
    orderInfo: 'Order Info',
    confirmOrder: 'Confirm Order',
    get: 'Get',
    spend: 'Spend',
    buying: 'Buying',
    accountTransactions: 'Account Transactions',
    overview: 'Overview',
    accountBalances: 'Account Balances',
    details: 'Details',
    loginHistory: 'Login history',
    device: 'Device',
    contactUs: 'Contact us',
    sessionLog: 'Sessions & login history',
    selectMethods: 'Select methods',
    depositDetails: 'Deposit details',
    order: 'Order',
    support: 'Support',
    supportChat: 'Support Chat',
    createChat: 'Create Chat',
    homepage: 'Home',
    takeProfitStopLoss: 'Take Profit & Stop Loss',
    takeProfit: 'Take Profit',
    stopLoss: 'Stop Loss',
    updateOrder: 'Update Order',
    balance: 'Balance',
    credit: 'Credit',
    equity: 'Equity',
    practice: 'PRACTICE',
    real: 'REAL',
    account: 'ACCOUNT',
    invested: 'Invested',
    profit: 'Profit',
    loss: 'Loss',
    margin: 'Margin',
    marginLevel: 'Margin Level',
    marginFree: 'Free Margin',
    cfd: 'CFD',
    platform: 'Platform',
    deposit: 'Deposit',
    marketWatch: 'Market Watch',
    activeOrders: 'Active Orders',
    tradingHistory: 'Trading History',
    economicCalendar: 'Economic Calendar',
    marketNews: 'Market News',
    closeOrder: 'Close Position',
    areYouSure: 'Are you sure to close the position',
    buy: 'Buy',
    sell: 'Sell',
    yes: 'Yes',
    no: 'No',
    addNewChart: 'Add New Chart',
    watchlist: 'Watchlist',
    asset: 'Asset',
    price: 'Price',
    changePct: 'Change 24h',
    pending: 'Pending',
    assetPrice: 'Asset Price',
    current: 'Current',
    revert: 'Revert to Market Price',
    automatically:
      'Position will be opened automatically when the price reaches this level',
    default: 'default',
    volume: 'Volume',
    lots: 'lots',
    units: 'units',
    currency: 'currency',
    contractSize: 'Contract size',
    position: 'Position',
    margin: 'Margin',
    freeMargin: 'Free Margin',
    takeProfitStopLoss: 'Take Profit & Stop Loss',
    pending: 'Pending',
    market: 'Market',
    leverage: 'Leverage',
    spread: 'Spread',
    notSet: 'Not set',
    at: 'at',
    buy: 'buy',
    sell: 'sell',
    supportBanner: 'EVERY DAY, AROUND THE CLOCK',
    currentTime: 'CURRENT TIME',
    liveChat: 'Live Chat',
    balance: 'Balance',
    credit: 'Credit',
    equity: 'Equity',
    margin: 'Margin',
    marginLevel: 'Margin level',
    freeMargin: 'Free Margin',
    pnl: 'PnL',
    profitTotal: 'Lifetime PnL',
    dateRegistered: 'Date Registered',
    userId: 'User ID',
    dashboard: 'Dashboard',
    personalData: 'Personal Data',
    deposit: 'Deposit',
    withdrawFunds: 'Withdraw Funds',
    savings: 'Savings',
    settings: 'Settings',
    logout: 'Logout',
    BT_INVOICE: 'Enter amount and generate invoice',
    BT_TITLE: 'Our banking details',
    account: 'Account',
    bank_name: 'Bank Name',
    beneficiary: 'Beneficiary',
    beneficiary_address: 'Beneficiary Address',
    swift: 'Swift',
    iban: 'IBAN',
    ru_inn: 'INN',
    ru_kpp: 'KPP',
    corresponding_account: 'Correspondent account',
    TOKEN_AMOUNT: 'Amount',
    TOKEN_SUBTOTAL: 'Subtotal',
    TOKEN_TOTAL: 'Total',
    TOKEN_DESCRIPTION: 'Description',
    TOKEN_PAYMENT_DETAILS: 'Payment Details',
    PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
    makeDeposit: 'Make a Deposit',
    practiceAccount: 'Practice Account',
    realAccount: 'Real Account',
    noWithdrawals: 'No Withdrawals',
    easyWithdrawals: 'Easy Withdrawals',
    allAssets: 'All assets available',
    fullFledged: 'Full-ledged platform',
    fillUpTo: 'Fill Up To',
    freeReplenishment: 'Free replenishment',
    topUp: 'Top Up Your Account',
    minimumAmount: 'Minimum amount',
    canSwitch: 'You can switch between your accounts at any moment',
    chartGrids: 'CHART GRIDS',
    chart1: '1 chart',
    chart2: '2 charts',
    chart3: '3 charts',
    chart4: '4 charts',
    connectionLost: 'Connection with the server is lost',
    retryNow: 'Retry Now',
    loginToTradeRoom: 'Login To Traderoom',
    email: 'Email address',
    enterEmail: 'Enter your email',
    twoFactor: '2FA Code (if enabled)',
    twoFactorAuth: 'Two-factor authentication',
    password: 'Password',
    yourPassword: 'Your password',
    signIn: 'Sign In',
    dontHaveAccount: 'Don’t have an account?',
    restore: 'Restore',
    fullName: 'Full Name',
    havePromo: 'I have a promo-code',
    promo: 'Promo code',
    login: 'Login',
    haveAccount: 'Already have an account?',
    hide: 'Hide',
    goTo: 'Go to back',

    setNewPassword: 'Set New Password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    setButton: 'Set',

    repeatPassword: 'Repeat Password',
    createNewAccount: 'Create A New Account',
    email: 'Email address',
    firstName: 'First Name',
    lastName: 'Last Name',
    country: 'Country',
    phone: 'Phone',
    password: 'Password',
    createAccount: 'Create Account',
    currency: 'Currency',

    forgotPassword: 'Forgot Password?',
    submitEmail:
      'Please submit an email used for registration, check your inbox and follow the instructions provided',
    submit: 'Submit',
    error: 'Error',
    success: 'Success',
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
    depositApproved: 'Deposit has been approved',
    depositDeclined: 'Deposit has been declined',
    withdrawalApproved: 'Withdrawal has been approved',
    withdrawalDeclined: 'Withdrawal has been declined',

    forex: 'Forex',
    stocks: 'Stocks',
    commodities: 'Commodities',
    indices: 'Indices',
    crypto: 'Crypto',
    metals: 'Metals',
    nft: 'NFT',

    buy: 'BUY',
    profitCalculator: 'Profit Calculator',
    sell: 'SELL',
    volume: 'Volume',
    entryPrice: 'Entry Price',
    takeProfit: 'Take Profit',
    stopLoss: 'Stop Loss',
    maxPosition: 'Max Position',
    calculate: 'Calculate',
    calculationsFor: 'Calculations for',
    leverage: 'Leverage',
    requiredMargin: 'Required Margin',
    profitFromTP: 'Profit from TP',
    lossFromSL: 'Loss from SL',
    roe: 'ROE',

    title: 'Deposit successful',
    text: 'Your deposit has been processed successfully!',

    title: 'Deposit failed',
    text: 'Your deposit has not been processed.',

    marketWatch: 'Market Watch',
    search: 'Search...',

    commission: 'Commission',
    volume: 'Volume',
    margin: 'Margin',
    active: 'Active',
    pending: 'Pending',
    activeOrders: 'Active Orders',
    portfolio: 'Portfolio',
    allPositions: 'All Positions',
    noPositionsLine1: 'You have no open',
    noPositionsLine2: 'positions yet',
    show: 'Show',
    more: 'more',
    less: 'less',
    purchaseTime: 'Purchase Time',
    closePosition: 'Close Position',
    priceOpen: 'Purchase Price',
    priceSl: 'Stop Loss Price',
    priceTp: 'Take Profit Price',
    type: 'Position Direction',
    pnl: 'Profit/Loss',
    cancelOrder: 'Cancel Order',
    orderType: 'Order Type',
    cancelled: 'Cancelled',
    tradingHistory: 'Trading History',
    noHistoryLine1: "You don't have any",
    noHistoryLine2: 'closed deals yet',

    economicCalendar: 'Economic Calendar',

    marketNews: 'Market News',

    ordersActive: 'Active Orders',
    ordersHistory: 'Orders History',
    id: 'ID',
    symbol: 'Symbol',
    type: 'Type',
    volume: 'Volume',
    openPrice: 'Open Price',
    openTime: 'Open Time',
    closePrice: 'Close Price',
    closeTime: 'Close Time',
    sl: 'SL',
    tp: 'TP',
    price: 'Price',
    pnl: 'PnL',
    actions: 'Actions',
    edit: 'Edit',
    close: 'Close',
    commission: 'Commission',
    swap: 'Swap',
    swapLong: 'Swap Long',
    swapShort: 'Swap Short',

    assetInfo: 'Asset Info',
    tradingConditions: 'Trading Conditions',
    information: 'Session Change',
    bid: 'Bid',
    ask: 'Ask',
    sessionChange: 'Session Change',
    tradeNow: 'Trade Now',
    opens: 'Opens',
    closes: 'Closes',
    at: 'at',
    open247: 'Open 24/7',
    today: 'today',
    tomorrow: 'tomorrow',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    contractSpecification: 'Contract Specification',
    symbol: 'Symbol',
    name: 'Name',
    market: 'Market',
    baseCurrency: 'Base Currency',
    digits: 'Digits',
    lotSize: 'Lot Size',
    lotStep: 'Lot Step',
    minVolume: 'Min Volume',
    maxVolume: 'Max Volume',
    leverage: 'Leverage',
    commission: 'Commission',
    swapLong: 'Swap Long',
    swapShort: 'Swap Short',
    schedule: 'Schedule',
    weekday: 'Weekday',
    tradingTime: 'Trading Time',
    closed: 'closed',
    sell: 'Sell',
    buy: 'Buy',
    low: 'Low',
    high: 'High',
    oneHourChange: '1 hour change',
    oneDayChange: '1 day change',
    oneWeekChange: '1 week change',
    oneMonthChange: '1 month change',
    threeMonthsChange: '3 months change',
    oneYearChange: '1 year change',
    loading: 'Loading...',

    title: 'Auto Trader',
    riskLevelLow: 'Risk Level: Low',
    riskLevelMedium: 'Risk Level: Medium',
    riskLevelHigh: 'Risk Level: High',
    perDay: 'Trades per day:',
    desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
              read the
              relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
              company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
              fully understand the risks and take measures to manage risks.`,
    accept: 'Accept',

    trade: 'Trade',
    startTrading: 'Start Trading',
    deposit: 'Deposit',
    dashboard: 'Dashboard',
    personalInfo: 'Personal Info',
    withdrawal: 'Withdrawal',
    verification: 'Verification',
    accounts: 'Accounts',
    liveChat: 'Live Chat',
    savings: 'Savings',
    settings: 'Settings',
    logOut: 'Log Out',

    methods: 'METHODS',
    confirm: 'Confirm in the application of your bank',
    sendOtp: 'Send otp',
    otpCode: 'Otp code',
    addWalletAddress:
      'Wallet for this deposit method is not set. Please contact your agent or support team.',
    addressReceipt: 'Please wait for the address to load',
    makeDeposit: 'MAKE A DEPOSIT',
    instant: 'Instant',
    minutes: 'minutes',
    hours: 'hours',
    days: 'days',
    amount: 'Amount',
    continue: 'Continue',
    qrCode: 'QR CODE',
    depositAddress: 'DEPOSIT ADDRESS',
    copy: 'Copy',
    last: 'LAST',
    deposits: 'DEPOSITS',
    time: 'Time',
    currency: 'Currency',
    status: 'Status',
    info: 'Info',

    pleaseWait: 'Please wait. Connecting to payment service provider...',
    cardPayment: 'Card payment',
    inOrder: 'In order to send the amount',
    pleaseProvide: 'please provide card details:',

    completeVerification: 'Please Complete Verification',
    noOrderYet: 'No order yet',
    totalBalance: 'Total Balance',
    totalDeposits: 'Total Deposits',
    profitableOrders: 'Profitable Orders',
    totalPnl: 'Total PNL',
    roi: 'ROI',
    activityLog: 'Activity Log',
    loginFromIp: 'Login from IP',
    tradingResults: 'Trading Results',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    successRate: 'Success rate',
    closedWithProfit: 'Closed With Profit',
    closedWithLoss: 'Closed With Loss',
    account: 'Account',
    balance: 'Balance',
    leverage: 'Leverage',
    credit: 'Credit',
    tradeNow: 'Trade Now',
    usingCurrentRate: 'using current exchange rate',

    personalDetails: 'Personal Details',
    profilePhoto: 'Profile Photo',
    firstName: 'First Name',
    country: 'Country',
    lastName: 'Last Name',
    address: 'Address',
    email: 'E-mail',
    phone: 'Phone number',
    saveChanges: 'Save Changes',

    dropFile: 'Drop a file on the circle above to upload',
    notAllowed: 'It is not allowed to publish',
    notAllowedLine1: 'Photos of an explicitly sexual or pornographic nature',
    notAllowedLine2:
      'Images aimed at inciting ethnic or racial hatred or aggression',
    notAllowedLine3: 'Photos involving persons under 18 years of age',
    notAllowedLine4: 'Third-party copyright protected photos',
    notAllowedLine5:
      'Images larger than 5 MB and in a format other than JPG, GIF or PNG',
    requirements:
      'Your face must be clearly visible on the photo. All photos and videos uploaded by you must comply with these requirements, otherwise they can be removed.',

    withdrawalOff: 'Please contact your account manager for withdrawal details',
    cryptocurrency: 'Cryptocurrency',
    withdrawal: 'Withdrawal',
    requestNewWithdrawal: 'REQUEST A NEW WITHDRAWAL',
    amount: 'Amount',
    currentBalance: 'Your current account balance is',
    withdrawAll: 'Withdraw all',
    requestWithdrawal: 'Request Withdrawal',
    last: 'LAST',
    withdrawalRequests: 'WITHDRAWAL REQUESTS',
    time: 'Time',
    currency: 'Currency',
    info: 'Info',
    status: 'Status',
    bank: 'Bank',
    bitcoin: 'Bitcoin',
    card: 'Card',
    ethereum: 'Ethereum',
    eth: 'Ethereum',
    usdt: 'USDT',
    withdrawalDetails: 'Withdrawal Details',
    address: 'Wallet Address',
    bankAccount: 'Account Number',
    bankHolder: 'Account Holder Name',
    bankIban: 'IBAN',
    bankSwift: 'Bank SWIFT code',
    cardNumber: 'Card Number',
    cardHolder: 'Card Holder Name',

    dragAndDrop: 'drag and drop the document to this area',
    documentsList: 'LIST OF UPLOADED DOCUMENTS',
    document: 'Document',
    timeUploaded: 'Time Uploaded',
    timeProcessed: 'Time Processed',
    status: 'Status',
    types: [
      'Proof of Id',
      'Proof of Residence',
      'Credit Card Front',
      'Credit Card Back',
      'Proof of Id Back',
      'Selfie',
    ],
    types2: [
      'Proof of identification (POI)',
      'Proof of residential address',
      'Credit Card Front',
      'Credit Card Back',
      'Proof of identification Back',
      'Selfie with ID',
    ],
    upload: 'Upload',
    isConfirmed: 'is confirmed',
    uploadAnotherDocument: 'Upload another document',
    isUnderReview: 'is under review',
    questionnaire: 'Questionnaire',
    sendAnswers: 'Send Answers',
    progressBarOf: 'of',
    progressBarText: 'of your documents have been uploaded and confirmed',

    manageAccounts: 'Transfer funds between your accounts',
    transferFrom: 'From account',
    transferTo: 'To account',
    amount: 'Amount',
    makeTransfer: 'Make Transfer',
    yourAccounts: 'Your Accounts',
    account: 'Account',
    currency: 'Currency',
    balance: 'Balance',
    credit: 'Credit',
    makeActive: 'Make Active',
    active: 'Active',

    sendMessage: 'Send Message',

    pct_0: 'Flexible',
    pct_30: 'Locked 1 Month',
    pct_90: 'Locked 1 Quarter',
    pct_180: 'Locked 6 Months',
    pct_365: 'Locked 1 Year',
    savings: 'Savings',
    detailedInformation: 'Detailed Information',
    pleaseSelect: 'Please select a currency and a period',
    openSavings: 'Open Savings',
    currentTime: 'Current Time',
    releaseTime: 'Release Time',
    currencyChosen: 'Currency Chosen',
    periodChosen: 'Period Chosen',
    yourRate: 'Your Rate',
    yourEarnings: 'Your earnings',
    accountFrom: 'Account to transfer funds from',
    enterAmount: 'Enter Amount',
    in: 'In',
    openAndInvest: 'Open Savings Account and invest',
    investment: 'Investment',
    period: 'Period',
    daysTotal: 'Days total',
    finalEarnings: 'Final earnings',
    created: 'Created',
    daysElapsed: 'Days Elapsed',
    days: 'days',
    earnings: 'Earnings',
    fundsReleasedIn: 'Funds Released In',
    claim: 'Claim',

    updatePassword: 'UPDATE PASSWORD',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    changePassword: 'Change Password',
    activationForGoogle: 'Activation code for Google Authenticator',
    activateAndEnter:
      'Activate Authenticator and enter a generated code in field below',
    qrCode: 'QR Code',
    activateProtection: 'Activate 2FA Protection',
    protectionActive: '2FA protection is active',
    twoFactorCode: '2FA Code',
    disableProtection: 'Disable 2FA Protection',
    language: 'Language',
    dashboardCurrency: 'Dashboard Currency',
    confirmNewPassword: 'Confirm new password',

    yourLink: 'YOUR REFERRAL LINK',
    referredFriends: 'REFERRED FRIENDS',
    id: 'ID',
    name: 'Name',
    registered: 'Register',
    level: 'Level',
    payout: 'Payout',
    lastPayouts: 'LAST REFERRAL PAYOUTS',
    time: 'Time',
    amount: 'Amount',
    currency: 'Currency',
    info: 'Info',

    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',

    orderTypes: [
      'Buy',
      'Sell',
      'Buy Limit',
      'Sell Limit',
      'Buy Stop',
      'Sell Stop',
    ],
    statuses: ['Pending', 'Approved', 'Declined', 'Processing'],

    NOT_FOUND: 'Request error',
    FORBIDDEN: 'Access denied. Please re-login',
    SERVER_ERROR: 'Action failed. Please try again later',
    INVALID_ACCOUNT: 'Session expired. Please re-login',
    INVALID_EMAIL: 'Invalid e-mail address',
    INVALID_PHONE: 'Invalid phone number',
    INVALID_COUNTRY: 'Invalid country',
    INVALID_LOGIN: 'Invalid login credentials',
    USER_BLOCKED: 'Account is blocked. Please contact support',
    USER_REGISTERED:
      'User with entered e-mail or phone number is already registered',
    INVALID_USER: 'User is not found',
    REJECTED: 'Operation is not allowed due to platform restrictions',
    INVALID_OLD_PASSWORD: 'Old password is invalid',
    INVALID_SYMBOL: 'Invalid trading symbol',
    INVALID_SYMBOL_PRECISION: 'Invalid symbol precision',
    INVALID_FRAME: 'Invalid chart frame',
    INVALID_CURRENCY: 'Invalid currency',
    INVALID_PRODUCT: 'Invalid account trading type',
    INSUFFICIENT_FUNDS: 'Insufficient funds',
    INVALID_WITHDRAWAL: 'Withdrawal is not found',
    INVALID_STATUS: 'Invalid status',
    INVALID_FILE: 'Invalid file extension or file is too large',
    INVALID_DOCUMENT: 'Document is not found',
    INVALID_NAME: 'Invalid name',
    INVALID_LOT_SIZE: 'Invalid lot size',
    INVALID_LOT_STEP: 'Invalid lot step',
    INVALID_MIN_VOLUME: 'Invalid minimal volume',
    INVALID_MAX_VOLUME: 'Invalid maximal volume',
    INVALID_GROUP: 'Invalid group',
    INVALID_SPREAD: 'Invalid spread',
    REQUEST_TIMEOUT: 'Request timed out. Please try again later',
    INVALID_TYPE: 'Invalid order type',
    INVALID_VOLUME: 'Invalid order volume',
    INVALID_SL: 'Invalid stop loss value',
    INVALID_TP: 'Invalid take profit value',
    INVALID_PRICE: 'Invalid order price',
    INVALID_EXPIRATION: 'Invalid order expiration',
    NOT_ENOUGH_MARGIN: 'Not enough margin',
    INVALID_ORDER: 'Order is not found',
    MARKET_CLOSED: 'Trading is not available. Please try again',
    INVALID_BALANCE_TYPE: 'Invalid type of balance operation',
    INVALID_HASH: 'Invalid hash',
    HASH_EXPIRED:
      'Your password reset link is expired. Please request a new one',
    INVALID_CODE: 'Invalid 2FA code',
    CHAT_DISABLED: 'Access to the support chat has been closed',
    WITHDRAWAL_NOT_ALLOWED: 'Access to the withdrawals has been closed',
    TRADING_DISABLED: 'Trading is disabled for current account',
    PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
    LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
    LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
    LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
    LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
    LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
    LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
    LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
    LOCAL_NO_TRADING_ACCOUNTS:
      'You do not have trading accounts available. Please contact the support',
    LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
    LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
    LOCAL_INVALID_FILE_TYPE:
      'File format not supported. Please attach only JPG, PNG or PDF',
    LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
    FLOOD_REJECTED:
      'You are sending messages too often. Please try again later',
    countries: {
      AF: 'Afghanistan',
      AX: 'Aland Islands',
      AL: 'Albania',
      DZ: 'Algeria',
      AS: 'American Samoa',
      AD: 'Andorra',
      AO: 'Angola',
      AI: 'Anguilla',
      AQ: 'Antarctica',
      AG: 'Antigua And Barbuda',
      AR: 'Argentina',
      AM: 'Armenia',
      AW: 'Aruba',
      AU: 'Australia',
      AT: 'Austria',
      AZ: 'Azerbaijan',
      BS: 'Bahamas',
      BH: 'Bahrain',
      BD: 'Bangladesh',
      BB: 'Barbados',
      BY: 'Belarus',
      BE: 'Belgium',
      BZ: 'Belize',
      BJ: 'Benin',
      BM: 'Bermuda',
      BT: 'Bhutan',
      BO: 'Bolivia',
      BA: 'Bosnia And Herzegovina',
      BW: 'Botswana',
      BV: 'Bouvet Island',
      BR: 'Brazil',
      IO: 'British Indian Ocean Territory',
      BN: 'Brunei Darussalam',
      BG: 'Bulgaria',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      KH: 'Cambodia',
      CM: 'Cameroon',
      CA: 'Canada',
      CV: 'Cape Verde',
      KY: 'Cayman Islands',
      CF: 'Central African Republic',
      TD: 'Chad',
      CL: 'Chile',
      CN: 'China',
      CX: 'Christmas Island',
      CC: 'Cocos (Keeling) Islands',
      CO: 'Colombia',
      KM: 'Comoros',
      CG: 'Congo',
      CD: 'Congo, Democratic Republic',
      CK: 'Cook Islands',
      CR: 'Costa Rica',
      CI: "Cote D'Ivoire",
      HR: 'Croatia',
      CU: 'Cuba',
      CY: 'Cyprus',
      CZ: 'Czech Republic',
      DK: 'Denmark',
      DJ: 'Djibouti',
      DM: 'Dominica',
      DO: 'Dominican Republic',
      EC: 'Ecuador',
      EG: 'Egypt',
      SV: 'El Salvador',
      GQ: 'Equatorial Guinea',
      ER: 'Eritrea',
      EE: 'Estonia',
      ET: 'Ethiopia',
      FK: 'Falkland Islands (Malvinas)',
      FO: 'Faroe Islands',
      FJ: 'Fiji',
      FI: 'Finland',
      FR: 'France',
      GF: 'French Guiana',
      PF: 'French Polynesia',
      TF: 'French Southern Territories',
      GA: 'Gabon',
      GM: 'Gambia',
      GE: 'Georgia',
      DE: 'Germany',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GR: 'Greece',
      GL: 'Greenland',
      GD: 'Grenada',
      GP: 'Guadeloupe',
      GU: 'Guam',
      GT: 'Guatemala',
      GG: 'Guernsey',
      GN: 'Guinea',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HT: 'Haiti',
      HM: 'Heard Island & Mcdonald Islands',
      VA: 'Holy See (Vatican City State)',
      HN: 'Honduras',
      HK: 'Hong Kong',
      HU: 'Hungary',
      IS: 'Iceland',
      IN: 'India',
      ID: 'Indonesia',
      IR: 'Iran',
      IQ: 'Iraq',
      IE: 'Ireland',
      IM: 'Isle Of Man',
      IL: 'Israel',
      IT: 'Italy',
      JM: 'Jamaica',
      JP: 'Japan',
      JE: 'Jersey',
      JO: 'Jordan',
      KZ: 'Kazakhstan',
      KE: 'Kenya',
      KI: 'Kiribati',
      KR: 'Korea',
      KW: 'Kuwait',
      KG: 'Kyrgyzstan',
      LA: "Lao People's Democratic Republic",
      LV: 'Latvia',
      LB: 'Lebanon',
      LS: 'Lesotho',
      LR: 'Liberia',
      LY: 'Libyan Arab Jamahiriya',
      LI: 'Liechtenstein',
      LT: 'Lithuania',
      LU: 'Luxembourg',
      MO: 'Macao',
      MK: 'Macedonia',
      MG: 'Madagascar',
      MW: 'Malawi',
      MY: 'Malaysia',
      MV: 'Maldives',
      ML: 'Mali',
      MT: 'Malta',
      MH: 'Marshall Islands',
      MQ: 'Martinique',
      MR: 'Mauritania',
      MU: 'Mauritius',
      YT: 'Mayotte',
      MX: 'Mexico',
      FM: 'Micronesia',
      MD: 'Moldova',
      MC: 'Monaco',
      MN: 'Mongolia',
      ME: 'Montenegro',
      MS: 'Montserrat',
      MA: 'Morocco',
      MZ: 'Mozambique',
      MM: 'Myanmar',
      NA: 'Namibia',
      NR: 'Nauru',
      NP: 'Nepal',
      NL: 'Netherlands',
      AN: 'Netherlands Antilles',
      NC: 'New Caledonia',
      NZ: 'New Zealand',
      NI: 'Nicaragua',
      NE: 'Niger',
      NG: 'Nigeria',
      NU: 'Niue',
      NF: 'Norfolk Island',
      MP: 'Northern Mariana Islands',
      NO: 'Norway',
      OM: 'Oman',
      PK: 'Pakistan',
      PW: 'Palau',
      PS: 'Palestinian Territory, Occupied',
      PA: 'Panama',
      PG: 'Papua New Guinea',
      PY: 'Paraguay',
      PE: 'Peru',
      PH: 'Philippines',
      PN: 'Pitcairn',
      PL: 'Poland',
      PT: 'Portugal',
      PR: 'Puerto Rico',
      QA: 'Qatar',
      RE: 'Reunion',
      RO: 'Romania',
      RU: 'Russia',
      RW: 'Rwanda',
      BL: 'Saint Barthelemy',
      SH: 'Saint Helena',
      KN: 'Saint Kitts And Nevis',
      LC: 'Saint Lucia',
      MF: 'Saint Martin',
      PM: 'Saint Pierre And Miquelon',
      VC: 'Saint Vincent And Grenadines',
      WS: 'Samoa',
      SM: 'San Marino',
      ST: 'Sao Tome And Principe',
      SA: 'Saudi Arabia',
      SN: 'Senegal',
      RS: 'Serbia',
      SC: 'Seychelles',
      SL: 'Sierra Leone',
      SG: 'Singapore',
      SK: 'Slovakia',
      SI: 'Slovenia',
      SB: 'Solomon Islands',
      SO: 'Somalia',
      ZA: 'South Africa',
      GS: 'South Georgia And Sandwich Isl.',
      ES: 'Spain',
      LK: 'Sri Lanka',
      SD: 'Sudan',
      SR: 'Suriname',
      SJ: 'Svalbard And Jan Mayen',
      SZ: 'Swaziland',
      SE: 'Sweden',
      CH: 'Switzerland',
      SY: 'Syrian Arab Republic',
      TW: 'Taiwan',
      TJ: 'Tajikistan',
      TZ: 'Tanzania',
      TH: 'Thailand',
      TL: 'Timor-Leste',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinidad And Tobago',
      TN: 'Tunisia',
      TR: 'Turkey',
      TM: 'Turkmenistan',
      TC: 'Turks And Caicos Islands',
      TV: 'Tuvalu',
      UG: 'Uganda',
      UA: 'Ukraine',
      AE: 'United Arab Emirates',
      GB: 'United Kingdom',
      US: 'United States',
      UM: 'United States Outlying Islands',
      UY: 'Uruguay',
      UZ: 'Uzbekistan',
      VU: 'Vanuatu',
      VE: 'Venezuela',
      VN: 'Viet Nam',
      VG: 'Virgin Islands, British',
      VI: 'Virgin Islands, U.S.',
      WF: 'Wallis And Futuna',
      EH: 'Western Sahara',
      YE: 'Yemen',
      ZM: 'Zambia',
      ZW: 'Zimbabwe',
    },
  },
  ru: {
    _name: 'Русский',

    policy: 'Политика конфиденциальности',
    policyAML: 'Политика противодействия отмыванию денег (AML)',
    policyKYC: 'Политика «Знай своего клиента» (KYC)',
    locense: 'Лицензия',

    view_all: 'Посмотреть все',

    sell_btn: 'Продать',
    buy_btn: 'Купить',

    currency_action: 'валюта',

    importantAccountDetails: 'Важные данные учетной записи',
    viewAdditionalSettings: 'Дополнительные настройки',
    switchDarkMode: 'Переключить темный/светлый режим',
    writeProblem: 'Напишите нам о своей проблеме',
    darkMode: 'Темный режим',

    yay: 'Ура',
    purchased: 'Вы успешно приобрели',
    from: 'из',
    walet: 'Wallet',
    trade: 'Торговля',

    withdrawDetails: 'Подробности вывода',
    saveDetails: 'Сохранить подробности для следующего раза',
    amountToWithdraw: 'Сумма вывода',
    withdrawLimit: 'Оставшийся дневной лимит вывода $1 000 000,00.',

    serviceFee: 'Плата за обслуживание',
    youWillPay: 'Вы заплатите',
    cancel: 'Отмена',
    understand: 'Я понимаю, продолжить',

    select: 'Выбрать',
    enterAmount: 'Ввести сумму',
    orderInfo: 'Информация о заказе',
    confirmOrder: 'Подтвердить заказ',
    get: 'Получить',
    spend: 'Потратить',
    buying: 'Покупка',

    accountTransactions: 'Операции по счету',

    overview: 'Обзор',
    accountBalances: 'Остатки на счетах',
    details: 'Подробности',
    loginHistory: 'История входа',
    device: 'Устройство',
    contactUs: 'Связаться с нами',
    sessionLog: 'Сеансы и история входов',
    selectMethods: 'Выберите методы',
    depositDetails: 'Подробности депозита',
    support: 'Поддержка',
    homepage: 'Домашняя страница',
    supportChat: 'Чат поддержки',
    createChat: 'Создать чат',

    takeProfitStopLoss: 'Тейк Профит и Стоп Лосс',
    takeProfit: 'Тейк Профит',
    stopLoss: 'Стоп Лосс',
    order: 'Ордер',
    updateOrder: 'Изменить ордер',
    balance: 'Баланс',
    credit: 'Кредит',
    equity: 'Средства',
    practice: 'Пробный',
    real: 'Реальный',
    account: 'Счет',
    invested: 'Вложено',
    profit: 'Прибыль',
    loss: 'Убыток',
    margin: 'Маржа',
    marginLevel: 'Уровень маржи',
    marginFree: 'Свободная маржа',
    cfd: 'CFD',
    platform: 'Platform',
    deposit: 'Депозит',
    marketWatch: 'обзор Рынков',
    activeOrders: 'открытые ордера',
    tradingHistory: 'история Торговли',
    economicCalendar: 'экономический Календарь',
    marketNews: 'Новости рынков',

    closeOrder: 'Закрыть позицию',
    areYouSure: 'Вы уверены, что хотите закрыть позицию',
    buy: 'Купить',
    sell: 'Продать',
    yes: 'Да',
    no: 'Нет',

    addNewChart: 'Добавить график',

    watchlist: 'Избранное',
    asset: 'Инструмент',
    price: 'Цена',
    changePct: 'Изменения 24ч',

    pending: 'Цена', // <- NB
    assetPrice: 'Цена инструмента',
    current: 'Текущая',
    revert: 'Вернуть к рыночной',
    automatically:
      'Позиция будет открыта автоматически при достижении указанного уровня',

    default: 'По умолчанию',
    volume: 'Объем',
    lots: 'лоты',
    units: 'юниты',
    currency: 'валюта',
    contractSize: 'Размер контракта',
    position: 'Позиция',
    margin: 'Маржа',
    freeMargin: 'Свободная маржа',
    takeProfitStopLoss: 'Тейк Профит и Стоп Лосс',
    pending: 'Цена',
    market: 'Рынок',
    leverage: 'Плечо',
    spread: 'Спрэд',
    notSet: 'Не задано',
    at: 'по',
    buy: 'купить',
    sell: 'продать',

    supportBanner: 'КАЖДЫЙ ДЕНЬ, 24/7',
    currentTime: 'ТЕКУЩЕЕ ВРЕМЯ',
    liveChat: 'Поддержка',

    balance: 'Баланс',
    credit: 'Кредит',
    equity: 'Средства',
    margin: 'Маржа',
    marginLevel: 'Уровень маржи',
    freeMargin: 'Свободная маржа',
    pnl: 'Прибыль/Убыток',
    profitTotal: 'Прибыль всего',

    dateRegistered: 'Дата Регистрации',
    userId: 'ID Пользователя',
    dashboard: 'Панель пользователя',
    personalData: 'Личные данные',
    deposit: 'Депозит',
    withdrawFunds: 'Вывод Средств',
    savings: 'Накопительные Счета',
    settings: 'Настройки',
    logout: 'Выйти',

    BT_INVOICE: 'Введите сумму и сформируйте счет',
    BT_TITLE: 'Ваши платежные реквизиты',
    PENDING_DEPOSITS_LIMIT: 'Лимит депозитов',
    account: 'Счет получателя',
    bank_name: 'Наименование банка получателя',
    beneficiary: 'Получатель',
    beneficiary_address: 'Счет получателя',
    swift: 'БИК Банка получателя',
    iban: 'IBAN',
    ru_inn: 'ИНН',
    ru_kpp: 'KPP',
    corresponding_account: 'Correspondent accountКорреспондентский счёт',
    TOKEN_AMOUNT: 'Сумма',
    TOKEN_SUBTOTAL: 'Промежуточный итог',
    TOKEN_TOTAL: 'Всего',
    TOKEN_DESCRIPTION: 'Описание',
    TOKEN_PAYMENT_DETAILS: 'Платежные реквизиты',
    makeDeposit: 'Внести Депозит',
    practiceAccount: 'Учебнй Счет',
    realAccount: 'Реальный Счет',
    noWithdrawals: 'Нет вывода средств',
    easyWithdrawals: 'Легкий вывод средств',
    allAssets: 'Доступны все инструменты',
    fullFledged: 'Полнофункциональная платформа',
    fillUpTo: 'Пополнить до',
    freeReplenishment: 'Бесплатное Пополнение',
    topUp: 'Пополните Ваш Счет',
    minimumAmount: 'Минимальная Сумма',
    canSwitch: 'Вы можете переключиться между счетами в любое время',

    chartGrids: 'СЕТКА ГРАФИКОВ',
    chart1: '1 график',
    chart2: '2 графика',
    chart3: '3 графика',
    chart4: '4 графика',

    connectionLost: 'Соединение с сервером потеряно',
    retryNow: 'Попробовать еще',

    loginToTradeRoom: 'Авторизация',
    email: 'Электронная Почта',
    enterEmail: 'Введите почту',
    twoFactor: 'Код 2FA (если активна)',
    twoFactorAuth: 'Двухфакторная аутентификация',
    password: 'Пароль',
    yourPassword: 'Ваш пароль',
    signIn: 'Войти',
    dontHaveAccount: 'У вас нет аккаунта?',
    restore: 'Восстановить',
    fullName: 'Полное имя',
    havePromo: 'У меня есть промо код',
    promo: 'Промо код',
    login: 'Вход в систему',
    haveAccount: 'Уже есть аккаунт?',
    hide: 'Скрыть',
    goTo: 'Вернуться назад',

    setNewPassword: 'Установить новый пароль',
    newPassword: 'Новый пароль',
    confirmNewPassword: 'Подтвердите новый пароль',
    setButton: 'Установить',

    repeatPassword: 'Подтверлить Пароль',
    createNewAccount: 'Создать Новый Счет',
    email: 'Электронная Почта',
    firstName: 'Имя',
    lastName: 'Фамилия',
    country: 'Страна',
    phone: 'Телефон',
    password: 'Пароль',
    createAccount: 'Создать Счет',
    currency: 'Валюта',

    forgotPassword: 'Забыли Пароль?',
    submitEmail:
      'Пожалуйста, введите имейл, указанный при регистрации, проверьте свой ящик и следуйте инструкциям, полученным в письме',
    submit: 'Отправить',

    error: 'Ошибка',
    success: 'Успешно',
    deposit: 'Депозит',
    withdrawal: 'Вывод',
    depositApproved: 'Депозит одобрен',
    depositDeclined: 'Депозит отклонен',
    withdrawalApproved: 'Вывод средств одобрен',
    withdrawalDeclined: 'Вывод средств отклонен',

    forex: 'Форекс',
    stocks: 'Акции',
    commodities: 'Сырье',
    indices: 'Индексы',
    crypto: 'Криптовалюта',
    metals: 'Металлы',
    nft: 'NFT',

    buy: 'ПОКУПКА',
    sell: 'ПРОДАЖА',
    volume: 'Объем',
    entryPrice: 'Цена Входа',
    takeProfit: 'Тейк Профит',
    stopLoss: 'Стоп Лосс',
    maxPosition: 'Макс. Позиция',
    calculate: 'Рассчитать',
    calculationsFor: 'Рассчет для',
    leverage: 'Плечо',
    requiredMargin: 'Требуемая Маржа',
    profitFromTP: 'Прибыль от ТП',
    lossFromSL: 'Убыток от СЛ',
    roe: 'ROE',

    title: 'Deposit successful',
    text: 'Your deposit has been processed successfully!',

    title: 'Deposit failed',
    text: 'Your deposit has not been processed.',

    marketWatch: 'Обзор Рынков',
    search: 'Поиск...',

    commission: 'Комиссия',
    volume: 'Объем',
    margin: 'Маржа',
    active: 'Открытые',
    pending: 'В Ожидании',
    activeOrders: 'Открытые ордера',
    portfolio: 'Портфолио',
    allPositions: 'Все Позиции',
    noPositionsLine1: 'У вас ещё нет',
    noPositionsLine2: 'открытых позиций',
    show: 'Показать',
    more: 'больше',
    less: 'меньше',
    purchaseTime: 'Открыт',
    closePosition: 'Закрыть',
    priceOpen: 'Цена открытия',
    priceSl: 'Цена Стоп Лосс',
    priceTp: 'Цена Тейк Профит',
    type: 'Направление позиции',
    pnl: 'Прибыль/Убыток',
    cancelOrder: 'Отменить Ордер',
    orderType: 'Тип Ордера',
    cancelled: 'Отменено',
    tradingHistory: 'Торговая История',
    noHistoryLine1: 'У вас ещё нет',
    noHistoryLine2: 'закрытых позиций',

    economicCalendar: 'Экономический Календарь',

    marketNews: 'Новости Рынков',

    ordersActive: 'Активные ордеры',
    ordersHistory: 'История Ордеров',
    id: 'ID',
    symbol: 'Символ',
    type: 'Тип',
    volume: 'Объем',
    openPrice: 'Цена открытия',
    openTime: 'Время открытия',
    closePrice: 'Цена закрытия',
    closeTime: 'Время закрытия',
    sl: 'СЛ',
    tp: 'ТП',
    price: 'Цена',
    pnl: 'ПиУ',
    actions: 'Действия',
    edit: 'Править',
    close: 'Закрыть',
    commission: 'Комиссия',
    swap: 'Своп',
    swapLong: 'Своп Лонг',
    swapShort: 'Своп Шорт',

    assetInfo: 'Информация об Инструменте',
    tradingConditions: 'Торговые Условия',
    information: 'Изменение за сессию',
    bid: 'Покупка',
    ask: 'Продажа',
    sessionChange: 'Изменение за сессию',
    tradeNow: 'Торговать',
    opens: 'Открывается',
    closes: 'Закрывается',
    at: 'в',
    open247: 'Открыто круглосуточно',
    today: 'сегодня',
    tomorrow: 'завтра',
    sunday: 'Воскресенье',
    monday: 'Понедельник',
    tuesday: 'Вторник',
    wednesday: 'Среда',
    thursday: 'Четверг',
    friday: 'Пятница',
    saturday: 'Суббота',
    contractSpecification: 'Спецификация контракта',
    symbol: 'Символ',
    name: 'Наименование',
    market: 'Рынок',
    baseCurrency: 'Базовая Валюта',
    digits: 'Точность',
    lotSize: 'Размер лота',
    lotStep: 'Шаг лота',
    minVolume: 'Мин Объем',
    maxVolume: 'Макс Объем',
    leverage: 'Плечо',
    commission: 'Комиссия',
    swapLong: 'Своп Лонг',
    swapShort: 'Своп Шорт',
    schedule: 'Расписание',
    weekday: 'День Недели',
    tradingTime: 'Время Торгов',
    closed: 'закрыто',
    sell: 'Продавать',
    buy: 'Покупать',
    low: 'Мин',
    high: 'Макс',
    oneHourChange: 'Изменение 1 час',
    oneDayChange: 'Изменение 1 день',
    oneWeekChange: 'Изменение 1 неделя',
    oneMonthChange: 'Изменение 1 месяц',
    threeMonthsChange: 'Изменение 3 месяца',
    oneYearChange: 'Изменение 1 год',
    loading: 'Загрузка...',

    title: 'Автоматический Трейдер',
    riskLevelLow: 'Уровень риска: Низкий',
    riskLevelMedium: 'Уровень риска: Средний',
    riskLevelHigh: 'Уровень риска: высокий',
    perDay: 'Количество сделок в день:',
    desc: `Торговля CFD и другими продуктами с кредитным плечом может привести к убыткам. Перед началом торговли клиентам следует
              ознакомиться с
              соответствующими заявлениями о рисках на нашей странице "Информация о рисках". Автоматизированная торговля не гарантирует результатов. Компания
              Компания не несет ответственности за потерю средств при автоматической торговле. Пожалуйста, убедитесь, что вы
              полностью понимаете риски и принимаете меры по управлению ими.`,
    accept: 'Принять',

    trade: 'Торговля',
    startTrading: 'К Торговле',
    deposit: 'Депозит',
    dashboard: 'Панель Управления',
    personalInfo: 'Личные Данные',
    withdrawal: 'Вывод Средств',
    verification: 'Верификация',
    accounts: 'Счета',
    liveChat: 'Поддержка',
    savings: 'Накопительные Счета',
    settings: 'Настройки',
    logOut: 'Выход',

    methods: 'МЕТОДЫ',
    confirm: 'Подтвердите в приложении вашего банка',
    sendOtp: 'Отправить код',
    otpCode: 'OTP Код',
    addWalletAddress:
      'Кошелек для данного метода не установлен. Пожалуйста, обратитсь к Вашему агенту или в службу поддержки.',
    addressReceipt: 'Пожалуйста, подождите, получаем текущий адрес',
    makeDeposit: 'ВНЕСТИ ДЕПОЗИТ',
    instant: 'Моментально',
    minutes: 'минут',
    hours: 'часов',
    days: 'дней',
    amount: 'Сумма',
    continue: 'Продолжить',
    qrCode: 'QR код',
    depositAddress: 'АДРЕС КОШЕЛЬКА',
    copy: 'Копировать',
    last: 'ПОСЛЕДНИЕ',
    deposits: 'ДЕПОЗИТЫ',
    time: 'Время',
    currency: 'Валюта',
    status: 'Статус',
    info: 'Инфо',

    pleaseWait:
      'Пожалуйста, подождите. Идет соединение с сервисом приема платежей...',
    cardPayment: 'Оплата картоу',
    inOrder: 'Чтобы отправить сумму',
    pleaseProvide: 'пожалуйста предоставьте данные карты:',

    completeVerification: 'Пожалуйста, заполните верификацию',
    noOrderYet: 'Закрыто в убыток',
    totalBalance: 'Итоговый Баланс',
    totalDeposits: 'Всего депозитов',
    totalPnl: 'Прибыль / Убыток',
    profitableOrders: 'Прибыльные Ордеры',
    roi: 'ROI',
    activityLog: 'Лог Активности',
    loginFromIp: 'Вход с IP',
    tradingResults: 'Результаты Торговли',
    week: 'неделя',
    month: 'Месяц',
    year: 'Год',
    successRate: 'Успешные сделки',
    closedWithProfit: 'Закрыто с прибылью',
    closedWithLoss: 'Закрыто в убыток',
    account: 'Счет',
    balance: 'Баланс',
    leverage: 'Плечо',
    credit: 'Кредит',
    tradeNow: 'Торговать',
    usingCurrentRate: 'по текущей выбранной валюте',

    personalDetails: 'Персональные Данные',
    profilePhoto: 'Фото Пользователя',
    firstName: 'Имя',
    country: 'Страна',
    lastName: 'Фамилия',
    address: 'Адрес',
    email: 'Электронная почта',
    phone: 'Номер телефона',
    saveChanges: 'Сохранить Изменения',

    dropFile: 'Для загрузки перетащите файл на круг выше',
    notAllowed: 'Не разрешается размещать',
    notAllowedLine1: 'Эротические и порнографические фотографии',
    notAllowedLine2:
      'Изображения, разжигающие этническую или расовую ненависть или агрессию',
    notAllowedLine3: 'Фотографии с детьми до 18 лет',
    notAllowedLine4: 'Фотографии, защищенные авторским правом',
    notAllowedLine5:
      'Фотографии размером более 5 Мб и в формате, отличном от JPG, GIF или PNG',
    requirements:
      'На фото должно быть четко видно Ваше лицо. Все загруженные фотографии должны соответсвовать критериям, описанным выше. В противном случае они будут принудительно удалены.',

    withdrawalOff:
      'Пожалуйста, свяжитесь с вашим менеджером по работе с клиентами для получения информации о снятии средств',
    cryptocurrency: 'Криптовалюта',
    withdrawal: 'Вывод Средств',
    requestNewWithdrawal: 'ЗАПРОСИТЬ ВЫВОД СРЕДСТВ',
    amount: 'Сумма',
    currentBalance: 'Ваш текущий баланс',
    withdrawAll: 'Вывести все',
    requestWithdrawal: 'Вывод',
    last: 'ПОСЛЕДНИЕ',
    withdrawalRequests: 'ВЫВОД СРЕДСТВ',
    time: 'Время',
    currency: 'Валюта',
    info: 'Инфо',
    status: 'Статус',
    bank: 'Банк',
    bitcoin: 'Биткоин',
    card: 'Карта',
    ethereum: 'Ethereum',
    eth: 'Ethereum',
    usdt: 'USDT',
    withdrawalDetails: 'Детали Вывода Средств',
    address: 'Адрес Кошелька',
    bankAccount: 'Номер Счета',
    bankHolder: 'Имя Владельца Счета',
    bankIban: 'IBAN',
    bankSwift: 'SWIFT код',
    cardNumber: 'Номер Карты',
    cardHolder: 'Имя Владельца Карты',

    dragAndDrop: 'для загрузки перетащите документ в эту область',
    documentsList: 'СПИСОК ЗАГРУЖЕННЫХ ДОКУМЕНТОВ',
    document: 'Документ',
    timeUploaded: 'Загружен',
    timeProcessed: 'Обработан',
    status: 'Статус',
    types: [
      'Подтверждeние Личности',
      'Подтверждeние Адреса',
      'Карта - Лицевая Сторона',
      'Карта - Обратная Сторона',
      'Подтверждeние Личности - Обратная Сторона',
      'Селфи',
    ],
    types2: [
      'Подтверждeние Личности',
      'Подтверждeние Адреса',
      'Карта - Лицевая Сторона',
      'Карта - Обратная Сторона',
      'Подтверждeние Личности - Обратная Сторона',
      'Селфи',
    ],
    upload: 'Загрузить',
    isConfirmed: 'одобрено',
    uploadAnotherDocument: 'Загрузите другой документ',
    isUnderReview: 'находится в обработке',
    questionnaire: 'Опросник',
    sendAnswers: 'Отправить Ответы',
    progressBarOf: 'из',
    progressBarText: 'ваших документов были загружены и подтверждены',

    manageAccounts: 'Перевод средств между счетами',
    transferFrom: 'Со счета',
    transferTo: 'На счет',
    amount: 'Сумма',
    makeTransfer: 'Перевести Средства',
    yourAccounts: 'Ваши Счета',
    account: 'Счет',
    currency: 'Валюта',
    balance: 'Баланс',
    credit: 'Кредит',
    makeActive: 'Сделать Активным',
    active: 'Активен',

    sendMessage: 'Отправить сообщение',

    pct_0: 'Гибкий',
    pct_30: 'Фиксированный 1 Месяц',
    pct_90: 'Фиксированный 1 Квартал',
    pct_180: 'Фиксированный 6 Месяцев',
    pct_365: 'Фиксированный 1 Год',
    savings: 'Накопительные счета',
    detailedInformation: 'Подробная Информация',
    pleaseSelect: 'Пожалуйста, выберите валюту и период',
    openSavings: 'Открытые накопительные счета',
    currentTime: 'Текущее время',
    releaseTime: 'Время закрытия счета',
    currencyChosen: 'Выбранная валюта',
    periodChosen: 'Выбранный период',
    yourRate: 'Ваша ставка',
    yourEarnings: 'Ваш доход',
    accountFrom: 'Счет, с которого произвести трансфер',
    enterAmount: 'Введите сумму',
    in: 'в',
    openAndInvest: 'Открыть накопительный счет и вложить',
    investment: 'Вложение',
    period: 'Период',
    daysTotal: 'Дней всего',
    finalEarnings: 'Итоговый доход',
    created: 'Создано',
    daysElapsed: 'Дней прошло',
    days: 'дней',
    earnings: 'Доход',
    fundsReleasedIn: 'До закрытия',
    claim: 'Забрать',

    updatePassword: 'ОБНОВИТЬ ПАРОЛЬ',
    currentPassword: 'Текущий Пароль',
    newPassword: 'Новый Пароль',
    changePassword: 'Изменить Пароль',
    activationForGoogle: 'Код активации для Google Authenticator',
    activateAndEnter:
      'Активируйте Authenticator и введите сгенерированный код в поле ниже',
    qrCode: 'QR код',
    activateProtection: 'Активировать Двухфакторную Защиту',
    protectionActive: 'ДВУХФАКТОРНАЯ ЗАЩИТА АКТИВНА',
    twoFactorCode: 'Код Верификации',
    disableProtection: 'Отключить Двухфакторную Защиту',
    language: 'Язык',
    dashboardCurrency: 'Валюта для панели управления',
    confirmNewPassword: 'Подтвердите новый пароль',

    yourLink: 'ВАША ССЫЛКА ДЛЯ ПРИВЛЕЧЕНИЯ',
    referredFriends: 'ПРИВЛЕЧЕННЫЕ ДРУЗЬЯ',
    id: 'ID',
    name: 'Имя',
    registered: 'Зарегистрироваться',
    level: 'Уровень',
    payout: 'Выплата',
    lastPayouts: 'ПОСЛЕДНИЕ ВЫПЛАТЫ',
    time: 'Время',
    amount: 'Сумма',
    currency: 'Валюта',
    info: 'Комментарий',

    months: {
      Jan: 'Янв',
      Feb: 'Фев',
      Mar: 'Мар',
      Apr: 'Апр',
      May: 'Май',
      Jun: 'Июн',
      Jul: 'Июл',
      Aug: 'Авг',
      Sep: 'Сен',
      Oct: 'Окт',
      Nov: 'Ноя',
      Dec: 'Дек',
    },
    orderTypes: [
      'Покупка',
      'Продажа',
      'Лимитный Покупка',
      'Лимитный Продажа',
      'Стоп Покупка',
      'Стоп Продажа',
    ],
    statuses: ['Новый', 'Одобрен', 'Отклонен', 'В Обработке'],

    NOT_FOUND: 'Ошибка запроса',
    FORBIDDEN: 'Доступ запрещен. Пожалуйста, авторизуйтесь заново',
    SERVER_ERROR: 'Действие не удалось. Пожалуйста, попробуйте позже',
    INVALID_ACCOUNT: 'Сессия истекла. Пожалуйста, авторизуйтесь заново',
    INVALID_EMAIL: 'Неверная электронная почта',
    INVALID_PHONE: 'Неверный номер телефона',
    INVALID_COUNTRY: 'Неверная страна',
    INVALID_LOGIN: 'Неверные данные авторизации',
    USER_BLOCKED:
      'Аккаунт заблокирован. Пожалуйста, свяжитесь со службой поддержки',
    USER_REGISTERED:
      'Пользователь с такими электронной почтой или номером телефона уже зарегистрирован',
    INVALID_USER: 'Пользователь не найден',
    REJECTED: 'Операция запрещена согласно настройкам платформы',
    INVALID_OLD_PASSWORD: 'Текущий пароль неверен',
    INVALID_SYMBOL: 'Неверный торговый инструмент',
    INVALID_SYMBOL_PRECISION: 'Неверная точность после запятой',
    INVALID_FRAME: 'Неверный период',
    INVALID_CURRENCY: 'Неверная валюта',
    INVALID_PRODUCT: 'Неверный тип счета',
    INSUFFICIENT_FUNDS: 'Недостаточно денег. Пожалуйста, внесите депозит',
    INVALID_WITHDRAWAL: 'Запрос на вывод средств не найден',
    INVALID_STATUS: 'Неверный статус',
    INVALID_FILE: 'Неверный тип файла или файл слишком большой',
    INVALID_DOCUMENT: 'Документ не найден',
    INVALID_NAME: 'Неверное имя',
    INVALID_LOT_SIZE: 'Неверный размер лота',
    INVALID_LOT_STEP: 'Неверный шаг лота',
    INVALID_MIN_VOLUME: 'Неверный минимальный объем',
    INVALID_MAX_VOLUME: 'Неверный максимальный объем',
    INVALID_GROUP: 'Неверная группа',
    INVALID_SPREAD: 'Неверный спред',
    REQUEST_TIMEOUT:
      'Время ожидания запроса истекло. Пожалуйста, попробуйте еще раз',
    INVALID_TYPE: 'Неверный тип ордера',
    INVALID_VOLUME: 'Неверный объем ордера',
    INVALID_SL: 'Неверный стоп лосс',
    INVALID_TP: 'Неверный тейк профит',
    INVALID_PRICE: 'Неверная цена ордера',
    INVALID_EXPIRATION: 'Неверное время экспирации',
    NOT_ENOUGH_MARGIN: 'Недостаточно маржи',
    INVALID_ORDER: 'Ордер не найден',
    MARKET_CLOSED: 'Торговля недоступна, рынок закрыт',
    INVALID_BALANCE_TYPE: 'Неверный тип балансовой операции',
    INVALID_HASH: 'Неверный хеш',
    HASH_EXPIRED:
      'Ваша ссылка на сброс пароля истекла. Пожалуйста, запросите новую',
    INVALID_CODE: 'Неверный код 2FA',
    CHAT_DISABLED: 'Доступ к чату поддержки был закрыт',
    WITHDRAWAL_NOT_ALLOWED: 'Доступ к выводу средств был закрыт',
    TRADING_DISABLED: 'Торговля отключена для этого счета',
    PENDING_DEPOSITS_LIMIT: 'Превышен лимит депозитов',
    LOCAL_ONE_PENDING_WITHDRAWAL:
      'Возможно только одно неподтвержденное снятие средств',
    LOCAL_DEPOSIT_ONLY_REAL:
      'Пополнение возможно только для реального аккаунта',
    LOCAL_DEPOSIT_POSITIVE: 'Сумма депозита должна быть больше 0',
    LOCAL_INVALID_CARD_NUMBER: 'Неверный номер карты',
    LOCAL_INVALID_CARD_EXPIRATION: 'Неверная дата истечения срока действия',
    LOCAL_INVALID_CVC: 'Неверный код CVV/CVC',
    LOCAL_PASSWORDS_NOT_MATCH: 'Пароли не сопадают, пожалуйста, введите снова',
    LOCAL_NO_TRADING_ACCOUNTS:
      'У вас нет торговых счетов. Пожалуйста, свяжитесь со службой поддержки',
    LOCAL_MIN_DEPOSIT: 'Минмальная сумма пополнения - $',
    LOCAL_MIN_WITHDRAWAL: 'Минимальная сумма снятия - $',
    LOCAL_INVALID_FILE_TYPE:
      'Неподдерживаемый формат файла. Пожалуйста, приложите файл JPG, PNG или PDF',
    LOCAL_MIN_STAKING: 'Минимальная сумма для выбранной валюты ',
    FLOOD_REJECTED:
      'Вы отправляете сообщения слишком часто. Пожалуйста, повторите попытку позже',

    countries: {
      AF: 'Афганистан',
      AX: 'Аландские острова',
      AL: 'Албания',
      DZ: 'Алжир',
      AS: 'Американское Самоа',
      AD: 'Андорра',
      AO: 'Ангола',
      AI: 'Ангилья',
      AQ: 'Антарктида',
      AG: 'Антигуа и Барбуда',
      AR: 'Аргентина',
      AM: 'Армения',
      AW: 'Аруба',
      AU: 'Австралия',
      AT: 'Австрия',
      AZ: 'Азербайджан',
      BS: 'Багамские Острова',
      BH: 'Бахрейн',
      BD: 'Бангладеш',
      BB: 'Барбадос',
      BY: 'Белоруссия',
      BE: 'Бельгия',
      BZ: 'Белиз',
      BJ: 'Бенин',
      BM: 'Бермудские Острова',
      BT: 'Бутан',
      BO: 'Боливия',
      BA: 'Босния и Герцеговина',
      BW: 'Ботсвана',
      BV: 'Остров Буве',
      BR: 'Бразилия',
      IO: 'Британская территория в Индийском океане',
      BN: 'Бруней',
      BG: 'Болгария',
      BF: 'Буркина-Фасо',
      BI: 'Бурунди',
      KH: 'Камбоджа',
      CM: 'Камерун',
      CA: 'Канада',
      CV: 'Кабо-Верде',
      KY: 'Острова Кайман',
      CF: 'ЦАР',
      TD: 'Чад',
      CL: 'Чили',
      CN: 'Китай',
      CX: 'Остров Рождества',
      CC: 'Кокосовые острова',
      CO: 'Колумбия',
      KM: 'Коморы',
      CG: 'Конго',
      CD: 'Демократическая Республика Конго',
      CK: 'Острова Кука',
      CR: 'Коста-Рика',
      CI: 'Кот-д’Ивуар',
      HR: 'Хорватия',
      CU: 'Куба',
      CY: 'Кипр',
      CZ: 'Чехия',
      DK: 'Дания',
      DJ: 'Джибути',
      DM: 'Доминика',
      DO: 'Доминиканская Республика',
      EC: 'Эквадор',
      EG: 'Египет',
      SV: 'Сальвадор',
      GQ: 'Экваториальная Гвинея',
      ER: 'Эритрея',
      EE: 'Эстония',
      ET: 'Эфиопия',
      FK: 'Фолклендские острова',
      FO: 'Фарерские острова',
      FJ: 'Фиджи',
      FI: 'Финляндия',
      FR: 'Франция',
      GF: 'Гвиана',
      PF: 'Французская Полинезия',
      TF: 'Французские Южные и Антарктические территории',
      GA: 'Габон',
      GM: 'Гамбия',
      GE: 'Грузия',
      DE: 'Германия',
      GH: 'Гана',
      GI: 'Гибралтар',
      GR: 'Греция',
      GL: 'Гренландия',
      GD: 'Гренада',
      GP: 'Гваделупа',
      GU: 'Гуам',
      GT: 'Гватемала',
      GG: 'Гернси',
      GN: 'Гвинея',
      GW: 'Гвинея-Бисау',
      GY: 'Гайана',
      HT: 'Гаити',
      HM: 'Остров Херд и острова Макдональд',
      VA: 'Ватикан',
      HN: 'Гондурас',
      HK: 'Гонк Конг',
      HU: 'Венгрия',
      IS: 'исландия',
      IN: 'Индия',
      ID: 'Индонезия',
      IR: 'Иран',
      IQ: 'Ирак',
      IE: 'Ирландия',
      IM: 'Остров Мэн',
      IL: 'Израиль',
      IT: 'Италия',
      JM: 'Ямайка',
      JP: 'Япония',
      JE: 'Джерси',
      JO: 'Иордания',
      KZ: 'Казахстан',
      KE: 'Кения',
      KI: 'Кирибати',
      KR: 'Корея',
      KW: 'Кувейт',
      KG: 'Киргизия',
      LA: 'Лаос',
      LV: 'Латвия',
      LB: 'Ливан',
      LS: 'Лесото',
      LR: 'Либерия',
      LY: 'Ливия',
      LI: 'Лихтенштейн',
      LT: 'Литва',
      LU: 'Люксембург',
      MO: 'Макао',
      MK: 'Македония',
      MG: 'Мадагаскар',
      MW: 'Малави',
      MY: 'Малайзия',
      MV: 'Мальдивы',
      ML: 'Мали',
      MT: 'Мальта',
      MH: 'Маршалловы Острова',
      MQ: 'Мартиника',
      MR: 'Мавритания',
      MU: 'Маврикий',
      YT: 'Майотта',
      MX: 'Мексика',
      FM: 'Микронезия',
      MD: 'Молдова',
      MC: 'Монако',
      MN: 'Монголия',
      ME: 'Черногория',
      MS: 'Монтсеррат',
      MA: 'Марокко',
      MZ: 'Мозамбик',
      MM: 'Мьянма',
      NA: 'Намибия',
      NR: 'Науру',
      NP: 'Непал',
      NL: 'Нидерланды',
      AN: 'Нидерландские Антильские острова',
      NC: 'Новая Каледония',
      NZ: 'Новая Зеландия',
      NI: 'Никарагуа',
      NE: 'Нигер',
      NG: 'Нигерия',
      NU: 'Ниуэ',
      NF: 'Норфолк',
      MP: 'Северные Марианские острова',
      NO: 'Норвегия',
      OM: 'Оман',
      PK: 'Пакистан',
      PW: 'Палау',
      PS: 'Палестинские Территории',
      PA: 'Панама',
      PG: 'Папуа — НоваяГвинея',
      PY: 'Парагвай',
      PE: 'Перу',
      PH: 'Филиппины',
      PN: 'Острова Питкэрн',
      PL: 'Польша',
      PT: 'Португалия',
      PR: 'Пуэрто-Рико',
      QA: 'Катар',
      RE: 'Реюньон',
      RO: 'Румыния',
      RU: 'Россия',
      RW: 'Руанда',
      BL: 'Сен-Бартелеми',
      SH: 'Остров Святой Елены',
      KN: 'Сент-Китс и Невис',
      LC: 'Сент-Люсия',
      MF: 'Сен-Мартен',
      PM: 'Сен-Пьер и Микелон',
      VC: 'Сент-Винсент и Гренадины',
      WS: 'Самоа',
      SM: 'Сан Марино',
      ST: 'Сан-Томе и Принсипи',
      SA: 'Саудовская Арабия',
      SN: 'Сенегал',
      RS: 'Сербия',
      SC: 'Сейшелы',
      SL: 'Сьерра-Леоне',
      SG: 'Сингапур',
      SK: 'Словакия',
      SI: 'Словения',
      SB: 'Соломоновы Острова',
      SO: 'Сомали',
      ZA: 'ЮАР',
      GS: 'Южная Георгия и Южные Сандвичевы Острова',
      ES: 'Испания',
      LK: 'Шри-Ланка',
      SD: 'Судан',
      SR: 'Суринам',
      SJ: 'Шпицберген и Ян-Майен',
      SZ: 'Эсватини',
      SE: 'Швеция',
      CH: 'Швейцария',
      SY: 'Сирия',
      TW: 'тайвань',
      TJ: 'Таджикистан',
      TZ: 'Танзания',
      TH: 'Тайланд',
      TL: 'Восточный Тимор',
      TG: 'Того',
      TK: 'Токелау',
      TO: 'Тонга',
      TT: 'Тринидад и Тобаго',
      TN: 'Тунис',
      TR: 'Турция',
      TM: 'Туркменистан',
      TC: 'Тёркс и Кайкос',
      TV: 'Тувалу',
      UG: 'Уганда',
      UA: 'Украина',
      AE: 'ОАЭ',
      GB: 'Великобритания',
      US: 'США',
      UM: 'Внешние малые острова США',
      UY: 'Уругвай',
      UZ: 'Узбекистан',
      VU: 'Вануату',
      VE: 'Венесуэла',
      VN: 'Вьетнам',
      VG: 'Виргинские Острова',
      VI: 'Виргинские Острова (США)',
      WF: 'Уоллис и Футуна',
      EH: 'Западная Сахара',
      YE: 'Йемен',
      ZM: 'Замбия',
      ZW: 'Зимбабве',
    },
  },
  pl: {
    _name: 'Polski',

    policy: 'Polityka prywatności',
    policyAML: 'Polityka AML (Przeciwdziałanie praniu pieniędzy)',
    policyKYC: 'Polityka KYC (Poznaj swojego klienta)',
    locense: 'Licencja',

    view_all: 'Zobacz wszystkie',

    sell_btn: 'Sprzedać',
    buy_btn: 'Kupić',

    currency_action: 'waluta',

    importantAccountDetails: 'Ważne szczegóły konta',
    viewAdditionalSettings: 'Wyświetl dodatkowe ustawienia',
    switchDarkMode: 'Przełącz tryb ciemny/jasny',
    writeProblem: 'Napisz do nas swój problem',
    darkMode: 'Tryb ciemny',

    yay: 'Hurra',
    purchased: 'Pomyślnie kupiłeś',
    from: 'z',
    walet: 'Portfela',
    trade: 'Handlu',

    withdrawDetails: 'Szczegóły wypłaty',
    saveDetails: 'Zapisz szczegóły na następny raz',
    amountToWithdraw: 'Kwota do wypłaty',
    withdrawLimit: 'Pozostało 1 000 000,00 USD dziennego limitu wypłat.',

    serviceFee: 'Opłata za usługę',
    youWillPay: 'Zapłacisz',
    cancel: 'Anuluj',
    understand: 'Rozumiem, kontynuuj',

    select: 'Wybierz',
    enterAmount: 'Wprowadź kwotę',
    orderInfo: 'Informacje o zamówieniu',
    confirmOrder: 'Potwierdź zamówienie',
    get: 'Uzyskaj',
    spend: 'Wydaj',
    buying: 'Kupowanie',

    accountTransactions: 'Transakcje na koncie',

    overview: 'Przegląd',
    accountBalances: 'Salda kont',
    details: 'Szczegóły',
    loginHistory: 'Historia logowania',
    device: 'Urządzenie',
    contactUs: 'Skontaktuj się z nami',
    sessionLog: 'Sitzungen und Anmeldeverlauf',
    selectMethods: 'Wybierz metody',
    depositDetails: 'Szczegóły depozytu',
    support: 'Wsparcie',
    homepage: 'Strona główna',
    supportChat: 'Czat wsparcia',
    createChat: 'Utwórz czat',

    takeProfitStopLoss: 'TAKE PROFIT & STOP LOSS',
    takeProfit: ' Take Profit',
    stopLoss: ' Stop Loss',
    updateOrder: ' Zaktualizuj zamówienie',

    balance: 'Saldo',
    credit: ' Kredyt',
    equity: ' Kapital',
    practice: ' PRAKTYKA',
    real: ' REALNY',
    account: ' KONTO',
    invested: ' Zainwestowany',
    profit: ' Zysk',
    loss: ' Strata',
    margin: ' Margines',
    marginLevel: 'Poziom marginesu',
    marginFree: 'Wolny margines',

    cfd: 'CFD',
    platform: ' Platforma',
    deposit: ' Depozyt',

    marketWatch: 'Obserwacja rynku',
    activeOrders: 'Aktywne zamówienia',
    tradingHistory: 'Historia handlu',
    economicCalendar: 'Kalendarz ekonomiczny',
    marketNews: 'Wiadomosci rynkowe',

    closeOrder: 'Zamknij pozycje',
    areYouSure: 'Czy na pewno zamkniesz pozycje',
    buy: 'Kupic',
    sell: 'Sprzedac',
    yes: 'Tak',
    no: 'Nie',

    addNewChart: 'Dodaj nowy wykres',

    watchlist: 'Lista obserwowanych',
    asset: 'Aktywa',
    price: 'Cena',
    changePct: 'Wymiana 24h',

    pending: 'W oczekiwaniu',
    assetPrice: 'Cena aktywów',
    current: ' Aktualny',
    revert: 'Powrót do ceny rynkowej',
    automatically:
      'Pozycja zostanie otwarta automatycznie, gdy cena osiegnie ten poziom',

    default: 'default',
    volume: 'Pojemnosc',
    lots: 'partii',
    units: ' jednostki',
    currency: 'waluta',
    contractSize: 'Wielkosc kontraktu',
    position: 'Pozycja',
    margin: 'Margines',
    freeMargin: 'Wolny margines',
    takeProfitStopLoss: 'Odbierz zysk i zatrzymaj strate',
    pending: 'W oczekiwaniu',
    market: 'Rynek',
    leverage: 'Dźwignia',
    spread: 'Spread',
    notSet: 'Nie ustawiony',
    at: 'w',
    buy: 'kupić',
    sell: 'sprzedać',

    supportBanner: 'KAZDEGO DNIA PRZEZ CALE DOBE',
    currentTime: 'OBECNY CZAS',
    liveChat: 'Czat na zywo',

    balance: 'Saldo',
    credit: 'Kredyt',
    equity: 'Kapital',
    margin: 'Margines',
    marginLevel: 'Poziom marginesu',
    freeMargin: 'Wolny margines',
    pnl: 'PnL',
    profitTotal: 'Lifetime PnL',

    dateRegistered: 'Data rejestracji',
    userId: 'Identyfikator uzytkownika',
    dashboard: 'Dashboard',
    personalData: 'Dane osobiste',
    deposit: 'Depozyt',
    withdrawFunds: 'Wypłatę srodków',
    savings: 'Oszczednosci',
    settings: 'Ustawienia',
    logout: 'Wyloguj sie',

    BT_INVOICE: 'Enter amount and generate invoice',
    BT_TITLE: 'Our banking details',
    PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
    account: 'Account',
    bank_name: 'Bank Name',
    beneficiary: 'Beneficiary',
    beneficiary_address: 'Beneficiary Address',
    swift: 'Swift',
    iban: 'IBAN',
    ru_inn: 'INN',
    ru_kpp: 'KPP',
    corresponding_account: 'Correspondent account',
    TOKEN_AMOUNT: 'Amount',
    TOKEN_SUBTOTAL: 'Subtotal',
    TOKEN_TOTAL: 'Total',
    TOKEN_DESCRIPTION: 'Description',
    TOKEN_PAYMENT_DETAILS: 'Payment Details',
    makeDeposit: 'Zlozyc depozyt',
    practiceAccount: 'Konto treningowe',
    realAccount: 'Prawdziwe konto',
    noWithdrawals: 'Brak wyplat',
    easyWithdrawals: 'latwe wyplaty',
    allAssets: 'Wszystkie aktywy dostepne',
    fullFledged: 'Pelnoprawna platforma',
    fillUpTo: 'Wypelnij do',
    freeReplenishment: 'Bezplatne uzupelnianie',
    topUp: 'Doladuj swoje konto',
    minimumAmount: 'Stawka minimalna',
    canSwitch:
      'Mozesz przelaczac sie miedzy swoimi kontami w dowolnym momencie',

    chartGrids: 'SIATKI WYKRESÓW',
    chart1: '1 wykres',
    chart2: '2 wykres',
    chart3: '3 wykres',
    chart4: '4 wykres',

    connectionLost: 'Polaczenie z serwerem zostalo utracone',
    retryNow: 'Spróbuj teraz',

    loginToTradeRoom: 'Zaloguj sie do Traderoom',
    email: 'Adres e-mail',
    enterEmail: 'Wpisz swój e-mail',
    twoFactor: 'Kod 2FA (jesli jest wlaczony)',
    twoFactorAuth: 'Uwierzytelnianie dwuskladnikowe',
    password: 'Haslo',
    yourPassword: 'Twoje haslo',
    signIn: 'Zaloguj sie',
    dontHaveAccount: 'Don’t have an account?',
    restore: 'Restore',
    fullName: 'Full Name',
    havePromo: 'I have a promo-code',
    promo: 'Promo code',
    login: 'Login',
    haveAccount: 'Already have an account?',
    hide: 'Hide',
    goTo: 'Go to back',

    setNewPassword: 'Ustaw nowe haslo',
    newPassword: 'Nasza zalety',
    confirmNewPassword: 'Potwierdź nowe hasło',
    setButton: 'Ustaw',

    repeatPassword: 'Repeat Password',
    createNewAccount: 'Utwórz nowe konto',
    email: 'Adres e-mail',
    firstName: 'Imie',
    lastName: 'Nazwisko',
    country: 'Kraj',
    phone: 'Telefon',
    password: 'Haslo',
    createAccount: 'Utwórz konto',
    currency: 'Waluta',

    forgotPassword: 'Nie pamietam hasla?',
    submitEmail:
      'Prosimy o podanie e-maila uzytego do rejestracji, sprawdzenie skrzynki odbiorczej i postepowanie zgodnie z podanymi instrukcjami.',
    submit: 'Przeslij',

    error: 'Blad',
    success: 'Sukces',
    deposit: 'Depozyt',
    withdrawal: 'Wycofanie',
    depositApproved: 'Depozyt zostal zatwierdzony',
    depositDeclined: 'Depozyt zostal odrzucony',
    withdrawalApproved: 'Wycofanie zatwierdzone',
    withdrawalDeclined: 'Odmówiono wyplaty',

    forex: 'Forex',
    stocks: 'Akcje',
    commodities: 'Towary',
    indices: 'Wskazniki',
    crypto: 'Kryptowaluta',
    metals: 'Metale',
    nft: 'NFT',

    buy: 'BUY',
    profitCalculator: 'Profit Calculator',
    sell: 'SELL',
    volume: 'Volume',
    entryPrice: 'Entry Price',
    takeProfit: 'Take Profit',
    stopLoss: 'Stop Loss',
    maxPosition: 'Max Position',
    calculate: 'Calculate',
    calculationsFor: 'Calculations for',
    leverage: 'Leverage',
    requiredMargin: 'Required Margin',
    profitFromTP: 'Profit from TP',
    lossFromSL: 'Loss from SL',
    roe: 'ROE',

    title: 'Deposit successful',
    text: 'Your deposit has been processed successfully!',

    title: 'Deposit failed',
    text: 'Your deposit has not been processed.',

    marketWatch: 'Obserwacja rynku',
    search: 'Szukac  ...',

    commission: 'Commission',
    volume: 'Tom',
    margin: 'Marza',
    active: 'Aktywny',
    pending: 'W trakcie realizacji',
    activeOrders: 'Zamówienia aktywne',
    portfolio: 'Portfolio',
    allPositions: 'Wszystkie stanowiska',
    noPositionsLine1: 'Nie masz otwartego',
    noPositionsLine2: 'jeszcze stanowiska',
    show: 'Pokaz',
    more: 'wiecej',
    less: 'mniej',
    purchaseTime: 'Czas zakupu',
    closePosition: 'Pozycja zamkniecia',
    priceOpen: 'Cena zakupu',
    priceSl: 'Cena Stop Loss',
    priceTp: 'Cena Take Profit',
    type: 'Pozycja Kierunek',
    pnl: 'Zysk/Strata',
    cancelOrder: 'Anuluj zamówienie',
    order: 'Zamówienie',

    orderType: 'Typ zamówienia',
    cancelled: 'Anulowane',
    tradingHistory: 'Historia handlu',
    noHistoryLine1: 'Nie masz zadnych',
    noHistoryLine2: 'jeszcze zamkniete transakcje',

    economicCalendar: 'Kalendarz ekonomiczny',

    marketNews: 'Wiadomosci rynkowe',

    ordersActive: 'Zamówienia Aktywne',
    ordersHistory: 'Historia zamówien',
    id: 'ID',
    symbol: 'Symbol',
    type: 'Typ',
    volume: 'Tom',
    openPrice: 'Cena wywolawcza',
    openTime: 'Czas otwarcia',
    closePrice: 'Zamknij cene',
    closeTime: 'Czas zamkniecia',
    sl: 'SL',
    tp: 'TP',
    price: 'Cena',
    pnl: 'PnL',
    actions: 'Dzialania',
    edit: 'Edytować',
    close: 'Zamknać',
    commission: 'Commission',
    swap: 'Swap',
    swapLong: 'Swap Long',
    swapShort: 'Swap Short',

    assetInfo: 'Asset Info',
    tradingConditions: 'Trading Conditions',
    information: 'Session Change',
    bid: 'Bid',
    ask: 'Ask',
    sessionChange: 'Session Change',
    tradeNow: 'Trade Now',
    opens: 'Opens',
    closes: 'Closes',
    at: 'at',
    open247: 'Open 24/7',
    today: 'today',
    tomorrow: 'tomorrow',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    contractSpecification: 'Contract Specification',
    symbol: 'Symbol',
    name: 'Name',
    market: 'Market',
    baseCurrency: 'Base Currency',
    digits: 'Digits',
    lotSize: 'Lot Size',
    lotStep: 'Lot Step',
    minVolume: 'Min Volume',
    maxVolume: 'Max Volume',
    leverage: 'Leverage',
    commission: 'Commission',
    swapLong: 'Swap Long',
    swapShort: 'Swap Short',
    schedule: 'Schedule',
    weekday: 'Weekday',
    tradingTime: 'Trading Time',
    closed: 'closed',
    sell: 'Sell',
    buy: 'Buy',
    low: 'Low',
    high: 'High',
    oneHourChange: '1 hour change',
    oneDayChange: '1 day change',
    oneWeekChange: '1 week change',
    oneMonthChange: '1 month change',
    threeMonthsChange: '3 months change',
    oneYearChange: '1 year change',
    loading: 'Loading...',

    months: {
      Jan: 'Styczen',
      Feb: 'Luty',
      Mar: 'Marzec',
      Apr: 'Kwiecien',
      May: 'Maj',
      Jun: 'Czerwiec',
      Jul: 'Lipiec',
      Aug: 'Sierpien',
      Sep: 'Wrzesien',
      Oct: 'Pazdziernik',
      Nov: 'Listopad',
      Dec: 'Grudzien',
    },

    title: 'Auto Trader',
    riskLevelLow: 'Risk Level: Low',
    riskLevelMedium: 'Risk Level: Medium',
    riskLevelHigh: 'Risk Level: High',
    perDay: 'Trades per day:',
    desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
              read the
              relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
              company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
              fully understand the risks and take measures to manage risks.`,
    accept: 'Accept',

    trade: 'Handel',
    startTrading: 'Rozpocznij Handel',
    deposit: 'Dokonaj wplaty',
    dashboard: 'Pulpit',
    personalInfo: 'Aktualizacja danych osobowych',
    withdrawal: 'Wypłata środków',
    verification: 'Weryfikacja tożsamości',
    accounts: 'Zarządzaj kontami',
    liveChat: 'Czat na żywo',
    savings: 'Lokaty',
    settings: 'Ustawienia platformy',
    logOut: 'Wyloguj się',

    methods: 'METHODS',
    confirm: 'Confirm in the application of your bank',
    sendOtp: 'Send otp',
    otpCode: 'Otp code',
    addWalletAddress:
      'Wallet for this deposit method is not set. Please contact your agent or support team.',
    addressReceipt: 'Please wait for the address to load',
    makeDeposit: 'DOKONAJ WPLATY DEPOZYTU',
    instant: 'Natychmiast',
    minutes: 'minuty',
    hours: 'godziny',
    days: 'dni',
    amount: 'Kwota',
    continue: 'Kontynuuj',
    qrCode: 'KOD QR',
    depositAddress: 'ADRES DEPOZYTOWY',
    copy: 'Kopia',
    last: 'OSTATNI',
    deposits: 'DEPOZYTY',
    time: 'Czas',
    currency: 'Waluta',
    status: 'Status',
    info: 'Informacje',

    pleaseWait: 'Prosze czekac. Polaczenie z dostawca uslug platniczych...',
    cardPayment: 'Platnosc karta',
    inOrder: 'W celu wyslania kwoty',
    pleaseProvide: 'Prosze podac dane karty:',

    completeVerification: 'Please Complete Verification',
    noOrderYet: 'No order yet',
    totalBalance: 'Całkowite Saldo',
    totalDeposits: 'Razem depozyty',
    totalPnl: 'Całkowity PNL',
    profitableOrders: 'Zyskowne pozycję',
    roi: 'ROI',
    activityLog: 'Lista Aktywności',
    loginFromIp: 'Login from IP',
    tradingResults: 'Trading Results',
    week: 'Tydzień',
    month: 'Miesiąc',
    year: 'Rok',
    successRate: 'Wskaźnik Sukcesu',
    closedWithProfit: 'Zamknięte z Zyskiem',
    closedWithLoss: 'Zamknięte ze stratą',
    account: 'Konto',
    balance: 'Saldo',
    leverage: 'Dźwignia',
    credit: 'Kredyt',
    tradeNow: 'Rozpocznij Handel',
    usingCurrentRate: 'using current exchange rate',

    personalDetails: 'Dane osobowe',
    profilePhoto: 'Zdjecie profilowe',
    firstName: 'Imię',
    country: 'Kraj',
    lastName: 'Nazwisko',
    address: 'Adres',
    email: 'E-mail',
    phone: 'Numer telefonu',
    saveChanges: 'Zapisac zmiany',

    dropFile: 'Wrzuc plik na okrag powyzej, aby go przeslac',
    notAllowed: 'Niedozwolone jest publikowanie',
    notAllowedLine1:
      'Zdjecia o charakterze jednoznacznie seksualnym lub pornograficznym',
    notAllowedLine2:
      'Obrazy majace na celu podzeganie do nienawisci lub agresji na tle etnicznym lub rasowym',
    notAllowedLine3: 'Zdjecia z udzialem osób ponizej 18 roku zycia',
    notAllowedLine4: 'Zdjecia chronione prawami autorskimi osób trzecich',
    notAllowedLine5:
      'Obrazy wieksze niz 5 MB i w formacie innym niz JPG, GIF lub PNG',
    requirements:
      'Twoja twarz musi byc wyraznie widoczna na zdjeciu. Wszystkie zdjecia i filmy zamieszczane przez Ciebie musza spelniac te wymagania, w przeciwnym razie moga zostac usuniete.',

    withdrawalOff: 'Please contact your account manager for withdrawal details',
    cryptocurrency: 'Cryptocurrency',
    withdrawal: 'Withdrawal',
    requestNewWithdrawal: 'WNIOSEK O NOWA WYPŁATĘ',
    amount: 'Kwota',
    currentBalance: 'Stan Twojego rachunku biezacego wynosi',
    withdrawAll: 'Wycofac wszystkie',
    requestWithdrawal: 'Wypłać',
    last: 'OSTATNI',
    withdrawalRequests: 'Historia',
    time: 'Czas',
    currency: 'WALUTA',
    info: 'Informacje',
    status: 'Status',
    bank: 'Bank',
    bitcoin: 'Bitcoin',
    card: 'Karta',
    ethereum: 'Ethereum',
    eth: 'Ethereum',
    usdt: 'USDT',
    withdrawalDetails: 'Szczególy wypłaty',
    address: 'Adres portfela',
    bankAccount: 'Numer konta',
    bankHolder: 'Nazwa posiadacza konta',
    bankIban: 'IBAN',
    bankSwift: 'Kod SWIFT banku',
    cardNumber: 'Numer karty',
    cardHolder: 'Nazwa posiadacza karty',

    dragAndDrop: 'przeciagnij i upuść dokument na ten obszar',
    documentsList: 'LISTA PRZESLANYCH DOKUMENTÓW',
    document: 'Dokument',
    timeUploaded: 'Wyslano',
    timeProcessed: 'Zweryfikowano',
    status: 'Status',
    types: [
      'Przód dowodu',
      'Dokument potwierdzający adres zamieszkania',
      'Przód karty kredytowej',
      'Tył karty kredytowej',
      'Tył dowodu',
      'Selfie',
    ],
    types2: [
      'Proof of identification (POI)',
      'Proof of residential address',
      'Credit Card Front',
      'Credit Card Back',
      'Proof of identification Back',
      'Selfie with ID',
    ],
    upload: 'Wyślij',
    isConfirmed: 'jest potwierdzony',
    uploadAnotherDocument: 'Wyślij inny dokument',
    isUnderReview: 'jest w trakcie przegladu',
    questionnaire: 'Kwestionariusz',
    sendAnswers: 'Wyslij odpowiedzi',
    progressBarOf: 'z',
    progressBarText: 'twoich dokumentów zostały wgrane i potwierdzone',

    manageAccounts: 'Przelewaj srodki pomiedzy swoimi kontami',
    transferFrom: 'Z konta',
    transferTo: 'Na konto',
    amount: 'Kwota',
    makeTransfer: 'Dokonaj transferu',
    yourAccounts: 'Twoje konta',
    account: 'Konto',
    currency: 'Waluta',
    balance: 'Saldo',
    credit: 'Kredyt',
    makeActive: 'Uczyn aktywnym',
    active: 'Aktywny',

    sendMessage: 'Napisz wiadomość...',

    pct_0: 'Dynamiczna',
    pct_30: 'Terminowa miesiąc',
    pct_90: 'Terminowa kwartał',
    pct_180: 'Terminowa 6 miesięcy',
    pct_365: 'Terminowa rok',
    savings: 'Oszczednosci',
    detailedInformation: 'Szczególowe informacje',
    pleaseSelect: 'Prosze wybrac walute i okres',
    openSavings: 'Oszczednosci otwarte',
    currentTime: 'Aktualny czas',
    releaseTime: 'Czas wydania',
    currencyChosen: 'Wybrana waluta',
    periodChosen: 'Wybrany okres',
    yourRate: 'Twoja stawka',
    yourEarnings: 'Twoje zarobki',
    accountFrom: 'Z konta',
    enterAmount: 'Wprowadz kwote',
    in: 'W',
    openAndInvest: 'Otwórz konto oszczednosciowe i inwestuj',
    investment: 'Lokata',
    period: 'Okres',
    daysTotal: 'Dni',
    finalEarnings: 'Potencjialny zysk',
    created: 'Utworzony',
    daysElapsed: 'Dni, które uplynely',
    days: 'dni',
    earnings: 'Zarobki',
    fundsReleasedIn: 'Odbłokowane za',
    claim: 'Roszczenie',

    updatePassword: 'AKTUALIZACJA HASLA',
    currentPassword: 'Aktualne haslo',
    newPassword: 'Nowe hasło',
    changePassword: 'Zmiana hasła',
    activationForGoogle: 'Kod aktywacyjny dla Google Authenticator',
    activateAndEnter:
      'Aktywuj kod uwierzytelniający i wprowadź wygenerowany kod w polu poniżej',
    qrCode: 'Kod QR',
    activateProtection: 'Aktywuj ochrone 2FA',
    protectionActive: 'Ochrona 2FA jest aktywna',
    twoFactorCode: 'Kod 2FA',
    disableProtection: 'Wylacz ochrone 2FA',
    language: 'Jezyk',
    dashboardCurrency: 'Waluta na Panelu',
    confirmNewPassword: 'Potwierdź nowe hasło',

    yourLink: 'TWÓJ LINK POLECAJACY',
    referredFriends: 'POLECENI ZNAJOMI',
    id: 'ID',
    name: 'Imie',
    registered: 'Zarejestrowany',
    level: 'Poziom',
    payout: 'Wypłatę',
    lastPayouts: 'OSTATNIE WYPLATY ZA POLECENIE',
    time: 'Czas',
    amount: 'Kwota',
    currency: 'Walut',
    info: 'Informacje',

    orderTypes: [
      'Kupic',
      'Sprzedac',
      'Wykupic limit',
      'Limit sprzedazy',
      'Zakup Stop',
      'Sprzedac Stop',
    ],
    statuses: ['W trakcie realizacji', 'Zatwierdzony', 'Odrzucony', 'Obróbka'],

    NOT_FOUND: 'Blad zadania',
    FORBIDDEN: 'Odmowa dostepu. Prosimy o ponowne zalogowanie.',
    SERVER_ERROR: 'Akcja nie powiodla sie. Prosze spróbowac ponownie pózniej',
    INVALID_ACCOUNT:
      'Akcja nie powiodla sie. Prosze spróbowac ponownie pózniej',
    INVALID_EMAIL: 'Nieprawidlowy adres e-mail',
    INVALID_PHONE: 'Nieprawidlowy numer telefonu',
    INVALID_COUNTRY: 'Nieprawidlowy kraj',
    INVALID_LOGIN: 'Nieprawidlowe poswiadczenia logowania',
    USER_BLOCKED: 'Konto jest zablokowane. Prosimy o kontakt z obsluga',
    USER_REGISTERED:
      'Uzytkownik o podanym adresie e-mail lub numerze telefonu jest juz zarejestrowany',
    INVALID_USER: 'Uzytkownik nie zostal znaleziony',
    REJECTED: 'Dzialanie nie jest dozwolone z powodu ograniczen platformy',
    INVALID_OLD_PASSWORD: 'Stare haslo jest niewazne',
    INVALID_SYMBOL: 'Nieprawidlowy symbol handlowy',
    INVALID_SYMBOL_PRECISION: 'Nieprawidlowa precyzja symbolu',
    INVALID_FRAME: 'Nieprawidlowa ramka wykresu',
    INVALID_CURRENCY: 'Nieprawidlowa waluta',
    INVALID_PRODUCT: 'Nieprawidlowy typ handlowy konta',
    INSUFFICIENT_FUNDS: 'Niewystarczajace srodki finansowe',
    INVALID_WITHDRAWAL: 'Wycofanie nie zostalo odnalezione',
    INVALID_STATUS: 'Nieprawidlowy status',
    INVALID_FILE: 'Nieprawidlowe rozszerzenie pliku lub plik jest zbyt duzy',
    INVALID_DOCUMENT: 'Dokument nie zostal znaleziony',
    INVALID_NAME: 'Nieprawidlowa nazwa',
    INVALID_LOT_SIZE: 'Nieprawidlowa wielkosc dzialki',
    INVALID_LOT_STEP: 'Nieprawidlowy krok partii',
    INVALID_MIN_VOLUME: 'Nieprawidlowa objetosc minimalna',
    INVALID_MAX_VOLUME: 'Nieprawidlowa objetosc maksymalna',
    INVALID_GROUP: 'Nieprawidlowa grupa',
    INVALID_SPREAD: 'Niewazny rozrzut',
    REQUEST_TIMEOUT:
      'Zadanie zostalo przerwane. Prosze spróbowac ponownie pózniej',
    INVALID_TYPE: 'Nieprawidlowy typ zamówienia',
    INVALID_VOLUME: 'Nieprawidlowa wielkosc zamówienia',
    INVALID_SL: 'Nieprawidlowa wartosc stop loss',
    INVALID_TP: 'Nieprawidlowa wartosc take profit',
    INVALID_PRICE: 'Nieprawidlowa cena zamówienia',
    INVALID_EXPIRATION: 'Nieprawidlowy termin waznosci zamówienia',
    NOT_ENOUGH_MARGIN: 'Niewystarczajaca marza',
    INVALID_ORDER: 'Zamówienie nie zostalo znalezione',
    MARKET_CLOSED: 'Handel nie jest dostepny. Prosze spróbowac ponownie',
    INVALID_BALANCE_TYPE: 'Nieprawidlowy typ operacji bilansowej',
    INVALID_HASH: 'Nieprawidlowy hash',
    HASH_EXPIRED: 'Twój link do resetowania hasla wygasl. Popros o nowe haslo.',
    INVALID_CODE: 'Nieprawidlowy kod 2FA',
    CHAT_DISABLED: 'Dostep do czatu pomocy technicznej zostal zamkniety',
    WITHDRAWAL_NOT_ALLOWED: 'Dostep do wyplat zostal zamkniety',
    TRADING_DISABLED: 'Trading is disabled for current account',
    PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
    LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
    LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
    LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
    LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
    LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
    LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
    LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
    LOCAL_NO_TRADING_ACCOUNTS:
      'You do not have trading accounts available. Please contact the support',
    LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
    LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
    LOCAL_INVALID_FILE_TYPE:
      'File format not supported. Please attach only JPG, PNG or PDF',
    FLOOD_REJECTED:
      'You are sending messages too often. Please try again later',
    LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',

    countries: {
      AF: 'Afganistan',
      AX: 'Wyspy Alandzkie',
      AL: 'Albania',
      DZ: 'Algieria',
      AS: 'Samoa Amerykanskie',
      AD: 'Andora',
      AO: 'Angola',
      AI: 'Anguilla',
      AQ: 'Antarktyda',
      AG: 'Antigua i Barbuda',
      AR: 'Argentyna',
      AM: 'Armenia',
      AW: 'Aruba',
      AU: 'Australia',
      AT: 'Austria',
      AZ: 'Azerbejdzan',
      BS: 'Bahamy',
      BH: 'Bahrajn',
      BD: 'Bangladesz',
      BB: 'Barbados',
      BY: 'Bialorus',
      BE: 'Belgia',
      BZ: 'Belize',
      BJ: 'Benin',
      BM: 'Bermudy',
      BT: 'Bhutan',
      BO: 'Boliwia',
      BA: 'Bosnia i Hercegowina',
      BW: 'Botswana',
      BV: 'Wyspa Bouveta',
      BR: 'Brazylia',
      IO: 'Brytyjskie Terytorium Oceanu Indyjskiego',
      BN: 'Panstwo Brunei Darussalam',
      BG: 'Bulgaria',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      KH: 'Kambodza',
      CM: 'Kamerun',
      CA: 'Kanada',
      CV: 'Republika Zielonego Przyladka',
      KY: 'Kajmany',
      CF: 'Republika Srodkowoafrykanska',
      TD: 'Chad',
      CL: 'Chile',
      CN: 'Chiny',
      CX: 'Wyspa Bozego Narodzenia',
      CC: 'Wyspy Kokosowe (Keelinga)',
      CO: 'Kolumbia',
      KM: 'Komory',
      CG: 'Kongo',
      CD: 'Kongo, Republika Demokratyczna',
      CK: 'Wyspy Cooka',
      CR: 'Kostaryka',
      CI: 'Wybrzeze Kosci Sloniowej',
      HR: 'Chorwacja',
      CU: 'Kuba',
      CY: 'Cypr',
      CZ: 'Republika Czeska',
      DK: 'Dania',
      DJ: 'Dzibuti',
      DM: 'Dominika',
      DO: 'Republika Dominikanska',
      EC: 'Ekwador',
      EG: 'Egipt',
      SV: 'Salwador',
      GQ: 'Gwinea Równikowa',
      ER: 'Erytrea',
      EE: 'Estonia',
      ET: 'Etiopia',
      FK: 'Wyspy Falklandzkie (Malwiny)',
      FO: 'Wyspy Owcze',
      FJ: 'Fidzi',
      FI: 'Finlandia',
      FR: 'Francja',
      GF: 'Gujana Francuska',
      PF: 'Polinezja Francuska',
      TF: 'Francuskie Terytoria Poludniowe',
      GA: 'Gabon',
      GM: 'Gambia',
      GE: 'Georgia',
      DE: 'Niemcy',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GR: 'Grecja',
      GL: 'Grenlandia',
      GD: 'Grenada',
      GP: 'Gwadelupa',
      GU: 'Guam',
      GT: 'Gwatemala',
      GG: 'Guernsey',
      GN: 'Gwinea',
      GW: 'Gwinea Bissau',
      GY: 'Gujana',
      HT: 'Haiti',
      HM: 'Wyspa Heard i Wyspy Mcdonalda',
      VA: 'Stolica Apostolska (Panstwo Watykanskie)',
      HN: 'Honduras',
      HK: 'Hongkong',
      HU: 'Wegry',
      IS: 'Islandia',
      IN: 'Indie',
      ID: 'Indonezja',
      IR: 'Iran',
      IQ: 'Irak',
      IE: 'Irlandia',
      IM: 'Wyspa Man',
      IL: 'Izrael',
      IT: 'Wlochy',
      JM: 'Jamajka',
      JP: 'Japonia',
      JE: 'Jersey',
      JO: 'Jordan',
      KZ: 'Kazachstan',
      KE: 'Kenia',
      KI: 'Kiribati',
      KR: 'Korea',
      KW: 'Kuwejt',
      KG: 'Kirgistan',
      LA: 'Laotanska Republika Ludowo-Demokratyczna',
      LV: 'Lotwa',
      LB: 'Liban',
      LS: 'Lesotho',
      LR: 'Liberia',
      LY: 'Libijska Arabska Dzamahirija',
      LI: 'Liechtenstein',
      LT: 'Litwa',
      LU: 'Luksemburg',
      MO: 'Macao',
      MK: 'Macedonia',
      MG: 'Madagaskar',
      MW: 'Malawi',
      MY: 'Malezja',
      MV: 'Malediwy',
      ML: 'Mali',
      MT: 'Malta',
      MH: 'Wyspy Marshalla',
      MQ: 'Martynika',
      MR: 'Mauretania',
      MU: 'Mauritius',
      YT: 'Majotta',
      MX: 'Meksyk',
      FM: 'Mikronezja',
      MD: 'Moldawia',
      MC: 'Monako',
      MN: 'Mongolia',
      ME: 'Montenegro',
      MS: 'Montserrat',
      MA: 'Maroko',
      MZ: 'Mozambik',
      MM: 'Myanmar',
      NA: 'Namibia',
      NR: 'Nauru',
      NP: 'Nepal',
      NL: 'Niderlandy',
      AN: 'Antyle Holenderskie',
      NC: 'Nowa Kaledonia',
      NZ: 'Nowa Zelandia',
      NI: 'Nikaragua',
      NE: 'Niger',
      NG: 'Nigeria',
      NU: 'Niue',
      NF: 'Wyspa Norfolk',
      MP: 'Mariany Pólnocne',
      NO: 'Norwegia',
      OM: 'Oman',
      PK: 'Pakistan',
      PW: 'Palau',
      PS: 'Terytorium Palestynskie, Okupowane',
      PA: 'Panama',
      PG: 'Papua Nowa Gwinea',
      PY: 'Paragwaj',
      PE: 'Peru',
      PH: 'Filipiny',
      PN: 'Pitcairn',
      PL: 'Polska',
      PT: 'Portugalia',
      PR: 'Puerto Rico',
      QA: 'Katar',
      RE: 'Zjazd',
      RO: 'Rumunia',
      RU: 'Rosja',
      RW: 'Rwanda',
      BL: 'Swiety Bartymeusz',
      SH: 'Swieta Helena',
      KN: 'Saint Kitts i Nevis',
      LC: 'Saint Lucia (Lucja Pólnocna)',
      MF: 'Swiety Marcin',
      PM: 'Saint Pierre i Miquelon',
      VC: 'Saint Vincent i Grenadyny',
      WS: 'Samoa',
      SM: 'San Marino',
      ST: 'Wyspy Swietego Tomasza i Ksiazeca',
      SA: 'Arabia Saudyjska',
      SN: 'Senegal',
      RS: 'Serbia',
      SC: 'Seszele',
      SL: 'Sierra Leone',
      SG: 'Singapur',
      SK: 'Slowacja',
      SI: 'Slowenia',
      SB: 'Wyspy Salomona',
      SO: 'Somalia',
      ZA: 'Republika Poludniowej Afryki',
      GS: 'Poludniowa Georgia i Sandwich Isl.',
      ES: 'Hiszpania',
      LK: 'Sri Lanka',
      SD: 'Sudan',
      SR: 'Surinam',
      SJ: 'Svalbard i Jan Mayen',
      SZ: 'Suazi',
      SE: 'Szwecja',
      CH: 'Szwajcaria',
      SY: 'Syryjska Republika Arabska',
      TW: 'Taiwan',
      TJ: 'Tadzykistan',
      TZ: 'Tanzania',
      TH: 'Tajlandia',
      TL: 'Timor Wschodni',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trynidad i Tobago',
      TN: 'Tunezja',
      TR: 'Turcja',
      TM: 'Turkmenistan',
      TC: 'Wyspy Turks i Caicos',
      TV: 'Tuvalu',
      UG: 'Uganda',
      UA: 'Ukraina',
      AE: 'Zjednoczone Emiraty Arabskie',
      GB: 'Zjednoczone Królestwo',
      US: 'Stany Zjednoczone',
      UM: 'Wyspy Zewnetrzne Stanów Zjednoczonych',
      UY: 'Urugwaj',
      UZ: 'Uzbekistan',
      VU: 'Vanuatu',
      VE: 'Wenezuela',
      VN: 'Wietnam',
      VG: 'Brytyjskie Wyspy Dziewicze',
      VI: 'Wyspy Dziewicze, U.S.',
      WF: 'Wallis i Futuna',
      EH: 'Sahara Zachodnia',
      YE: 'Jemen',
      ZM: 'Zambia',
      ZW: 'Zimbabwe',
    },
  },
  de: {
    _name: 'Deutsch',

    policy: 'Datenschutzerklärung',
    policyAML: 'AML-Richtlinie (Anti-Geldwäsche)',
    policyKYC: 'KYC-Richtlinie (Kenne deinen Kunden)',
    locense: 'Lizenz',
    view_all: 'Alle ansehen',

    sell_btn: 'Verkaufen',
    buy_btn: 'Kaufen',
    currency_action: 'währung',

    importantAccountDetails: 'Wichtige Kontodetails',
    viewAdditionalSettings: 'Weitere Einstellungen anzeigen',
    switchDarkMode: 'Dunkel-/Hellmodus umschalten',
    writeProblem: 'Schreiben Sie uns Ihr Problem',
    darkMode: 'Dunkelmodus',

    yay: 'Juhuu',
    purchased: 'Sie haben erfolgreich gekauft',
    from: 'von',
    walet: 'Wallet',
    trade: 'Trade',

    withdrawDetails: 'Auszahlungsdetails',
    saveDetails: 'Details für nächstes Mal speichern',
    amountToWithdraw: 'Auszuzahlender Betrag',
    withdrawLimit: 'Tägliches Auszahlungslimit von 1.000.000,00 $ verbleibend.',

    serviceFee: 'Servicegebühr',
    youWillPay: 'Sie zahlen',
    cancel: 'Abbrechen',
    understand: 'Ich verstehe, weiter',
    select: 'Auswählen',
    enterAmount: 'Betrag eingeben',
    orderInfo: 'Bestellinfo',
    confirmOrder: 'Bestellung bestätigen',
    get: 'Erhalten',
    spend: 'Ausgeben',
    buying: 'Kaufen',

    accountTransactions: 'Kontotransaktionen',

    overview: 'Übersicht',
    accountBalances: 'Kontostände',
    details: 'Details',
    loginHistory: 'Anmeldeverlauf',
    device: 'Gerät',
    contactUs: 'Kontaktieren Sie uns',
    deposit: 'Einzahlung ',
    sessionLog: 'Sitzungen und Anmeldeverlauf',
    selectMethods: 'Methoden auswählen',
    depositDetails: 'Einzahlungsdetails',
    support: 'Unterstützung',
    homepage: 'Startseite',
    supportChat: 'Support-Chat',
    createChat: 'Chat erstellen',

    takeProfitStopLoss: 'Gewinnmitnahme & Stop-Loss',
    takeProfit: ' Gewinnmitnahme',
    stopLoss: 'Stop-Loss',
    updateOrder: 'Handel aktualisieren',
    balance: 'Kontostand',
    credit: ' Kredit',
    equity: 'Eigenkapital ',
    practice: 'Praxis',
    real: 'Real',
    account: 'Konto',
    invested: 'Investiert',
    profit: 'Profit',
    loss: 'Verlust',
    margin: 'Margin',
    marginLevel: 'Margin Level',
    marginFree: 'Freie Margin',

    cfd: 'CFD',
    platform: 'Plattform',
    deposit: 'Einzahlung ',

    marketWatch: 'Marktübersicht',
    activeOrders: 'Aktive Handel',
    tradingHistory: 'Handelsgeschichte',
    economicCalendar: 'Wirtschaftskalender',
    marketNews: 'Marktnachrichten',

    closeOrder: 'Handel schließen',
    areYouSure: 'Sind Sie sicher, den Order zu schließen?',
    buy: 'Kaufen',
    sell: 'Verkaufen',
    yes: 'Ja',
    no: 'Nein',

    addNewChart: 'Neue Chart hinzufügen',

    watchlist: 'Überwachungsliste',
    asset: 'Vermögenswert',
    price: 'Preise',
    changePct: '24h Wechsel',

    pending: 'Ausstehend',
    assetPrice: 'Vermögenswertpreis',
    current: 'aktuell',
    revert: 'Zurück zum Marktpreis',
    automatically:
      'Handel wird automatisch eröffnet, wenn der Preis dieses Niveau erreicht',

    default: 'default',
    volume: 'Volumen',
    lots: 'lots',
    units: 'einheiten',
    currency: 'währung',
    contractSize: 'kontraktgröße',
    position: 'Position',
    margin: 'Margin',
    freeMargin: 'Freie Margin',
    takeProfitStopLoss: 'Gewinnmitnahme & Stop-Loss',
    pending: 'Ausstehend',
    market: 'Markt',
    leverage: 'Hebelwirkung',
    spread: 'Spread',
    notSet: 'Nicht eingestellt',
    at: 'um',
    buy: 'Kaufen',
    sell: 'Verkaufen',

    supportBanner: 'JEDEN TAG, RUND UM DIE UHR',
    currentTime: 'AKTUELLE UHRZEIT',
    liveChat: 'Live Chat',

    balance: 'Kontostand',
    credit: 'Kredit',
    equity: 'Eigenkapital',
    margin: 'Margin',
    marginLevel: 'Margin level',
    freeMargin: 'Freie Margin',
    pnl: 'PnL',
    profitTotal: 'Lifetime PnL',

    dateRegistered: 'Registrierungsdatum',
    userId: 'Benutzer ID',
    dashboard: 'Dashboard',
    personalData: 'Persönliche Daten',
    deposit: 'Einzahlung',
    withdrawFunds: 'Auszahlung',
    savings: 'Ersparnisse',
    settings: 'Einstelungen',
    logout: 'Ausloggen',

    BT_INVOICE: 'Enter amount and generate invoice',
    BT_TITLE: 'Our banking details',
    PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
    account: 'Account',
    bank_name: 'Bank Name',
    beneficiary: 'Beneficiary',
    beneficiary_address: 'Beneficiary Address',
    swift: 'Swift',
    iban: 'IBAN',
    ru_inn: 'INN',
    ru_kpp: 'KPP',
    corresponding_account: 'Correspondent account',
    TOKEN_AMOUNT: 'Amount',
    TOKEN_SUBTOTAL: 'Subtotal',
    TOKEN_TOTAL: 'Total',
    TOKEN_DESCRIPTION: 'Description',
    TOKEN_PAYMENT_DETAILS: 'Payment Details',
    makeDeposit: 'Einzahlung tätigen',
    practiceAccount: 'Demokonto',
    realAccount: 'Reales Konto',
    noWithdrawals: 'Keine Auszahlungen',
    easyWithdrawals: 'Einfache Auszahlungen ',
    allAssets: 'Alle Vermögenswerte verfügbar',
    fullFledged: 'Vollgeführte Plattform',
    fillUpTo: 'Auffüllen bis',
    freeReplenishment: 'Kostenloser Nachschub',
    topUp: 'Laden Sie Ihr Konto auf',
    minimumAmount: 'Mindestbetrag',
    canSwitch: 'Sie können jederzeit zwischen Ihren Konten wechseln',

    chartGrids: 'Chartaufstellung',
    chart1: '1 chart',
    chart2: '2 charts',
    chart3: '3 charts',
    chart4: '4 charts',

    connectionLost: 'Die Verbindung mit dem Server wurde unterbrochen',
    retryNow: 'Jetzt erneut versuchen',

    loginToTradeRoom: 'Melden Sie sich bei Handelsroom an',
    email: 'E-Mail-Addresse',
    enterEmail: 'Geben sie ihre E-Mail Adresse ein',
    twoFactor: '2FA-Code (falls aktiviert)',
    twoFactorAuth: 'Zwei-Faktor-Authentifizierung',
    password: 'Passwort',
    yourPassword: 'Ihr Passwort',
    signIn: 'Anmelden',
    dontHaveAccount: 'Don’t have an account?',
    restore: 'Restore',
    fullName: 'Full Name',
    havePromo: 'I have a promo-code',
    promo: 'Promo code',
    login: 'Login',
    haveAccount: 'Already have an account?',
    hide: 'Hide',
    goTo: 'Go to back',

    setNewPassword: 'Neues Passwort festlegen',
    newPassword: 'Neues Passwort',
    confirmNewPassword: 'Bestätige neues Passwort',
    setButton: 'Festsetzen',

    repeatPassword: 'Repeat Password',
    createNewAccount: 'Ein neues Konto erstellen',
    email: 'E-Mail-Addresse',
    firstName: 'Vorname',
    lastName: 'Nachname',
    country: 'Land',
    phone: 'Telefon',
    password: 'Passwort',
    createAccount: 'Benutzerkonto erstellen',
    currency: 'Währung',

    forgotPassword: 'Passwort vergessen?',
    submitEmail:
      'Bitte senden Sie eine für die Registrierung verwendete E-Mail-Adresse, überprüfen Sie Ihren Posteingang und befolgen Sie die bereitgestellten Anweisungen',
    submit: 'Bestätigen',

    error: 'Fehler',
    success: 'Erfolg',
    deposit: 'Einzahlung',
    withdrawal: 'Auszahlung',
    depositApproved: 'Einzahlung wurde genehmigt',
    depositDeclined: 'Einzahlung wurde abgelehnt',
    withdrawalApproved: 'Auszahlung wurde genehmigt',
    withdrawalDeclined: 'Auszahlung wurde abgelehnt',

    forex: 'Devisen',
    stocks: 'Aktien',
    commodities: 'Rohstoffe',
    indices: 'Indizes',
    crypto: 'Krypto',
    metals: 'Metalle',
    nft: 'NFT',

    buy: 'BUY',
    profitCalculator: 'Profit Calculator',
    sell: 'SELL',
    volume: 'Volume',
    entryPrice: 'Entry Price',
    takeProfit: 'Take Profit',
    stopLoss: 'Stop Loss',
    maxPosition: 'Max Position',
    calculate: 'Calculate',
    calculationsFor: 'Calculations for',
    leverage: 'Leverage',
    requiredMargin: 'Required Margin',
    profitFromTP: 'Profit from TP',
    lossFromSL: 'Loss from SL',
    roe: 'ROE',

    title: 'Deposit successful',
    text: 'Your deposit has been processed successfully!',

    title: 'Deposit failed',
    text: 'Your deposit has not been processed.',

    marketWatch: 'Marktübersicht',
    search: 'Suche...',

    commission: 'Commission',
    volume: 'Volumen',
    margin: 'Margin',
    active: 'Aktiv',
    pending: 'Ausstehend',
    activeOrders: 'Aktive Orders',
    portfolio: 'Portfolio',
    allPositions: 'Alle Positionen',
    noPositionsLine1: 'Sie haben noch keine',
    noPositionsLine2: 'geöffneten Positionen',
    show: 'Anzeigen',
    more: 'mehr',
    less: 'weniger',
    purchaseTime: 'Transaktionszeit',
    closePosition: 'Position schließen',
    priceOpen: 'Transaktionspreis',
    priceSl: 'Stop Loss Preis',
    priceTp: 'Take Profit Pries',
    type: 'Positionsrichtung',
    pnl: 'Profit/Verlust',
    cancelOrder: 'Order stornieren',
    order: 'Order',

    orderType: 'Ordertyp',
    cancelled: 'Storniert',
    tradingHistory: 'Handelsgeschichte',
    noHistoryLine1: 'Sie haben noch keine',
    noHistoryLine2: 'geschlossenen Positionen',

    economicCalendar: 'Wirtschaftskalender',

    marketNews: 'Marktnachrichten',

    ordersActive: 'Aktive Orders',
    ordersHistory: 'Handelsgeschichte',
    id: 'ID',
    symbol: 'Symbol',
    type: 'Type',
    volume: 'Volumen',
    openPrice: 'Eröffnungspreis',
    openTime: 'Eröffnungszeit',
    closePrice: 'Schlusskurs',
    closeTime: 'Schließzeit',
    sl: 'SL',
    tp: 'TP',
    price: 'Preis',
    pnl: 'PnL',
    actions: 'Aktionen',
    edit: 'Bearbeiten',
    close: 'Schließen',
    commission: 'Commission',
    swap: 'Swap',
    swapLong: 'Swap Long',
    swapShort: 'Swap Short',

    assetInfo: 'Asset Info',
    tradingConditions: 'Trading Conditions',
    information: 'Session Change',
    bid: 'Bid',
    ask: 'Ask',
    sessionChange: 'Session Change',
    tradeNow: 'Trade Now',
    opens: 'Opens',
    closes: 'Closes',
    at: 'at',
    open247: 'Open 24/7',
    today: 'today',
    tomorrow: 'tomorrow',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    contractSpecification: 'Contract Specification',
    symbol: 'Symbol',
    name: 'Name',
    market: 'Market',
    baseCurrency: 'Base Currency',
    digits: 'Digits',
    lotSize: 'Lot Size',
    lotStep: 'Lot Step',
    minVolume: 'Min Volume',
    maxVolume: 'Max Volume',
    leverage: 'Leverage',
    commission: 'Commission',
    swapLong: 'Swap Long',
    swapShort: 'Swap Short',
    schedule: 'Schedule',
    weekday: 'Weekday',
    tradingTime: 'Trading Time',
    closed: 'closed',
    sell: 'Sell',
    buy: 'Buy',
    low: 'Low',
    high: 'High',
    oneHourChange: '1 hour change',
    oneDayChange: '1 day change',
    oneWeekChange: '1 week change',
    oneMonthChange: '1 month change',
    threeMonthsChange: '3 months change',
    oneYearChange: '1 year change',
    loading: 'Loading...',

    title: 'Auto Trader',
    riskLevelLow: 'Risk Level: Low',
    riskLevelMedium: 'Risk Level: Medium',
    riskLevelHigh: 'Risk Level: High',
    perDay: 'Trades per day:',
    desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
              read the
              relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
              company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
              fully understand the risks and take measures to manage risks.`,
    accept: 'Accept',

    trade: 'Handel',
    startTrading: 'Handel starten',
    deposit: 'Einzahlung tätigen',
    dashboard: 'Dashboard',
    personalInfo: 'Persönliche Daten',
    withdrawal: 'Die Auszahlung',
    verification: 'Identitätsprüfung',
    accounts: 'Konten verwalten',
    liveChat: 'Live Chat',
    savings: 'Ersparnisse',
    settings: 'Plattformeinstellungen',
    logOut: 'Ausloggen',

    methods: 'METHODS',
    confirm: 'Confirm in the application of your bank',
    sendOtp: 'Send otp',
    otpCode: 'Otp code',
    addWalletAddress:
      'Wallet for this deposit method is not set. Please contact your agent or support team.',
    addressReceipt: 'Please wait for the address to load',
    makeDeposit: 'MACHEN SIE EINE EINZAHLUNG',
    instant: 'Sofort',
    minutes: 'Minuten',
    hours: 'Stunden',
    days: 'Tage',
    amount: 'Betrag',
    continue: 'Fortsetzen',
    qrCode: 'QR-CODE',
    depositAddress: 'EINZAHLUNGSADRESSE',
    copy: 'Kopieren',
    last: 'ZULETZT',
    deposits: 'EINZAHLUNGEN',
    time: 'Zeit',
    currency: 'Währung',
    status: 'Status',
    info: 'Info',

    pleaseWait:
      'Warten Sie mal. Verbindung zum Zahlungsdienstleister wird hergestellt...',
    cardPayment: 'Kartenzahlung',
    inOrder: 'Um den Betrag zu senden',
    pleaseProvide: 'Bitte geben Sie die Kartendaten an:',

    completeVerification: 'Please Complete Verification',
    noOrderYet: 'No order yet',
    totalBalance: 'Kontostand',
    totalDeposits: 'Gesamteinlagen',
    totalPnl: 'PnL Insgesamt',
    profitableOrders: 'Erfolgreiche Trades',
    roi: 'ROI',
    activityLog: 'Aktivitätsprotokoll',
    loginFromIp: 'Login from IP',
    tradingResults: 'Handelsergebnisse',
    week: 'Woche',
    month: 'Monat',
    year: 'Jahr',
    successRate: 'Erfolgsquote',
    closedWithProfit: 'Geschlossen mit Gewinn',
    closedWithLoss: 'Geschlossen mit Verlust',
    account: 'Konto',
    balance: 'Saldo',
    leverage: 'Hebelwirkung',
    credit: 'Kredit',
    tradeNow: 'Zum Handel',
    usingCurrentRate: 'zum aktuellen Wechselkurs',

    personalDetails: 'Persönliche Daten',
    profilePhoto: 'Profilfoto',
    firstName: 'Vorname',
    country: 'Land',
    lastName: 'Nachname',
    address: 'Adresse',
    email: 'E-Mail',
    phone: 'Telefonnummer',
    saveChanges: 'Änderungen speichern',

    dropFile: 'Legen Sie eine Datei zum Hochladen auf dem Kreis oben ab',
    notAllowed: 'Es darf nicht veröffentlicht werden',
    notAllowedLine1: 'Fotos explizit sexueller oder pornografischer Natur',
    notAllowedLine2:
      'Bilder, die darauf abzielen, zu ethnischem oder rassistischem Hass oder Aggression aufzustacheln',
    notAllowedLine3: 'Fotos mit Personen unter 18 Jahren',
    notAllowedLine4: 'Fotos mit Third-party Urheberrechte Schutz',
    notAllowedLine5:
      'Bilder größer als 5 MB und in einem anderen Format als JPG, GIF oder PNG',
    requirements:
      'Auf dem Foto muss Ihr Gesicht gut zu erkennen sein. Alle von Ihnen hochgeladenen Fotos und Videos müssen diese Anforderungen erfüllen, andernfalls können sie entfernt werden.',

    cryptocurrency: 'Cryptocurrency',
    withdrawal: 'Withdrawal',
    requestNewWithdrawal: 'NEUE AUSZAHLUNG ANFORDERN',
    amount: 'Betrag',
    currentBalance: 'Ihr aktueller Kontostand ist',
    withdrawAll: 'Alles auszahlen',
    requestWithdrawal: 'Auszahlung anfordern',
    last: 'ZULETZT',
    withdrawalRequests: 'AUSZAHLUNGSANTRÄGE',
    time: 'Zeit',
    currency: 'Währung',
    info: 'Info',
    status: 'Status',
    bank: 'Bank',
    bitcoin: 'Bitcoin',
    card: 'Karte',
    ethereum: 'Ethereum',
    eth: 'Ethereum',
    usdt: 'USDT',
    withdrawalDetails: 'Auszahlungsdetails',
    address: 'Wallet Address',
    bankAccount: 'Kontonummer',
    bankHolder: 'Name des Kontoinhabers',
    bankIban: 'IBAN',
    bankSwift: 'Bank SWIFT code',
    cardNumber: 'Kartennummer',
    cardHolder: 'Name des Karteninhabers',

    dragAndDrop: 'Ziehen Sie das Dokument per Drag & Drop in diesen Bereich',
    documentsList: 'LISTE DER HOCHGELADENEN DOKUMENTE',
    document: 'Dokument',
    timeUploaded: 'Zeit hochgeladen',
    timeProcessed: 'Zeit verarbeitet',
    status: 'Status',
    types: [
      'Ausweis',
      'Nachweis des Wohnsitzes',
      'Kreditkartenvorderseite',
      'Kreditkartenrückseite',
      'Ausweisrückseite',
      'Selfie',
    ],
    types2: [
      'Proof of identification (POI)',
      'Proof of residential address',
      'Credit Card Front',
      'Credit Card Back',
      'Proof of identification Back',
      'Selfie with ID',
    ],
    upload: 'Hochladen',
    isConfirmed: 'bestätigt',
    uploadAnotherDocument: 'Anderes Dokument hochladen',
    isUnderReview: 'wird überprüft',
    questionnaire: 'Fragebogen',
    sendAnswers: 'Antworten senden',
    progressBarOf: 'von',
    progressBarText: 'Ihrer Dokumente sind hochgeladen und geprüft',

    manageAccounts: 'Überweisen Sie Gelder zwischen Ihren Konten',
    transferFrom: 'Vom Konto',
    transferTo: 'Auf Konto ',
    amount: 'Betrag',
    makeTransfer: 'Überweisung vornehmen',
    yourAccounts: 'Ihre Konten',
    account: 'Konto',
    currency: 'Währung',
    balance: 'Kontostand',
    credit: 'Kredit',
    makeActive: 'Aktivieren',
    active: 'Aktiv',

    sendMessage: 'Nachricht zu senden',

    pct_0: 'Flexibel',
    pct_30: '1 Monat gesperrt',
    pct_90: '1 Quartal gesperrt',
    pct_180: '6 Monate gesperrt',
    pct_365: '1 Jahr gesperrt',
    savings: 'Ersparnisse',
    detailedInformation: 'Genaue Information',
    pleaseSelect: 'Bitte wählen Sie eine Währung und einen Zeitraum',
    openSavings: 'Ersparnisse öffnen',
    currentTime: 'Aktuelle Uhrzeit',
    releaseTime: 'Eröffnungsdatum',
    currencyChosen: 'Gewählte Währung',
    periodChosen: 'Gewählter Zeitraum',
    yourRate: 'Ihr Tarif',
    yourEarnings: 'Dein Verdienst',
    accountFrom: 'Konto, von dem Geld überwiesen werden soll',
    enterAmount: 'Enter Amount',
    in: 'In',
    openAndInvest: 'Sparkonto eröffnen und anlegen',
    investment: 'Investition',
    period: 'Zeitraum',
    daysTotal: 'Tage insgesamt',
    finalEarnings: 'Totales Einkommen',
    created: 'Erstellt',
    daysElapsed: 'Verstrichene Tage',
    days: 'Tage',
    earnings: 'Verdienste',
    fundsReleasedIn: 'Freigegebene Mittel in',
    claim: 'Versicherungsanspruch ',

    updatePassword: 'Passwort AKTUALISIEREN',
    currentPassword: 'Aktuelles Passwort',
    newPassword: 'Neues Passwort',
    changePassword: 'Passwort ändern',
    activationForGoogle: 'Aktivierungscode für Google Authenticator',
    activateAndEnter:
      'Aktivieren Sie Authenticator und geben Sie einen generierten Code in das Feld unten ein',
    qrCode: 'QR-Code',
    activateProtection: 'Aktivieren Sie den 2FA-Schutz',
    protectionActive: '2FA-Schutz ist aktiv',
    twoFactorCode: '2FA-Code',
    disableProtection: 'Deaktivieren Sie den 2FA-Schutz',
    language: 'Sprache',
    dashboardCurrency: 'Dashboard-Währung',
    confirmNewPassword: 'Bestätige neues Passwort',

    yourLink: 'IHR EMPFEHLUNGSLINK',
    referredFriends: 'EMPFOHLENE FREUNDE',
    id: 'ID',
    name: 'Name',
    registered: 'Registriert',
    level: 'Niveua',
    payout: 'Gewinnauszahlung',
    lastPayouts: 'LETZTE EMPFOHLENE Gewinnauszahlung',
    time: 'Zeit',
    amount: 'Betrag',
    currency: 'Währung',
    info: 'Info',
    months: {
      Jan: 'Jan',
      Feb: 'Feb',
      Mar: 'Mar',
      Apr: 'Apr',
      May: 'May',
      Jun: 'Jun',
      Jul: 'Jul',
      Aug: 'Aug',
      Sep: 'Sep',
      Oct: 'Oct',
      Nov: 'Nov',
      Dec: 'Dec',
    },
    orderTypes: [
      'Kaufen',
      'Verkaufen',
      'Kaufen Limit',
      'Verkaufen Limit',
      'Kaufen Stop',
      'Verkaufen Stop',
    ],
    statuses: ['Ausstehend', 'Genehmigt', 'Abgelehnt', 'Wird bearbeitet'],

    NOT_FOUND: 'Anforderungsfehler',
    FORBIDDEN: 'Zugriff abgelehnt. Bitte neu einloggen',
    SERVER_ERROR:
      'Aktion: fehlgeschlagen. Bitte versuchen Sie es später noch einmal.',
    INVALID_ACCOUNT: 'Sitzung abgelaufen. Bitte neu einloggen',
    INVALID_EMAIL: 'Ungültige E-Mail-Adresse',
    INVALID_PHONE: 'Ungültige Telefonnummer',
    INVALID_COUNTRY: 'Ungültiges Land',
    INVALID_LOGIN: 'Ungültige Login-Details',
    USER_BLOCKED: 'Konto ist gesperrt. Bitte wenden Sie sich an den Support.',
    USER_REGISTERED:
      'Benutzer mit eingegebener E-Mail oder Telefonnummer ist bereits registriert',
    INVALID_USER: 'Benutzer wurde nicht gefunden',
    REJECTED:
      'Der Betrieb ist aufgrund von Plattformbeschränkungen nicht erlaubt',
    INVALID_OLD_PASSWORD: 'Altes Passwort ist ungültig',
    INVALID_SYMBOL: 'Ungültiges Handelssymbol',
    INVALID_SYMBOL_PRECISION: 'Ungültige Symbolpräzision',
    INVALID_FRAME: 'Ungültiger Diagrammrahmen',
    INVALID_CURRENCY: 'Ungültige Währung',
    INVALID_PRODUCT: 'Ungültiger Typ des Handelskontos',
    INSUFFICIENT_FUNDS: 'Nicht genügend Geld auf dem Konto',
    INVALID_WITHDRAWAL: 'Auszahlung nicht gefunden',
    INVALID_STATUS: 'Ungültiger Status',
    INVALID_FILE: 'Ungültige Dateierweiterung oder Datei ist zu groß',
    INVALID_DOCUMENT: 'Dokument wurde nicht gefunden',
    INVALID_NAME: 'Ungültiger Name',
    INVALID_LOT_SIZE: 'Ungültige Lotgröße',
    INVALID_LOT_STEP: 'Ungültiger Lotschritt',
    INVALID_MIN_VOLUME: 'Ungültiges Mindestvolumen',
    INVALID_MAX_VOLUME: 'Ungültiges maximales Volumen',
    INVALID_GROUP: 'Ungültige Gruppe',
    INVALID_SPREAD: 'Ungültiger Spread',
    REQUEST_TIMEOUT:
      'Zeitüberschreitung der Anforderung. Bitte versuchen Sie es später noch einmal',
    INVALID_TYPE: 'Ungültiger Auftragstyp',
    INVALID_VOLUME: 'Ungültiges Auftragsvolumen',
    INVALID_SL: 'Ungültiger Stop-Verlust-Wert',
    INVALID_TP: 'Ungültiger Gewinnmitnahme-Wert',
    INVALID_PRICE: 'Ungültiger Auftragspreis',
    INVALID_EXPIRATION: 'Ungültiger Auftragsablauf',
    NOT_ENOUGH_MARGIN: 'Nicht genug Margin',
    INVALID_ORDER: 'Auftrag nicht gefunden',
    MARKET_CLOSED: 'Handel ist nicht verfügbar. Bitte versuche es erneut',
    INVALID_BALANCE_TYPE: 'Ungültiger Typ der Kontostandoperation',
    INVALID_HASH: 'Ungültiger Hash',
    HASH_EXPIRED:
      'Ihr Link zum Zurücksetzen des Passworts ist abgelaufen. Bitte fordern Sie eine neue an',
    INVALID_CODE: 'Ungültiger 2FA-Code',
    CHAT_DISABLED: 'Zugriff auf den Support-Chat wurde geschlossen',
    WITHDRAWAL_NOT_ALLOWED: 'Zugriff auf die Auszahlungen wurde gesperrt',
    TRADING_DISABLED: 'Trading is disabled for current account',
    PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
    LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
    LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
    LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
    LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
    LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
    LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
    LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
    LOCAL_NO_TRADING_ACCOUNTS:
      'You do not have trading accounts available. Please contact the support',
    LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
    LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
    LOCAL_INVALID_FILE_TYPE:
      'File format not supported. Please attach only JPG, PNG or PDF',
    LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
    FLOOD_REJECTED:
      'You are sending messages too often. Please try again later',

    countries: {
      AF: 'Afghanistan',
      AX: 'Aland-Inseln',
      AL: 'Albanien',
      DZ: 'Algerien',
      AS: 'Amerikanisch-Samoa',
      AD: 'Andorra',
      AO: 'Angola',
      AI: 'Anguilla',
      AQ: 'Antarktis',
      AG: 'Antigua und Barbuda',
      AR: 'Argentinien',
      AM: 'Armenien',
      AW: 'Aruba',
      AU: 'Australien',
      AT: 'Österreich',
      AZ: 'Aserbaidschan',
      BS: 'Bahamas',
      BH: 'Bahrain',
      BD: 'Bangladesch',
      BB: 'Barbados',
      BY: 'Weißrussland',
      BE: 'Belgien',
      BZ: 'Belize',
      BJ: 'Benin',
      BM: 'Bermuda',
      BT: 'Bhutan',
      BO: 'Bolivien',
      BA: 'Bosnien und Herzegowina',
      BW: 'Botswana',
      BV: 'Bouvetinsel',
      BR: 'Brasilien',
      IO: 'Britisches Territorium im Indischen Ozean',
      BN: 'Brunei Darussalam',
      BG: 'Bulgarien',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      KH: 'Kambodscha',
      CM: 'Kamerun',
      CA: 'Kanada',
      CV: 'Kap Verde',
      KY: 'Kaimaninseln',
      CF: 'Zentralafrikanische Republik',
      TD: 'Chad',
      CL: 'Chile',
      CN: 'China',
      CX: 'Weihnachtsinsel',
      CC: 'Cocos (Keeling) Inseln',
      CO: 'Kolumbien',
      KM: 'Komoren',
      CG: 'Kongo',
      CD: 'Kongo, Demokratische Republik',
      CK: 'Cookinseln',
      CR: 'Costa Rica',
      CI: "Cote D'Ivoire",
      HR: 'Kroatien',
      CU: 'Kuba',
      CY: 'Zypern',
      CZ: 'Tschechische Republik',
      DK: 'Dänemark',
      DJ: 'Dschibuti',
      DM: 'Dominica',
      DO: 'Dominikanische Republik',
      EC: 'Ecuador',
      EG: 'Ägypten',
      SV: 'El Salvador',
      GQ: 'Äquatorialguinea',
      ER: 'Eritrea',
      EE: 'Estland',
      ET: 'Äthiopien',
      FK: 'Falklandinseln (Malwinen)',
      FO: 'Färöer Inseln',
      FJ: 'Fidschi',
      FI: 'Finnland',
      FR: 'Frankreich',
      GF: 'Französisch-Guayana',
      PF: 'Französisch-Polynesien',
      TF: 'Französische Süd-Territorien',
      GA: 'Gabun',
      GM: 'Gambia',
      GE: 'Georgien',
      DE: 'Deutschland',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GR: 'Griechenland',
      GL: 'Grönland',
      GD: 'Grenada',
      GP: 'Guadeloupe',
      GU: 'Guam',
      GT: 'Guatemala',
      GG: 'Guernsey',
      GN: 'Guinea',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HT: 'Haiti',
      HM: 'Heard-Insel & Mcdonald-Inseln',
      VA: 'Heiliger Stuhl (Staat Vatikanstadt)',
      HN: 'Honduras',
      HK: 'Hongkong',
      HU: 'Ungarn',
      IS: 'Island',
      IN: 'Indien',
      ID: 'Indonesien',
      IR: 'Iran',
      IQ: 'Irak',
      IE: 'Irland',
      IM: 'Isle Of Man',
      IL: 'Israel',
      IT: 'Italien',
      JM: 'Jamaika',
      JP: 'Japan',
      JE: 'Jersey',
      JO: 'Jordanien',
      KZ: 'Kasachstan',
      KE: 'Kenia',
      KI: 'Kiribati',
      KR: 'Korea',
      KW: 'Kuwait',
      KG: 'Kirgisistan',
      LA: 'Demokratische Volksrepublik Laos',
      LV: 'Lettland',
      LB: 'Libanon',
      LS: 'Lesotho',
      LR: 'Liberia',
      LY: 'Libysch-Arabische Dschamahirija',
      LI: 'Liechtenstein',
      LT: 'Litauen',
      LU: 'Luxemburg',
      MO: 'Macao',
      MK: 'Mazedonien',
      MG: 'Madagaskar',
      MW: 'Malawi',
      MY: 'Malaysia',
      MV: 'Malediven',
      ML: 'Mali',
      MT: 'Malta',
      MH: 'Marshallinseln',
      MQ: 'Martinique',
      MR: 'Mauretanien',
      MU: 'Mauritius',
      YT: 'Mayotte',
      MX: 'Mexiko',
      FM: 'Mikronesien',
      MD: 'Moldawien',
      MC: 'Monaco',
      MN: 'Mongolei',
      ME: 'Montenegro',
      MS: 'Montserrat',
      MA: 'Marokko',
      MZ: 'Mosambik',
      MM: 'Myanmar',
      NA: 'Namibia',
      NR: 'Nauru',
      NP: 'Nepal',
      NL: 'Niederlande',
      AN: 'Niederländische Antillen',
      NC: 'Neukaledonien',
      NZ: 'Neuseeland',
      NI: 'Nicaragua',
      NE: 'Niger',
      NG: 'Nigeria',
      NU: 'Niue',
      NF: 'Norfolkinsel',
      MP: 'Nördliche Marianen',
      NO: 'Norwegen',
      OM: 'Oman',
      PK: 'Pakistan',
      PW: 'Palau',
      PS: 'Palästinensische Gebiete, Besetzt',
      PA: 'Panama',
      PG: 'Papua-Neuguinea',
      PY: 'Paraguay',
      PE: 'Peru',
      PH: 'Philippinen',
      PN: 'Pitcairn',
      PL: 'Polen',
      PT: 'Portugal',
      PR: 'Puerto Rico',
      QA: 'Katar',
      RE: 'Wiedersehen',
      RO: 'Rumänien',
      RU: 'Russland',
      RW: 'Ruanda',
      BL: 'St. Barthelemy',
      SH: 'Sankt Helena',
      KN: 'St. Kitts und Nevis',
      LC: 'St. Lucia',
      MF: 'Sankt Martin',
      PM: 'St. Pierre und Miquelon',
      VC: 'St. Vincent und Grenadinen',
      WS: 'Samoa',
      SM: 'San Marino',
      ST: 'Sao Tome und Principe',
      SA: 'Saudi-Arabien',
      SN: 'Senegal',
      RS: 'Serbien',
      SC: 'Seychellen',
      SL: 'Sierra Leone',
      SG: 'Singapur',
      SK: 'Slowakei',
      SI: 'Slowenien',
      SB: 'Salomoninseln',
      SO: 'Somalia',
      ZA: 'Südafrika',
      GS: 'Südgeorgien und Sandwichinseln',
      ES: 'Spanien',
      LK: 'Sri Lanka',
      SD: 'Sudan',
      SR: 'Surinam',
      SJ: 'Svalbard und Jan Mayen',
      SZ: 'Swasiland',
      SE: 'Schweden',
      CH: 'Schweiz',
      SY: 'Arabische Republik Syrien',
      TW: 'Taiwan',
      TJ: 'Tadschikistan',
      TZ: 'Tansania',
      TH: 'Thailand',
      TL: 'Timor-Leste',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinidad und Tobago',
      TN: 'Tunesien',
      TR: 'Türkei',
      TM: 'Turkmenistan',
      TC: 'Turks- und Caicosinseln',
      TV: 'Tuvalu',
      UG: 'Uganda',
      UA: 'Ukraine',
      AE: 'Vereinigte Arabische Emirate',
      GB: 'Vereinigtes Königreich',
      US: 'Vereinigte Staaten',
      UM: 'Überseeische Inseln der Vereinigten Staaten',
      UY: 'Uruguay',
      UZ: 'Usbekistan',
      VU: 'Vanuatu',
      VE: 'Venezuela',
      VN: 'Vietnam',
      VG: 'Jungferninseln, Britisch',
      VI: 'Jungferninseln, U.S.A.',
      WF: 'Wallis und Futuna',
      EH: 'Westsahara',
      YE: 'Jemen',
      ZM: 'Sambia',
      ZW: 'Simbabwe',
    },
  },
  fr: {
    _name: 'Française',

    policy: 'Politique de confidentialité',
    policyAML: `Politique AML (Lutte contre le blanchiment d'argent)`,
    policyKYC: 'Politique KYC (Connaître son client)',
    locense: 'Licence',

    view_all: 'Voir tout',

    sell_btn: 'Vendre',
    buy_btn: 'Acheter',

    currency_action: 'devise',

    importantAccountDetails: 'Informations importantes sur le compte',
    viewAdditionalSettings: 'Afficher les paramètres supplémentaires',
    switchDarkMode: 'Basculer entre le mode sombre et clair',
    writeProblem: 'Écrivez-nous votre problème',
    darkMode: 'Mode sombre',

    yay: 'Yay',
    purchased: 'Vous avez effectué avec succès',
    from: 'un achat sur',
    walet: 'Wallet',
    trade: 'Trade',

    withdrawDetails: 'Détails du retrait',
    saveDetails: 'Enregistrer les détails pour la prochaine fois',
    amountToWithdraw: 'Montant à retirer',
    withdrawLimit: 'Limite de retrait quotidienne restante de 1 000 000,00 $.',
    serviceFee: 'Frais de service',
    youWillPay: 'Vous allez payer',
    cancel: 'Annuler',
    understand: `J'ai compris, continuer`,

    select: 'Sélectionner',
    enterAmount: 'Entrer le montant',
    orderInfo: 'Informations sur la commande',
    confirmOrder: 'Confirmer la commande',
    get: 'Obtenir',
    spend: 'Dépenser',
    buying: 'Achat',

    accountTransactions: 'Transactions sur compte',

    overview: 'Présentation',
    accountBalances: 'Soldes des comptes',
    details: 'Détails',
    loginHistory: 'Historique de connexion',
    device: 'Appareil',
    contactUs: 'Nous contacter',
    sessionLog: 'Sessions et historique de connexion',
    selectMethods: 'Sélectionner les méthodes',
    depositDetails: 'Détails du dépôt',
    support: 'Soutien',
    homepage: `Page d'accueil`,
    supportChat: `Chat d'assistance`,
    createChat: 'Créer un chat',

    takeProfitStopLoss: 'Take Profit & Stop Loss',
    takeProfit: 'Take Profit',
    stopLoss: 'Stop Loss',
    updateOrder: 'Ordre de mise à jour',

    balance: 'Balance',
    credit: 'Crédit',
    equity: 'Equity',
    practice: 'PRACTICE',
    real: 'REAL',
    account: 'ACCOUNT',
    invested: 'Investi',
    profit: 'Profit',
    loss: 'Perte',
    margin: 'Marge',
    marginLevel: 'Marge Niveau',
    marginFree: 'Marge libre',

    cfd: 'CFD',
    platform: 'Plateforme',
    deposit: 'Dépôt',

    marketWatch: 'Surveillance du marché',
    activeOrders: 'Commandes actives',
    tradingHistory: 'Historique des transactions',
    economicCalendar: 'Calendrier économique',
    marketNews: 'Nouvelles du marché',

    closeOrder: 'Position fermée',
    areYouSure: 'Etes-vous sûr de fermer la position',
    buy: 'Acheter',
    sell: 'Vendez',
    yes: 'Oui',
    no: 'Non',

    addNewChart: 'Ajouter un nouveau graphique',

    watchlist: 'Liste de surveillance',
    asset: 'Actif',
    price: 'Prix',
    changePct: 'Changement 24h',

    pending: 'En attente',
    assetPrice: "Prix de l'actif",
    current: 'Actuel',
    revert: 'Retour au prix du marché',
    automatically:
      'La position sera ouverte automatiquement lorsque le prix atteindra ce niveau',

    default: 'default',
    volume: 'Volume',
    lots: 'lots',
    units: 'unités',
    currency: 'devise',
    contractSize: 'Taille du contrat',
    position: 'Position',
    margin: 'Marge',
    freeMargin: 'Marge libre',
    takeProfitStopLoss: 'Take Profit & Stop Loss',
    pending: 'En attente',
    market: 'Marché',
    leverage: 'Effet de levier',
    spread: 'Écartement',
    notSet: 'Non défini',
    at: "à l'adresse",
    buy: 'acheter',
    sell: 'vendre',

    supportBanner: 'TOUS LES JOURS, 24 HEURES SUR 24',
    currentTime: 'HEURE ACTUELLE',
    liveChat: 'Chat en direct',

    balance: 'Balance',
    credit: 'Crédit',
    equity: 'Equity',
    margin: 'Marge',
    marginLevel: 'Niveau de marge',
    freeMargin: 'Marge libre',
    pnl: 'PnL',
    profitTotal: 'Lifetime PnL',

    dateRegistered: "Date d'enregistrement",
    userId: 'ID utilisateur',
    dashboard: 'Dashboard',
    personalData: 'Données personnelles',
    deposit: 'Dépôt',
    withdrawFunds: 'Retirer des fonds',
    savings: 'Épargne',
    settings: 'Paramètres',
    logout: 'Déconnexion',

    BT_INVOICE: 'Enter amount and generate invoice',
    BT_TITLE: 'Our banking details',
    PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
    account: 'Account',
    bank_name: 'Bank Name',
    beneficiary: 'Beneficiary',
    beneficiary_address: 'Beneficiary Address',
    swift: 'Swift',
    iban: 'IBAN',
    ru_inn: 'INN',
    ru_kpp: 'KPP',
    corresponding_account: 'Correspondent account',
    TOKEN_AMOUNT: 'Amount',
    TOKEN_SUBTOTAL: 'Subtotal',
    TOKEN_TOTAL: 'Total',
    TOKEN_DESCRIPTION: 'Description',
    TOKEN_PAYMENT_DETAILS: 'Payment Details',
    makeDeposit: 'Effectuer un dépôt',
    practiceAccount: 'Effectuer un dépôt',
    realAccount: 'Compte réel',
    noWithdrawals: 'Pas de retraits',
    easyWithdrawals: 'Retraits faciles',
    allAssets: 'Tous les actifs disponibles',
    fullFledged: 'Une plateforme complète',
    fillUpTo: "Remplir jusqu'à",
    freeReplenishment: 'Réapprovisionnement gratuit',
    topUp: 'Rechargez votre compte',
    minimumAmount: 'Montant minimum',
    canSwitch: "Vous pouvez passer d'un compte à l'autre à tout moment",

    chartGrids: 'GRILLES DE CARTES',
    chart1: '1 graphique',
    chart2: '2 graphiques',
    chart3: '3 graphiques',
    chart4: '4 graphiques',

    connectionLost: 'La connexion avec le serveur est perdue',
    retryNow: 'Réessayer maintenant',

    loginToTradeRoom: 'Se connecter à Traderoom',
    email: 'Adresse électronique',
    enterEmail: 'Entrez votre courriel',
    twoFactor: 'Code 2FA (si activé)',
    twoFactorAuth: 'Authentification à deux facteurs',
    password: 'Mot de passe',
    yourPassword: 'Votre mot de passe',
    signIn: "S'inscrire",
    dontHaveAccount: "Vous n'avez pas de compte?",
    restore: 'Restaurer',
    fullName: 'Nom complet',
    havePromo: "J'ai un code promo",
    promo: 'Code promo',
    login: 'Connexion',
    haveAccount: 'Vous avez déjà un compte?',
    hide: 'Cacher',
    goTo: 'Retourner au début',

    setNewPassword: 'Définir un nouveau mot de passe',
    newPassword: 'Nouveau mot de passe',
    confirmNewPassword: 'Confirmer le nouveau mot de passe',
    setButton: 'Définir',

    repeatPassword: 'Repeat Password',
    createNewAccount: 'Créer un nouveau compte',
    email: 'Adresse électronique',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    country: 'Pays',
    phone: 'Téléphone',
    password: 'Mot de passe',
    createAccount: 'Créer un compte',
    currency: 'Monnaie',

    forgotPassword: 'Mot de passe oublié?',
    submitEmail:
      "Veuillez soumettre un courriel utilisé pour l'inscription, vérifiez votre boîte de réception et suivez les instructions fournies.",
    submit: 'Soumettre',

    error: 'Erreur',
    success: 'Succès',
    deposit: 'Dépôt',
    withdrawal: 'Retrait',
    depositApproved: 'Le dépôt a été approuvé',
    depositDeclined: 'Le dépôt a été refusé',
    withdrawalApproved: 'Le retrait a été approuvé',
    withdrawalDeclined: 'Le retrait a été refusé',

    forex: 'Forex',
    stocks: 'Stocks',
    commodities: 'Produits de base',
    indices: 'Indices',
    crypto: 'Crypto',
    metals: 'Métaux',
    nft: 'NFT',

    buy: 'BUY',
    profitCalculator: 'Profit Calculator',
    sell: 'SELL',
    volume: 'Volume',
    entryPrice: 'Entry Price',
    takeProfit: 'Take Profit',
    stopLoss: 'Stop Loss',
    maxPosition: 'Max Position',
    calculate: 'Calculate',
    calculationsFor: 'Calculations for',
    leverage: 'Leverage',
    requiredMargin: 'Required Margin',
    profitFromTP: 'Profit from TP',
    lossFromSL: 'Loss from SL',
    roe: 'ROE',

    title: 'Dépôt réussi',
    text: 'Votre dépôt a été traité avec succès !',

    title: 'Dépôt échoué',
    text: "Votre dépôt n'a pas été traité.",

    marketWatch: 'Surveillance du marché',
    search: 'Recherche...',

    commission: 'Commission',
    volume: 'Volume',
    margin: 'Marge',
    active: 'Actif',
    pending: 'En attente',
    activeOrders: 'Commandes actives',
    portfolio: 'Portefeuille',
    allPositions: 'Tous les postes',
    noPositionsLine1: "Vous n'avez pas d'ouverture",
    noPositionsLine2: 'postes encore',
    show: 'Afficher',
    more: 'plus',
    less: 'moins',
    purchaseTime: "Temps d'achat",
    closePosition: 'Position fermée',
    priceOpen: "Prix d'achat",
    priceSl: 'Prix du Stop Loss',
    priceTp: 'Prix du Take Profit',
    type: 'Envoyer les réponses',
    pnl: 'Bénéfice/perte',
    cancelOrder: 'Annuler la commande',
    order: 'Commande',
    orderType: 'Type de commande',
    cancelled: 'Annulé',
    tradingHistory: 'Historique des transactions',
    noHistoryLine1: "Vous n'avez pas de",
    noHistoryLine2: 'des affaires conclues pour le moment',

    economicCalendar: 'Calendrier économique',

    marketNews: 'Nouvelles du marché',

    ordersActive: 'Commandes actives',
    ordersHistory: 'Historique des commandes',
    id: 'ID',
    symbol: 'Symbole',
    type: 'Type',
    volume: 'Volume',
    openPrice: 'Prix ouvert',
    openTime: 'Temps ouvert',
    closePrice: 'Fermer le prix',
    closeTime: 'Heure de fermeture',
    sl: 'SL',
    tp: 'TP',
    price: 'Prix',
    pnl: 'PnL',
    actions: 'Actions',
    edit: 'Modifier',
    close: 'Fermer',
    commission: 'Commission',
    swap: 'Swap',
    swapLong: 'Swap Long',
    swapShort: 'Swap Short',

    assetInfo: 'Asset Info',
    tradingConditions: 'Trading Conditions',
    information: 'Session Change',
    bid: 'Bid',
    ask: 'Ask',
    sessionChange: 'Session Change',
    tradeNow: 'Trade Now',
    opens: 'Opens',
    closes: 'Closes',
    at: 'at',
    open247: 'Open 24/7',
    today: 'today',
    tomorrow: 'tomorrow',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    contractSpecification: 'Contract Specification',
    symbol: 'Symbol',
    name: 'Name',
    market: 'Market',
    baseCurrency: 'Base Currency',
    digits: 'Digits',
    lotSize: 'Lot Size',
    lotStep: 'Lot Step',
    minVolume: 'Min Volume',
    maxVolume: 'Max Volume',
    leverage: 'Leverage',
    commission: 'Commission',
    swapLong: 'Swap Long',
    swapShort: 'Swap Short',
    schedule: 'Schedule',
    weekday: 'Weekday',
    tradingTime: 'Trading Time',
    closed: 'closed',
    sell: 'Sell',
    buy: 'Buy',
    low: 'Low',
    high: 'High',
    oneHourChange: '1 hour change',
    oneDayChange: '1 day change',
    oneWeekChange: '1 week change',
    oneMonthChange: '1 month change',
    threeMonthsChange: '3 months change',
    oneYearChange: '1 year change',
    loading: 'Loading...',

    title: 'Auto Trader',
    riskLevelLow: 'Risk Level: Low',
    riskLevelMedium: 'Risk Level: Medium',
    riskLevelHigh: 'Risk Level: High',
    perDay: 'Trades per day:',
    desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
              read the
              relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
              company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
              fully understand the risks and take measures to manage risks.`,
    accept: 'Accept',

    trade: 'Commerce',
    startTrading: 'Commencer à trader',
    deposit: 'Effectuer un dépôt',
    dashboard: 'Dashboard',
    personalInfo: 'Mise à jour des données personnelles',
    withdrawal: 'Demander un retrait',
    verification: "Vérification de l'identité",
    accounts: 'Gérer les comptes',
    liveChat: 'Chat en direct',
    savings: 'Épargne',
    settings: 'Paramètres de la plate-forme',
    logOut: 'Déconnexion',

    methods: 'METHODS',
    confirm: 'Confirm in the application of your bank',
    sendOtp: 'Send otp',
    otpCode: 'Otp code',
    addWalletAddress:
      'Wallet for this deposit method is not set. Please contact your agent or support team.',
    addressReceipt: 'Please wait for the address to load',
    makeDeposit: 'FAIRE UN DÉPÔT',
    instant: 'Instantané',
    minutes: 'minutes',
    hours: 'heures',
    days: 'jours',
    amount: 'Montant',
    continue: 'Continuer',
    qrCode: 'QR CODE',
    depositAddress: 'ADRESSE DE DÉPÔT',
    copy: 'Copie',
    last: 'DERNIER',
    deposits: 'DÉPÔTS',
    time: 'Temps',
    currency: 'Monnaie',
    status: 'Statut',
    info: 'Info',

    pleaseWait:
      'Veuillez patienter. Connexion au fournisseur de services de paiement...',
    cardPayment: 'Paiement par carte',
    inOrder: "Afin d'envoyer le montant",
    pleaseProvide: 'veuillez fournir les détails de la carte :',

    completeVerification: 'Please Complete Verification',
    noOrderYet: 'No order yet',
    totalBalance: 'Solde Total',
    totalDeposits: 'Total des dépôts',
    totalPnl: 'PnL Total',
    profitableOrders: 'Commandes Rentables',
    roi: 'ROI',
    activityLog: "Journal d'activités",
    loginFromIp: 'Login from IP',
    tradingResults: 'Résultats de Trading',
    week: 'Semaine',
    month: 'Mois',
    year: 'Année',
    successRate: 'Taux de Réussite',
    closedWithProfit: 'Clôturé avec un Bénéfice',
    closedWithLoss: 'Clôturé avec une Perte',
    account: 'Compte',
    balance: 'Solde',
    leverage: 'Levier',
    credit: 'Crédit',
    tradeNow: 'Trading Maintenant',
    usingCurrentRate: 'using current exchange rate',

    personalDetails: 'Détails personnels',
    profilePhoto: 'Photo de profil',
    firstName: 'Prénom',
    country: 'Pays',
    lastName: 'Nom de famille',
    address: 'Adresse',
    email: 'Courriel',
    phone: 'Numéro de téléphone',
    saveChanges: 'Sauvegarder les changements',

    dropFile: 'Déposez un fichier sur le cercle ci-dessus pour le télécharger',
    notAllowed: "Il n'est pas permis de publier",
    notAllowedLine1:
      'Photos à caractère explicitement sexuel ou pornographique',
    notAllowedLine2:
      "Images visant à inciter à la haine ou à l'agression ethnique ou raciale",
    notAllowedLine3: 'Photos impliquant des personnes de moins de 18 ans',
    notAllowedLine4: "Photos protégées par le droit d'auteur de tiers",
    notAllowedLine5:
      'Images de plus de 5 MB et dans un format autre que JPG, GIF ou PNG',
    requirements:
      'Votre visage doit être clairement visible sur la photo. Toutes les photos et vidéos que vous mettez en ligne doivent respecter ces exigences, faute de quoi elles peuvent être supprimées.',

    withdrawalOff: 'Please contact your account manager for withdrawal details',
    cryptocurrency: 'Cryptocurrency',
    withdrawal: 'Withdrawal',
    requestNewWithdrawal: 'DEMANDER UN NOUVEAU RETRAIT',
    amount: 'Montant',
    currentBalance: 'Le solde de votre compte courant est de',
    withdrawAll: 'Retirer tout',
    requestWithdrawal: 'Demande de retrait',
    last: 'DERNIER',
    withdrawalRequests: 'DEMANDES DE RETRAIT',
    time: 'Temps',
    currency: 'Monnaie',
    info: 'Info',
    status: 'Statut',
    bank: 'Banque',
    bitcoin: 'Bitcoin',
    card: 'Carte',
    ethereum: 'Ethereum',
    eth: 'Ethereum',
    usdt: 'USDT',
    withdrawalDetails: 'Détails du retrait',
    address: 'Adresse du portefeuille',
    bankAccount: 'Numéro de compte',
    bankHolder: 'Nom du titulaire du compte',
    bankIban: 'IBAN',
    bankSwift: 'Code SWIFT de la banque',
    cardNumber: 'Numéro de la carte',
    cardHolder: 'Nom du titulaire de la carte',

    dragAndDrop: 'glisser et déposer le document dans cette zone',
    documentsList: 'LISTE DES DOCUMENTS TÉLÉCHARGÉS',
    document: 'Document',
    timeUploaded: 'Temps téléchargé',
    timeProcessed: 'Temps traité',
    status: 'Statut',
    types: [
      "Preuve d'identité",
      'Preuve de résidence',
      'Recto de la carte de crédit',
      'Retour de la carte de crédit',
      "Preuve d'identité en arrière",
      'Selfie',
    ],
    types2: [
      'Proof of identification (POI)',
      'Proof of residential address',
      'Credit Card Front',
      'Credit Card Back',
      'Proof of identification Back',
      'Selfie with ID',
    ],
    upload: 'Télécharger',
    isConfirmed: 'est confirmé',
    uploadAnotherDocument: 'Télécharger un autre document',
    isUnderReview: 'est en cours de révision',
    questionnaire: 'Questionnaire',
    sendAnswers: 'Envoyer les réponses',
    progressBarOf: 'of',
    progressBarText: 'of your documents have been uploaded and confirmed',

    manageAccounts: 'Transférer des fonds entre vos comptes',
    transferFrom: 'Du compte',
    transferTo: 'Pour le compte',
    amount: 'Montant',
    makeTransfer: 'Effectuer un transfert',
    yourAccounts: 'Vos comptes',
    account: 'Compte',
    currency: 'Monnaie',
    balance: 'Balance',
    credit: 'Crédit',
    makeActive: 'Rendre actif',
    active: 'Actif',

    sendMessage: 'Envoyer un message',

    pct_0: 'Flexible',
    pct_30: 'Verrouillé 1 mois',
    pct_90: 'Verrouillé 1 trimestre',
    pct_180: 'Verrouillé 6 mois',
    pct_365: 'Verrouillé 1 an',
    savings: 'Épargne',
    detailedInformation: 'Informations détaillées',
    pleaseSelect: 'Veuillez sélectionner une devise et une période',
    openSavings: 'Épargne ouverte',
    currentTime: 'Heure actuelle',
    releaseTime: 'Heure de sortie',
    currencyChosen: 'Devise choisie',
    periodChosen: 'Période choisie',
    yourRate: 'Votre taux',
    yourEarnings: 'Vos revenus',
    accountFrom: 'Compte pour transférer des fonds de',
    enterAmount: 'Entrez le montant',
    in: 'Sur',
    openAndInvest: "Ouvrir un compte d'épargne et investir",
    investment: 'Investissement',
    period: 'Période',
    daysTotal: 'Nombre total de jours',
    finalEarnings: 'Revenu final',
    created: 'Créé',
    daysElapsed: 'Jours écoulés',
    days: 'jours',
    earnings: 'Revenus',
    fundsReleasedIn: 'Fonds libérés en',
    claim: "Demande d'indemnisation",

    updatePassword: 'METTRE À JOUR LE MOT DE PASSE',
    currentPassword: 'Mot de passe actuel',
    newPassword: 'Nouveau mot de passe',
    changePassword: 'Changer le mot de passe',
    activationForGoogle: "Code d'activation pour Google Authenticator",
    activateAndEnter:
      "Activez l'Authenticator et entrez un code généré dans le champ ci-dessous.",
    qrCode: 'QR Code',
    activateProtection: 'Activer la protection 2FA',
    protectionActive: 'La protection 2FA est active',
    twoFactorCode: 'Code 2FA',
    disableProtection: 'Désactiver la protection 2FA',
    language: 'Langue',
    dashboardCurrency: 'Tableau de bord des devises',
    confirmNewPassword: 'Confirmer le nouveau mot de passe',

    yourLink: 'VOTRE LIEN DE RÉFÉRENCE',
    referredFriends: 'AMIS RÉFÉRENCÉS',
    id: 'ID',
    name: 'Nom',
    registered: 'Enregistré',
    level: 'Niveau',
    payout: 'Payout',
    lastPayouts: 'LES DERNIERS PAIEMENTS DE PARRAINAGE',
    time: 'Temps',
    amount: 'Montant',
    currency: 'Monnaie',
    info: 'Info',

    months: {
      Jan: 'Jan',
      Feb: 'Fev',
      Mar: 'Mar',
      Apr: 'Abr',
      May: 'Mai',
      Jun: 'Juin',
      Jul: 'Jui',
      Aug: 'Aoû',
      Sep: 'Sep',
      Oct: 'Oct',
      Nov: 'Nov',
      Dec: 'Dec',
    },
    orderTypes: [
      'Acheter',
      'Vendez',
      "Limite d'achat",
      'Limite de vente',
      "Stop à l'achat",
      'Stop à la vente',
    ],
    statuses: ['En attente', 'Approuvé', 'Refusé', 'Traitement'],

    NOT_FOUND: 'Erreur de demande',
    FORBIDDEN: 'Accès refusé. Veuillez vous reconnecter',
    SERVER_ERROR: "L'action a échoué. Veuillez réessayer plus tard",
    INVALID_ACCOUNT: 'La session a expiré. Veuillez vous reconnecter',
    INVALID_EMAIL: 'Adresse électronique non valide',
    INVALID_PHONE: 'Numéro de téléphone non valide',
    INVALID_COUNTRY: 'Pays non valide',
    INVALID_LOGIN: 'Identifiants de connexion non valides',
    USER_BLOCKED: 'Le compte est bloqué. Veuillez contacter le support',
    USER_REGISTERED:
      "L'utilisateur dont l'adresse électronique ou le numéro de téléphone a été saisi est déjà enregistré.",
    INVALID_USER: "L'utilisateur n'est pas trouvé",
    REJECTED:
      "L'opération n'est pas autorisée en raison des restrictions de la plate-forme",
    INVALID_OLD_PASSWORD: "L'ancien mot de passe n'est pas valide",
    INVALID_SYMBOL: 'Symbole commercial non valide',
    INVALID_SYMBOL_PRECISION: 'Précision du symbole non valide',
    INVALID_FRAME: 'Cadre graphique non valide',
    INVALID_CURRENCY: 'Devise non valide',
    INVALID_PRODUCT: 'Type de trading de compte invalide',
    INSUFFICIENT_FUNDS: 'Fonds insuffisants',
    INVALID_WITHDRAWAL: "Le retrait n'est pas trouvé",
    INVALID_STATUS: 'Statut non valide',
    INVALID_FILE: 'Extension de fichier non valide ou fichier trop volumineux',
    INVALID_DOCUMENT: "Le document n'est pas trouvé",
    INVALID_NAME: 'Nom non valide',
    INVALID_LOT_SIZE: 'Taille de lot non valide',
    INVALID_LOT_STEP: 'Pas de lot invalide',
    INVALID_MIN_VOLUME: 'Volume minimal non valide',
    INVALID_MAX_VOLUME: 'Volume maximal invalide',
    INVALID_GROUP: 'Groupe non valide',
    INVALID_SPREAD: 'Diffusion non valide',
    REQUEST_TIMEOUT: 'La demande a expiré. Veuillez réessayer plus tard',
    INVALID_TYPE: 'Type de commande non valide',
    INVALID_VOLUME: "Volume d'ordre invalide",
    INVALID_SL: "Valeur invalide de l'excédent de pertes",
    INVALID_TP: 'Valeur de prise de bénéfices invalide',
    INVALID_PRICE: 'Prix de la commande invalide',
    INVALID_EXPIRATION: 'Expiration de la commande invalide',
    NOT_ENOUGH_MARGIN: 'Pas assez de marge',
    INVALID_ORDER: "L'ordre n'est pas trouvé",
    MARKET_CLOSED: "Le commerce n'est pas disponible. Veuillez réessayer",
    INVALID_BALANCE_TYPE: "Type d'opération de balance non valide",
    INVALID_HASH: 'Hachage non valide',
    HASH_EXPIRED:
      'Le lien de réinitialisation de votre mot de passe a expiré. Veuillez en demander un nouveau',
    INVALID_CODE: 'Code 2FA invalide',
    CHAT_DISABLED: "L'accès au chat d'assistance a été fermé",
    WITHDRAWAL_NOT_ALLOWED: "L'accès aux retraits a été fermé",
    TRADING_DISABLED: 'Trading is disabled for current account',
    PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
    LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
    LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
    LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
    LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
    LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
    LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
    LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
    LOCAL_NO_TRADING_ACCOUNTS:
      'You do not have trading accounts available. Please contact the support',
    LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
    LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
    LOCAL_INVALID_FILE_TYPE:
      'File format not supported. Please attach only JPG, PNG or PDF',
    FLOOD_REJECTED:
      'You are sending messages too often. Please try again later',
    LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',

    countries: {
      AF: 'Afghanistan',
      AX: 'Aland Islands',
      AL: 'Albania',
      DZ: 'Algeria',
      AS: 'American Samoa',
      AD: 'Andorra',
      AO: 'Angola',
      AI: 'Anguilla',
      AQ: 'Antarctica',
      AG: 'Antigua And Barbuda',
      AR: 'Argentina',
      AM: 'Armenia',
      AW: 'Aruba',
      AU: 'Australia',
      AT: 'Austria',
      AZ: 'Azerbaijan',
      BS: 'Bahamas',
      BH: 'Bahrain',
      BD: 'Bangladesh',
      BB: 'Barbados',
      BY: 'Belarus',
      BE: 'Belgium',
      BZ: 'Belize',
      BJ: 'Benin',
      BM: 'Bermuda',
      BT: 'Bhutan',
      BO: 'Bolivia',
      BA: 'Bosnia And Herzegovina',
      BW: 'Botswana',
      BV: 'Bouvet Island',
      BR: 'Brazil',
      IO: 'British Indian Ocean Territory',
      BN: 'Brunei Darussalam',
      BG: 'Bulgaria',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      KH: 'Cambodia',
      CM: 'Cameroon',
      CA: 'Canada',
      CV: 'Cape Verde',
      KY: 'Cayman Islands',
      CF: 'Central African Republic',
      TD: 'Chad',
      CL: 'Chile',
      CN: 'China',
      CX: 'Christmas Island',
      CC: 'Cocos (Keeling) Islands',
      CO: 'Colombia',
      KM: 'Comoros',
      CG: 'Congo',
      CD: 'Congo, Democratic Republic',
      CK: 'Cook Islands',
      CR: 'Costa Rica',
      CI: "Cote D'Ivoire",
      HR: 'Croatia',
      CU: 'Cuba',
      CY: 'Cyprus',
      CZ: 'Czech Republic',
      DK: 'Denmark',
      DJ: 'Djibouti',
      DM: 'Dominica',
      DO: 'Dominican Republic',
      EC: 'Ecuador',
      EG: 'Egypt',
      SV: 'El Salvador',
      GQ: 'Equatorial Guinea',
      ER: 'Eritrea',
      EE: 'Estonia',
      ET: 'Ethiopia',
      FK: 'Falkland Islands (Malvinas)',
      FO: 'Faroe Islands',
      FJ: 'Fiji',
      FI: 'Finland',
      FR: 'France',
      GF: 'French Guiana',
      PF: 'French Polynesia',
      TF: 'French Southern Territories',
      GA: 'Gabon',
      GM: 'Gambia',
      GE: 'Georgia',
      DE: 'Germany',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GR: 'Greece',
      GL: 'Greenland',
      GD: 'Grenada',
      GP: 'Guadeloupe',
      GU: 'Guam',
      GT: 'Guatemala',
      GG: 'Guernsey',
      GN: 'Guinea',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HT: 'Haiti',
      HM: 'Heard Island & Mcdonald Islands',
      VA: 'Holy See (Vatican City State)',
      HN: 'Honduras',
      HK: 'Hong Kong',
      HU: 'Hungary',
      IS: 'Iceland',
      IN: 'India',
      ID: 'Indonesia',
      IR: 'Iran',
      IQ: 'Iraq',
      IE: 'Ireland',
      IM: 'Isle Of Man',
      IL: 'Israel',
      IT: 'Italy',
      JM: 'Jamaica',
      JP: 'Japan',
      JE: 'Jersey',
      JO: 'Jordan',
      KZ: 'Kazakhstan',
      KE: 'Kenya',
      KI: 'Kiribati',
      KR: 'Korea',
      KW: 'Kuwait',
      KG: 'Kyrgyzstan',
      LA: "Lao People's Democratic Republic",
      LV: 'Latvia',
      LB: 'Lebanon',
      LS: 'Lesotho',
      LR: 'Liberia',
      LY: 'Libyan Arab Jamahiriya',
      LI: 'Liechtenstein',
      LT: 'Lithuania',
      LU: 'Luxembourg',
      MO: 'Macao',
      MK: 'Macedonia',
      MG: 'Madagascar',
      MW: 'Malawi',
      MY: 'Malaysia',
      MV: 'Maldives',
      ML: 'Mali',
      MT: 'Malta',
      MH: 'Marshall Islands',
      MQ: 'Martinique',
      MR: 'Mauritania',
      MU: 'Mauritius',
      YT: 'Mayotte',
      MX: 'Mexico',
      FM: 'Micronesia',
      MD: 'Moldova',
      MC: 'Monaco',
      MN: 'Mongolia',
      ME: 'Montenegro',
      MS: 'Montserrat',
      MA: 'Morocco',
      MZ: 'Mozambique',
      MM: 'Myanmar',
      NA: 'Namibia',
      NR: 'Nauru',
      NP: 'Nepal',
      NL: 'Netherlands',
      AN: 'Netherlands Antilles',
      NC: 'New Caledonia',
      NZ: 'New Zealand',
      NI: 'Nicaragua',
      NE: 'Niger',
      NG: 'Nigeria',
      NU: 'Niue',
      NF: 'Norfolk Island',
      MP: 'Northern Mariana Islands',
      NO: 'Norway',
      OM: 'Oman',
      PK: 'Pakistan',
      PW: 'Palau',
      PS: 'Palestinian Territory, Occupied',
      PA: 'Panama',
      PG: 'Papua New Guinea',
      PY: 'Paraguay',
      PE: 'Peru',
      PH: 'Philippines',
      PN: 'Pitcairn',
      PL: 'Poland',
      PT: 'Portugal',
      PR: 'Puerto Rico',
      QA: 'Qatar',
      RE: 'Reunion',
      RO: 'Romania',
      RU: 'Russia',
      RW: 'Rwanda',
      BL: 'Saint Barthelemy',
      SH: 'Saint Helena',
      KN: 'Saint Kitts And Nevis',
      LC: 'Saint Lucia',
      MF: 'Saint Martin',
      PM: 'Saint Pierre And Miquelon',
      VC: 'Saint Vincent And Grenadines',
      WS: 'Samoa',
      SM: 'San Marino',
      ST: 'Sao Tome And Principe',
      SA: 'Saudi Arabia',
      SN: 'Senegal',
      RS: 'Serbia',
      SC: 'Seychelles',
      SL: 'Sierra Leone',
      SG: 'Singapore',
      SK: 'Slovakia',
      SI: 'Slovenia',
      SB: 'Solomon Islands',
      SO: 'Somalia',
      ZA: 'South Africa',
      GS: 'South Georgia And Sandwich Isl.',
      ES: 'Spain',
      LK: 'Sri Lanka',
      SD: 'Sudan',
      SR: 'Suriname',
      SJ: 'Svalbard And Jan Mayen',
      SZ: 'Swaziland',
      SE: 'Sweden',
      CH: 'Switzerland',
      SY: 'Syrian Arab Republic',
      TW: 'Taiwan',
      TJ: 'Tajikistan',
      TZ: 'Tanzania',
      TH: 'Thailand',
      TL: 'Timor-Leste',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinidad And Tobago',
      TN: 'Tunisia',
      TR: 'Turkey',
      TM: 'Turkmenistan',
      TC: 'Turks And Caicos Islands',
      TV: 'Tuvalu',
      UG: 'Uganda',
      UA: 'Ukraine',
      AE: 'United Arab Emirates',
      GB: 'United Kingdom',
      US: 'United States',
      UM: 'United States Outlying Islands',
      UY: 'Uruguay',
      UZ: 'Uzbekistan',
      VU: 'Vanuatu',
      VE: 'Venezuela',
      VN: 'Viet Nam',
      VG: 'Virgin Islands, British',
      VI: 'Virgin Islands, U.S.',
      WF: 'Wallis And Futuna',
      EH: 'Western Sahara',
      YE: 'Yemen',
      ZM: 'Zambia',
      ZW: 'Zimbabwe',
    },
  },
  az: {
    _name: 'Azərbaycan',
    policy: 'Məxfilik Siyasəti',
    policyAML: 'AML Siyasəti',
    policyKYC: 'KYC Siyasəti',
    locense: 'Lisenziya',
    view_all: 'Hamısını Göstər',
    sell_btn: 'Sat',
    buy_btn: 'Al',
    currency_action: 'valyuta',

    importantAccountDetails: 'Vacib hesab məlumatları',
    viewAdditionalSettings: 'Əlavə parametrləri bax',
    switchDarkMode: 'Tünd/İşıq rejimini dəyiş',
    writeProblem: 'Probleminizi bizə yazın',
    darkMode: 'Tünd rejim',

    yay: 'Təbriklər',
    purchased: 'Uğurla aldınız',
    from: 'kimi',
    walet: 'Cüzdan',
    trade: 'Ticarət',

    withdrawDetails: 'Çıxarış məlumatları',
    saveDetails: 'Növbəti dəfə üçün məlumatları saxla',
    amountToWithdraw: 'Çıxarış məbləği',
    withdrawLimit: 'Gündəlik çıxarış limiti: $1,000,000.00 qalıq.',

    serviceFee: 'Xidmət haqqı',
    youWillPay: 'Ödəyəcəksiniz',
    cancel: 'Ləğv et',
    understand: 'Başa düşürəm, davam et',

    select: 'Seçin',
    enterAmount: 'Məbləği daxil edin',
    orderInfo: 'Sifariş Məlumatı',
    confirmOrder: 'Sifarişi Təsdiqlə',
    get: 'Almaq',
    spend: 'Xərcləmək',
    buying: 'Alış',
    accountTransactions: 'Hesab Əməliyyatları',
    overview: 'Ümumi baxış',
    accountBalances: 'Hesab Balansları',
    details: 'Detallar',
    loginHistory: 'Giriş tarixi',
    device: 'Cihaz',
    contactUs: 'Bizimlə Əlaqə',
    sessionLog: 'Sessiyalar və giriş tarixi',
    selectMethods: 'Metodları seçin',
    depositDetails: 'Depozit məlumatları',
    order: 'Sifariş',
    support: 'Dəstək',
    supportChat: 'Dəstək Çatı',
    createChat: 'Çat Yaradın',
    homepage: 'Ana Səhifə',
    takeProfitStopLoss: 'Gəlir/Qeydiyyat Stopu',
    takeProfit: 'Gəlir Al',
    stopLoss: 'Zərər Dayandır',
    updateOrder: 'Sifarişi Yenilə',
    balance: 'Balans',
    credit: 'Kredit',
    equity: 'Kapital',
    practice: 'TƏCRÜBƏ',
    real: 'GERÇƏK',
    account: 'HESAB',
    invested: 'İnvestisiya edilmiş',
    profit: 'Gəlir',
    loss: 'Zərər',
    margin: 'Marja',
    marginLevel: 'Marja Səviyyəsi',
    marginFree: 'Azad Marja',
    cfd: 'CFD',
    platform: 'Platforma',
    deposit: 'Depozit',
    marketWatch: 'Bazar Baxışı',
    activeOrders: 'Aktiv Sifarişlər',
    tradingHistory: 'Ticarət Tarixi',
    economicCalendar: 'İqtisadi Təqvim',
    marketNews: 'Bazar Xəbərləri',
    closeOrder: 'Mövqeyi Bağla',
    areYouSure: 'Mövqeyi bağlamağa əminsiniz?',
    buy: 'Al',
    sell: 'Sat',
    yes: 'Bəli',
    no: 'Xeyr',
    addNewChart: 'Yeni Çart Əlavə Et',
    watchlist: 'İzləmə Siyahısı',
    asset: 'Aktiv',
    price: 'Qiymət',
    changePct: '24 saatlıq dəyişiklik',
    pending: 'Gözləyir',
    assetPrice: 'Aktiv Qiyməti',
    current: 'Cari',
    revert: 'Bazar Qiymətinə Qayıt',
    automatically:
        'Qiymət bu səviyyəyə çatanda mövqe avtomatik açılacaq',
    default: 'standart',
    volume: 'Həcm',
    lots: 'lotlar',
    units: 'vahidlər',

    currency: 'Valyuta',
    contractSize: 'Müqavilə Ölçüsü',
    position: 'Mövqe',
    margin: 'Marja',
    freeMargin: 'Azad Marja',
    takeProfitStopLoss: 'Gəlir Al & Zərər Dayandır',
    pending: 'Gözləyir',
    market: 'Bazar',
    leverage: 'Leveraj',
    spread: 'Səpələnmə',
    notSet: 'Təyin edilməyib',
    at: 'vaxtı',
    buy: 'al',
    sell: 'sat',
    supportBanner: 'HƏR GÜN, GÜNÜN HƏR SAATI',
    currentTime: 'CARİ VAXT',
    liveChat: 'Canlı Çat',
    balance: 'Balans',
    credit: 'Kredit',
    equity: 'Kapital',
    marginLevel: 'Marja səviyyəsi',
    pnl: 'PnL',
    profitTotal: 'Ömürlük PnL',
    dateRegistered: 'Qeydiyyat Tarixi',
    userId: 'İstifadəçi ID',
    dashboard: 'İdarə Paneli',
    personalData: 'Şəxsi Məlumatlar',
    deposit: 'Depozit',
    withdrawFunds: 'Vəsait Çıxarışı',
    savings: 'Əmanətlər',
    settings: 'Parametrlər',
    logout: 'Çıxış',
    BT_INVOICE: 'Məbləği daxil edin və faktura yaradın',
    BT_TITLE: 'Bank detallarımız',
    account: 'Hesab',
    bank_name: 'Bankın Adı',
    beneficiary: 'Benefisiar',
    beneficiary_address: 'Benefisiar Ünvanı',
    swift: 'SWIFT',
    iban: 'IBAN',
    ru_inn: 'İNН',
    ru_kpp: 'KPP',
    corresponding_account: 'Müxbir Hesabı',
    TOKEN_AMOUNT: 'Məbləğ',
    TOKEN_SUBTOTAL: 'Ara Cəmi',
    TOKEN_TOTAL: 'Cəmi',
    TOKEN_DESCRIPTION: 'Təsvir',
    TOKEN_PAYMENT_DETAILS: 'Ödəniş Təfərrüatları',
    PENDING_DEPOSITS_LIMIT: 'Depozit Limiti',
    makeDeposit: 'Depozit Yatırın',
    practiceAccount: 'Təcrübə Hesabı',
    realAccount: 'Gerçək Hesab',
    noWithdrawals: 'Çıxarış yoxdur',
    easyWithdrawals: 'Asan Çıxarışlar',
    allAssets: 'Bütün aktivlər mövcuddur',
    fullFledged: 'Tam funksional platforma',
    fillUpTo: 'Doldurun qədər',
    freeReplenishment: 'Pulsuz Tamamlama',
    topUp: 'Hesabınızı Artırın',
    minimumAmount: 'Minimal Məbləğ',
    canSwitch: 'Hesablar arasında istənilən vaxt keçid edə bilərsiniz',
    chartGrids: 'QRAFİK ŞƏBƏKƏLƏRİ',
    chart1: '1 qrafik',
    chart2: '2 qrafik',
    chart3: '3 qrafik',
    chart4: '4 qrafik',
    connectionLost: 'Serverlə əlaqə kəsildi',
    retryNow: 'Təkrar cəhd et',
    loginToTradeRoom: 'Ticarət Otağına Giriş',
    email: 'E-poçt ünvanı',
    enterEmail: 'E-poçt ünvanınızı daxil edin',
    twoFactor: '2FA Kodu (əgər aktivdirsə)',
    twoFactorAuth: 'İki Faktorlu Doğrulama',
    password: 'Şifrə',
    yourPassword: 'Şifrəniz',
    signIn: 'Daxil Ol',
    dontHaveAccount: 'Hesabınız yoxdur?',
    restore: 'Bərpa et',
    fullName: 'Tam Ad',
    havePromo: 'Promokodum var',
    promo: 'Promokod',
    login: 'Giriş',
    haveAccount: 'Artıq hesabınız var?',
    hide: 'Gizlət',
    goTo: 'Geri qayıt',

    setNewPassword: 'Yeni Şifrə Təyin Et',
    newPassword: 'Yeni şifrə',
    confirmNewPassword: 'Yeni şifrəni təsdiqləyin',
    setButton: 'Təyin Et',

    repeatPassword: 'Şifrəni Təkrarla',
    createNewAccount: 'Yeni Hesab Yarat',
    email: 'E-poçt ünvanı',
    firstName: 'Ad',
    lastName: 'Soyad',
    country: 'Ölkə',
    phone: 'Telefon',
    password: 'Şifrə',
    createAccount: 'Hesab Yarat',
    currency: 'Valyuta',

    forgotPassword: 'Şifrəni Unutmusunuz?',
    submitEmail:
        'Qeydiyyat zamanı istifadə olunan e-poçt ünvanını daxil edin, poçtunuzu yoxlayın və göstərilən təlimatlara əməl edin',
    submit: 'Təsdiq et',

    error: 'Xəta',
    success: 'Uğur',
    deposit: 'Depozit',
    withdrawal: 'Çıxarış',
    depositApproved: 'Depozit təsdiqləndi',
    depositDeclined: 'Depozit rədd edildi',
    withdrawalApproved: 'Çıxarış təsdiqləndi',
    withdrawalDeclined: 'Çıxarış rədd edildi',

    forex: 'Forex',
    stocks: 'Səhmlər',
    commodities: 'Əmtəələr',
    indices: 'İndekslər',
    crypto: 'Kripto',
    metals: 'Metallar',
    nft: 'NFT',

    buy: 'AL',
    profitCalculator: 'Gəlir Kalkulyatoru',
    sell: 'SAT',
    volume: 'Həcm',
    entryPrice: 'Giriş Qiyməti',
    takeProfit: 'Gəlir Al',
    stopLoss: 'Zərər Dayandır',
    maxPosition: 'Maksimal Mövqe',
    calculate: 'Hesabla',
    calculationsFor: 'Üçün Hesablamalar',
    leverage: 'Leveraj',
    requiredMargin: 'Tələb olunan Marja',
    profitFromTP: 'Gəlir TP-dən',
    lossFromSL: 'Zərər SL-dən',
    roe: 'ROE',

    title: 'Depozit uğurlu',
    text: 'Depozitiniz uğurla işlənildi!',

    title: 'Depozit uğursuz oldu',
    text: 'Depozitiniz işlənmədi.',

    marketWatch: 'Bazar Baxışı',
    search: 'Axtarış...',

    commission: 'Komissiya',
    volume: 'Həcm',
    margin: 'Marja',
    active: 'Aktiv',
    pending: 'Gözləyir',
    activeOrders: 'Aktiv Sifarişlər',
    portfolio: 'Portfel',
    allPositions: 'Bütün Mövqelər',
    noPositionsLine1: 'Açıq mövqeləriniz yoxdur',
    noPositionsLine2: 'hələlik mövqe yoxdur',
    show: 'Göstər',
    more: 'daha çox',
    less: 'daha az',
    purchaseTime: 'Alış Vaxtı',
    closePosition: 'Mövqeyi Bağla',
    priceOpen: 'Alış Qiyməti',
    priceSl: 'Zərər Dayandır Qiyməti',
    priceTp: 'Gəlir Al Qiyməti',
    type: 'Mövqe İstiqaməti',
    pnl: 'Gəlir/Zərər',
    cancelOrder: 'Sifarişi Ləğv et',
    orderType: 'Sifariş Növü',
    cancelled: 'Ləğv edildi',
    tradingHistory: 'Ticarət Tarixi',
    noHistoryLine1: 'Heç bir bağlanmış',
    noHistoryLine2: 'əməliyyatınız yoxdur',

    economicCalendar: 'İqtisadi Təqvim',

    marketNews: 'Bazar Xəbərləri',

    ordersActive: 'Aktiv Sifarişlər',
    ordersHistory: 'Sifarişlərin Tarixi',
    id: 'ID',
    symbol: 'Simvol',
    type: 'Növ',
    volume: 'Həcm',
    openPrice: 'Açılış Qiyməti',
    openTime: 'Açılış Vaxtı',
    closePrice: 'Bağlanış Qiyməti',
    closeTime: 'Bağlanış Vaxtı',
    sl: 'SL',
    tp: 'TP',
    price: 'Qiymət',
    pnl: 'PnL',
    actions: 'Əməliyyatlar',
    edit: 'Redaktə et',
    close: 'Bağla',
    commission: 'Komissiya',
    swap: 'Swap',
    swapLong: 'Uzun Swap',
    swapShort: 'Qısa Swap',

    assetInfo: 'Aktiv Məlumatı',
    tradingConditions: 'Ticarət Şərtləri',
    information: 'Sessiya Dəyişikliyi',
    bid: 'Təklif',
    ask: 'Sorğu',
    sessionChange: 'Sessiya Dəyişikliyi',
    tradeNow: 'İndi Ticarət et',
    opens: 'Açılır',
    closes: 'Bağlanır',
    at: 'vaxtı',
    open247: '24/7 Açıqdır',
    today: 'bu gün',
    tomorrow: 'sabah',
    sunday: 'Bazar günü',
    monday: 'Bazar ertəsi',
    tuesday: 'Çərşənbə axşamı',
    wednesday: 'Çərşənbə',
    thursday: 'Cümə axşamı',
    friday: 'Cümə',
    saturday: 'Şənbə',
    contractSpecification: 'Müqavilə Xüsusiyyəti',
    symbol: 'Simvol',
    name: 'Ad',
    market: 'Bazar',
    baseCurrency: 'Baza Valyutası',
    digits: 'Rəqəmlər',
    lotSize: 'Lot Ölçüsü',
    lotStep: 'Lot Addımı',
    minVolume: 'Minimal Həcm',
    maxVolume: 'Maksimal Həcm',
    leverage: 'Güc (Leverage)',
    commission: 'Komissiya',
    swapLong: 'Uzun Swap',
    swapShort: 'Qısa Swap',
    schedule: 'Cədvəl',
    weekday: 'Həftə günü',
    tradingTime: 'Ticarət Vaxtı',
    closed: 'bağlıdır',
    sell: 'Sat',
    buy: 'Al',
    low: 'Ən Aşağı',
    high: 'Ən Yuxarı',
    oneHourChange: '1 saatlıq dəyişiklik',
    oneDayChange: '1 günlük dəyişiklik',
    oneWeekChange: '1 həftəlik dəyişiklik',
    oneMonthChange: '1 aylıq dəyişiklik',
    threeMonthsChange: '3 aylıq dəyişiklik',
    oneYearChange: '1 illik dəyişiklik',
    loading: 'Yüklənir...',

    title: 'Avtomatik Ticarətçi',
    riskLevelLow: 'Risk Səviyyəsi: Aşağı',
    riskLevelMedium: 'Risk Səviyyəsi: Orta',
    riskLevelHigh: 'Risk Səviyyəsi: Yüksək',
    perDay: 'Gündəlik əməliyyatlar:',
    desc: `CFD-lər və digər leverage ilə məhsullar ticarət zamanı itkilərə səbəb ola bilər. Ticarətə başlamazdan əvvəl,
             müştərilər Risk Bəyanatları səhifəsində müvafiq risk bəyanatlarını oxumalıdırlar. Avtomatik ticarət nəticələri
             təmin etmir. Şirkət avtomatik ticarət zamanı vəsaitlərin itirilməsinə görə məsuliyyət daşımır. Riskləri tam
             başa düşdüyünüzdən və riskləri idarə etmək üçün tədbirlər gördüyünüzdən əmin olun.`,
    accept: 'Qəbul et',

    trade: 'Ticarət',
    startTrading: 'Ticarətə Başla',
    deposit: 'Depozit',
    dashboard: 'İdarə Paneli',
    personalInfo: 'Şəxsi Məlumat',
    withdrawal: 'Çıxarış',
    verification: 'Doğrulama',
    accounts: 'Hesablar',
    liveChat: 'Canlı Çat',
    savings: 'Əmanətlər',
    settings: 'Parametrlər',
    logOut: 'Çıxış',

    methods: 'METODLAR',
    confirm: 'Bank tətbiqinizdə təsdiqləyin',
    sendOtp: 'OTP göndər',
    otpCode: 'OTP kodu',
    addWalletAddress:
        'Bu depozit metodu üçün cüzdan təyin edilməyib. Zəhmət olmasa agentinizlə və ya dəstək komandası ilə əlaqə saxlayın.',
    addressReceipt: 'Ünvanın yüklənməsini gözləyin',
    makeDeposit: 'DEPOZİT YATIRIN',
    instant: 'Ani',
    minutes: 'dəqiqə',
    hours: 'saat',
    days: 'gün',
    amount: 'Məbləğ',
    continue: 'Davam et',
    qrCode: 'QR KOD',
    depositAddress: 'DEPOZİT ÜNVANI',
    copy: 'Kopyala',
    last: 'SON',
    deposits: 'DEPOZİTLƏR',
    time: 'Vaxt',
    currency: 'Valyuta',
    status: 'Status',
    info: 'Məlumat',

    pleaseWait: 'Zəhmət olmasa gözləyin. Ödəniş xidmət təminatçısına qoşulur...',
    cardPayment: 'Kartla ödəniş',
    inOrder: 'Məbləği göndərmək üçün',
    pleaseProvide: 'zəhmət olmasa kart məlumatlarını təqdim edin:',

    completeVerification: 'Zəhmət olmasa doğrulamanı tamamlayın',
    noOrderYet: 'Hələ sifariş yoxdur',
    totalBalance: 'Ümumi Balans',
    totalDeposits: 'Ümumi Depozitlər',
    profitableOrders: 'Gəlirli Sifarişlər',
    totalPnl: 'Ümumi PnL',
    roi: 'ROI',
    activityLog: 'Fəaliyyət Jurnalı',
    loginFromIp: 'IP-dən giriş',
    tradingResults: 'Ticarət Nəticələri',
    week: 'Həftə',
    month: 'Ay',
    year: 'İl',
    successRate: 'Uğur dərəcəsi',
    closedWithProfit: 'Gəlirlə Bağlandı',
    closedWithLoss: 'Zərərlə Bağlandı',
    account: 'Hesab',
    balance: 'Balans',
    leverage: 'Leveraj',
    credit: 'Kredit',
    tradeNow: 'İndi Ticarət et',
    usingCurrentRate: 'cari məzənnədən istifadə edərək',

    personalDetails: 'Şəxsi Məlumatlar',
    profilePhoto: 'Profil Şəkli',
    firstName: 'Ad',
    country: 'Ölkə',
    lastName: 'Soyad',
    address: 'Ünvan',
    email: 'E-poçt',
    phone: 'Telefon nömrəsi',
    saveChanges: 'Dəyişiklikləri Saxla',

    dropFile: 'Yükləmək üçün faylı yuxarıdakı dairəyə sürüşdürün',
    notAllowed: 'Bu yayımlamaq qadağandır',
    notAllowedLine1: 'Açıq şəkildə seksual və ya pornografik xarakterli şəkillər',
    notAllowedLine2:
        'Etnik və ya irqi nifrəti və ya təcavüzü qızışdırmağa yönəlmiş şəkillər',
    notAllowedLine3: '18 yaşdan kiçik şəxsləri əhatə edən şəkillər',
    notAllowedLine4: 'Üçüncü tərəfə aid müəllif hüquqları ilə qorunan şəkillər',
    notAllowedLine5:
        '5 MB-dan böyük və JPG, GIF və ya PNG formatından fərqli olan şəkillər',
    requirements:
        'Şəkildə üzünüz aydın şəkildə görünməlidir. Sizin tərəfinizdən yüklənmiş bütün şəkillər və videolar bu tələblərə uyğun olmalıdır, əks halda onlar silinə bilər.',

    withdrawalOff: 'Çıxarış məlumatları üçün hesab menecerinizlə əlaqə saxlayın',
    cryptocurrency: 'Kriptovalyuta',
    withdrawal: 'Çıxarış',
    requestNewWithdrawal: 'YENİ ÇIXARIŞ TƏLƏB ET',
    amount: 'Məbləğ',
    currentBalance: 'Cari hesab balansınız',
    withdrawAll: 'Hamısını çıxar',
    requestWithdrawal: 'Çıxarış Tələb Et',
    last: 'SON',
    withdrawalRequests: 'ÇIXARIŞ TƏLƏBLƏRİ',
    time: 'Vaxt',
    currency: 'Valyuta',
    info: 'Məlumat',
    status: 'Status',
    bank: 'Bank',
    bitcoin: 'Bitcoin',
    card: 'Kart',
    ethereum: 'Ethereum',
    eth: 'Ethereum',
    usdt: 'USDT',
    withdrawalDetails: 'Çıxarış Təfərrüatları',
    address: 'Cüzdan Ünvanı',
    bankAccount: 'Hesab Nömrəsi',
    bankHolder: 'Hesab Sahibinin Adı',
    bankIban: 'IBAN',
    bankSwift: 'Bank SWIFT Kodu',
    cardNumber: 'Kart Nömrəsi',
    cardHolder: 'Kart Sahibinin Adı',

    dragAndDrop: 'Sənədi bu sahəyə sürüşdürüb buraxın',
    documentsList: 'YÜKLƏNƏN SƏNƏDLƏRİN SİYAHISI',
    document: 'Sənəd',
    timeUploaded: 'Yüklənmə Vaxtı',
    timeProcessed: 'Emal Vaxtı',
    status: 'Status',
    types: [
      'Şəxsiyyət Sübutu',
      'Yaşayış Sübutu',
      'Kredit Kartı Ön Hissəsi',
      'Kredit Kartı Arxa Hissəsi',
      'Şəxsiyyət Sübutunun Arxası',
      'Selfi',
    ],

    types2: [
      'Şəxsiyyət Sübutu (POI)',
      'Yaşayış Ünvanının Sübutu',
      'Kredit Kartı Ön Hissəsi',
      'Kredit Kartı Arxa Hissəsi',
      'Şəxsiyyət Sübutunun Arxası',
      'Şəxsiyyət Vəsiqəsi ilə Selfi',
    ],
    upload: 'Yüklə',
    isConfirmed: 'təsdiqlənib',
    uploadAnotherDocument: 'Başqa sənəd yüklə',
    isUnderReview: 'baxışdadır',
    questionnaire: 'Sorğu',
    sendAnswers: 'Cavabları Göndər',
    progressBarOf: 'of',
    progressBarText: 'sənədləriniz yüklənib və təsdiqlənib',

    manageAccounts: 'Hesablar arasında vəsait köçürün',
    transferFrom: 'Hesabdan',
    transferTo: 'Hesaba',
    amount: 'Məbləğ',
    makeTransfer: 'Köçürmə Et',
    yourAccounts: 'Hesablarınız',
    account: 'Hesab',
    currency: 'Valyuta',
    balance: 'Balans',
    credit: 'Kredit',
    makeActive: 'Aktiv Et',
    active: 'Aktiv',

    sendMessage: 'Mesaj Göndər',

    pct_0: 'Elastik',
    pct_30: '1 Ay Bağlı',
    pct_90: '1 Rüb Bağlı',
    pct_180: '6 Ay Bağlı',
    pct_365: '1 İl Bağlı',
    savings: 'Əmanətlər',
    detailedInformation: 'Ətraflı Məlumat',
    pleaseSelect: 'Zəhmət olmasa valyuta və dövrü seçin',
    openSavings: 'Əmanət Hesabı Açın',
    currentTime: 'Cari Vaxt',
    releaseTime: 'Buraxılış Vaxtı',
    currencyChosen: 'Seçilmiş Valyuta',
    periodChosen: 'Seçilmiş Dövr',
    yourRate: 'Sizin Faiz Dərəcəniz',
    yourEarnings: 'Gəliriniz',
    accountFrom: 'Vəsait köçürmək üçün hesab',
    enterAmount: 'Məbləği Daxil Edin',
    in: 'İçində',
    openAndInvest: 'Əmanət Hesabı Açın və İnvestisiya Edin',
    investment: 'İnvestisiya',
    period: 'Dövr',
    daysTotal: 'Ümumi Günlər',
    finalEarnings: 'Son Gəlir',
    created: 'Yaradıldı',
    daysElapsed: 'Keçən Günlər',
    days: 'gün',
    earnings: 'Gəlir',
    fundsReleasedIn: 'Vəsait Buraxılacaq',
    claim: 'Tələb Et',

    updatePassword: 'ŞİFRƏNİ YENİLƏYİN',
    currentPassword: 'Cari Şifrə',
    newPassword: 'Yeni Şifrə',
    changePassword: 'Şifrəni Dəyiş',
    activationForGoogle: 'Google Authenticator üçün Aktivasiya Kodu',
    activateAndEnter:
        'Authenticator-u aktivləşdirin və aşağıdakı sahəyə yaradılmış kodu daxil edin',
    qrCode: 'QR Kodu',
    activateProtection: '2FA Mühafizəsini Aktiv Et',
    protectionActive: '2FA mühafizəsi aktivdir',
    twoFactorCode: '2FA Kodu',
    disableProtection: '2FA Mühafizəsini Deaktiv Et',
    language: 'Dil',
    dashboardCurrency: 'İdarə Paneli Valyutası',
    confirmNewPassword: 'Yeni şifrəni təsdiqləyin',

    yourLink: 'SİZİN TÖVSİYƏ LİNKİNİZ',
    referredFriends: 'TÖVSİYƏ EDİLƏN DOSTLAR',
    id: 'ID',
    name: 'Ad',
    registered: 'Qeydiyyat',
    level: 'Səviyyə',
    payout: 'Ödəniş',
    lastPayouts: 'SON TÖVSİYƏ ÖDƏNİŞLƏRİ',
    time: 'Vaxt',
    amount: 'Məbləğ',
    currency: 'Valyuta',
    info: 'Məlumat',

    Jan: 'Yan',
    Feb: 'Fev',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'May',
    Jun: 'İyun',
    Jul: 'İyul',
    Aug: 'Avq',
    Sep: 'Sen',
    Oct: 'Okt',
    Nov: 'Noy',
    Dec: 'Dek',

    orderTypes: [
      'Al',
      'Sat',
      'Alış Limit',
      'Satış Limit',
      'Alış Stop',
      'Satış Stop',
    ],
    statuses: ['Gözləyir', 'Təsdiqləndi', 'Rədd edildi', 'Emal olunur'],

    NOT_FOUND: 'Sorğu xətası',
    FORBIDDEN: 'Giriş qadağandır. Zəhmət olmasa yenidən daxil olun',
    SERVER_ERROR: 'Əməliyyat uğursuz oldu. Zəhmət olmasa sonra yenidən cəhd edin',
    INVALID_ACCOUNT: 'Sessiya müddəti bitib. Zəhmət olmasa yenidən daxil olun',
    INVALID_EMAIL: 'Yanlış e-poçt ünvanı',
    INVALID_PHONE: 'Yanlış telefon nömrəsi',
    INVALID_COUNTRY: 'Yanlış ölkə',
    INVALID_LOGIN: 'Yanlış giriş məlumatları',
    USER_BLOCKED: 'Hesab bloklanıb. Dəstək xidməti ilə əlaqə saxlayın',
    USER_REGISTERED:
        'Daxil edilmiş e-poçt və ya telefon nömrəsi ilə istifadəçi artıq qeydiyyatdan keçib',
    INVALID_USER: 'İstifadəçi tapılmadı',
    REJECTED: 'Əməliyyat platforma məhdudiyyətlərinə görə icazəsizdir',
    INVALID_OLD_PASSWORD: 'Köhnə şifrə yanlışdır',
    INVALID_SYMBOL: 'Yanlış ticarət simvolu',
    INVALID_SYMBOL_PRECISION: 'Yanlış simvol dəqiqliyi',
    INVALID_FRAME: 'Yanlış qrafik çərçivəsi',
    INVALID_CURRENCY: 'Yanlış valyuta',
    INVALID_PRODUCT: 'Yanlış ticarət hesabı növü',
    INSUFFICIENT_FUNDS: 'Yetersiz vəsait',
    INVALID_WITHDRAWAL: 'Çıxarış tapılmadı',
    INVALID_STATUS: 'Yanlış status',
    INVALID_FILE: 'Yanlış fayl genişləndirilməsi və ya fayl çox böyükdür',
    INVALID_DOCUMENT: 'Sənəd tapılmadı',
    INVALID_NAME: 'Yanlış ad',
    INVALID_LOT_SIZE: 'Yanlış lot ölçüsü',
    INVALID_LOT_STEP: 'Yanlış lot addımı',
    INVALID_MIN_VOLUME: 'Yanlış minimum həcm',
    INVALID_MAX_VOLUME: 'Yanlış maksimum həcm',
    INVALID_GROUP: 'Yanlış qrup',
    INVALID_SPREAD: 'Yanlış yayılma',
    REQUEST_TIMEOUT: 'Sorğunun vaxtı bitdi. Zəhmət olmasa sonra yenidən cəhd edin',
    INVALID_TYPE: 'Yanlış sifariş növü',
    INVALID_VOLUME: 'Yanlış sifariş həcmi',
    INVALID_SL: 'Yanlış stop loss dəyəri',
    INVALID_TP: 'Yanlış take profit dəyəri',
    INVALID_PRICE: 'Yanlış sifariş qiyməti',
    INVALID_EXPIRATION: 'Yanlış sifariş müddəti',
    NOT_ENOUGH_MARGIN: 'Kifayət qədər marja yoxdur',
    INVALID_ORDER: 'Sifariş tapılmadı',
    MARKET_CLOSED: 'Ticarət mövcud deyil. Zəhmət olmasa yenidən cəhd edin',
    INVALID_BALANCE_TYPE: 'Yanlış balans əməliyyatı növü',
    INVALID_HASH: 'Yanlış hash',
    HASH_EXPIRED:
        'Şifrə sıfırlama linkinizin müddəti bitib. Zəhmət olmasa yenisini tələb edin',
    INVALID_CODE: 'Yanlış 2FA kodu',
    CHAT_DISABLED: 'Dəstək çatına giriş bağlanıb',
    WITHDRAWAL_NOT_ALLOWED: 'Çıxarışlara giriş bağlanıb',
    TRADING_DISABLED: 'Cari hesab üçün ticarət deaktiv edilib',
    PENDING_DEPOSITS_LIMIT: 'Depozit limiti aşılıb',
    LOCAL_ONE_PENDING_WITHDRAWAL: 'Yalnız bir gözləyən çıxarış icazəlidir',
    LOCAL_DEPOSIT_ONLY_REAL: 'Depozit yalnız real hesab üçün mövcuddur',
    LOCAL_DEPOSIT_POSITIVE: 'Depozit məbləği 0-dan çox olmalıdır',
    LOCAL_INVALID_CARD_NUMBER: 'Yanlış kart nömrəsi',
    LOCAL_INVALID_CARD_EXPIRATION: 'Yanlış kart müddəti',
    LOCAL_INVALID_CVC: 'Yanlış CVV/CVC kodu',
    LOCAL_PASSWORDS_NOT_MATCH: 'Şifrələr uyğun gəlmir, zəhmət olmasa yenidən daxil edin',
    LOCAL_NO_TRADING_ACCOUNTS:
        'Mövcud ticarət hesabınız yoxdur. Zəhmət olmasa dəstək xidməti ilə əlaqə saxlayın',
    LOCAL_MIN_DEPOSIT: 'Minimum depozit $',
    LOCAL_MIN_WITHDRAWAL: 'Minimum çıxarış məbləği $',
    LOCAL_INVALID_FILE_TYPE:
        'Fayl formatı dəstəklənmir. Zəhmət olmasa yalnız JPG, PNG və ya PDF əlavə edin',
    LOCAL_MIN_STAKING: 'Bu valyuta üçün minimum depozit',
    FLOOD_REJECTED:
        'Çox tez-tez mesaj göndərirsiniz. Zəhmət olmasa sonra yenidən cəhd edin',
    countries: {
      AF: 'Əfqanıstan',
      AX: 'Aland Adaları',
      AL: 'Albaniya',
      DZ: 'Əlcəzair',
      AS: 'Amerika Samoası',
      AD: 'Andorra',
      AO: 'Anqola',
      AI: 'Angilya',
      AQ: 'Antarktika',
      AG: 'Antiqua və Barbuda',
      AR: 'Argentina',
      AM: 'Ermənistan',
      AW: 'Aruba',
      AU: 'Avstraliya',
      AT: 'Avstriya',
      AZ: 'Azərbaycan',
      BS: 'Bahamalar',
      BH: 'Bəhreyn',
      BD: 'Banqladeş',
      BB: 'Barbados',
      BY: 'Belarus',
      BE: 'Belçika',
      BZ: 'Beliz',
      BJ: 'Benin',
      BM: 'Bermud Adaları',
      BT: 'Butan',
      BO: 'Boliviya',
      BA: 'Bosniya və Herseqovina',
      BW: 'Botsvana',
      BV: 'Buve Adası',
      BR: 'Braziliya',
      IO: 'Britaniya Hind Okeanı Ərazisi',
      BN: 'Bruney-Darüssalam',
      BG: 'Bolqarıstan',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      KH: 'Kamboca',
      CM: 'Kamerun',
      CA: 'Kanada',
      CV: 'Kabo-Verde',
      KY: 'Kayman Adaları',
      CF: 'Mərkəzi Afrika Respublikası',
      TD: 'Çad',
      CL: 'Çili',
      CN: 'Çin',
      CX: 'Milad Adası',
      CC: 'Kokos (Kiling) Adaları',
      CO: 'Kolumbiya',
      KM: 'Komor Adaları',
      CG: 'Konqo',
      CD: 'Konqo Demokratik Respublikası',
      CK: 'Kuk Adaları',
      CR: 'Kosta-Rika',
      CI: "Kot-d'İvuar",
      HR: 'Xorvatiya',
      CU: 'Kuba',
      CY: 'Kipr',
      CZ: 'Çexiya',
      DK: 'Danimarka',
      DJ: 'Cibuti',
      DM: 'Dominika',
      DO: 'Dominikan Respublikası',
      EC: 'Ekvador',
      EG: 'Misir',
      SV: 'El-Salvador',
      GQ: 'Ekvatorial Qvineya',
      ER: 'Eritreya',
      EE: 'Estoniya',
      ET: 'Efiopiya',
      FK: 'Folklend Adaları (Malvin Adaları)',
      FO: 'Farer Adaları',
      FJ: 'Fici',
      FI: 'Finlandiya',
      FR: 'Fransa',
      GF: 'Fransa Qvianası',
      PF: 'Fransa Polineziyası',
      TF: 'Fransız Cənubi Əraziləri',
      GA: 'Qabon',
      GM: 'Qambiya',
      GE: 'Gürcüstan',
      DE: 'Almaniya',
      GH: 'Qana',
      GI: 'Cəbəllütariq',
      GR: 'Yunanıstan',
      GL: 'Qrenlandiya',
      GD: 'Qrenada',
      GP: 'Qvadelupa',
      GU: 'Quam',
      GT: 'Qvatemala',
      GG: 'Gernsi',
      GN: 'Qvineya',
      GW: 'Qvineya-Bisau',
      GY: 'Qayana',
      HT: 'Haiti',
      HM: 'Herd və Makdonald Adaları',
      VA: 'Müqəddəs Taxt (Vatikan Şəhər Dövləti)',
      HN: 'Honduras',
      HK: 'Honq-Konq',
      HU: 'Macarıstan',
      IS: 'İslandiya',
      IN: 'Hindistan',
      ID: 'İndoneziya',
      IR: 'İran',
      IQ: 'İraq',
      IE: 'İrlandiya',
      IM: 'Men Adası',
      IL: 'İsrail',
      IT: 'İtaliya',
      JM: 'Yamayka',
      JP: 'Yaponiya',
      JE: 'Cersi',
      JO: 'İordaniya',
      KZ: 'Qazaxıstan',
      KE: 'Keniya',
      KI: 'Kiribati',
      KR: 'Koreya',
      KW: 'Küveyt',
      KG: 'Qırğızıstan',
      LA: "Laos Xalq Demokratik Respublikası",
      LV: 'Latviya',
      LB: 'Livan',
      LS: 'Lesoto',
      LR: 'Liberiya',
      LY: 'Liviya Ərəb Camahiriyyəsi',
      LI: 'Lixtenşteyn',
      LT: 'Litva',
      LU: 'Lüksemburq',
      MO: 'Makao',
      MK: 'Şimali Makedoniya',
      MG: 'Madaqaskar',
      MW: 'Malavi',
      MY: 'Malayziya',
      MV: 'Maldiv Adaları',
      ML: 'Mali',
      MT: 'Malta',
      MH: 'Marşal Adaları',
      MQ: 'Martinik',
      MR: 'Mavritaniya',
      MU: 'Mavriki',
      YT: 'Mayotta',
      MX: 'Meksika',
      FM: 'Mikroneziya',
      MD: 'Moldova',
      MC: 'Monako',
      MN: 'Monqolustan',
      ME: 'Monteneqro',
      MS: 'Monserat',
      MA: 'Mərakeş',
      MZ: 'Mozambik',
      MM: 'Myanma',
      NA: 'Namibiya',
      NR: 'Nauru',
      NP: 'Nepal',
      NL: 'Niderland',
      AN: 'Niderland Antil Adaları',
      NC: 'Yeni Kaledoniya',
      NZ: 'Yeni Zelandiya',
      NI: 'Nikaraqua',
      NE: 'Niger',
      NG: 'Nigeriya',
      NU: 'Niu',
      NF: 'Norfolk Adası',
      MP: 'Şimal Marian Adaları',
      NO: 'Norveç',
      OM: 'Oman',
      PK: 'Pakistan',
      PW: 'Palau',
      PS: 'Fələstin Ərazisi, İşğal Altında',
      PA: 'Panama',
      PG: 'Papua Yeni Qvineya',
      PY: 'Paraqvay',
      PE: 'Peru',
      PH: 'Filippin',
      PN: 'Pitkern',
      PL: 'Polşa',
      PT: 'Portuqaliya',
      PR: 'Puerto Riko',
      QA: 'Qətər',
      RE: 'Reunion',
      RO: 'Rumıniya',
      RU: 'Rusiya',
      RW: 'Ruanda',
      BL: 'Müqəddəs Bartolomey',
      SH: 'Müqəddəs Yelena',
      KN: 'Sent-Kits və Nevis',
      LC: 'Sent-Lusiya',
      MF: 'Müqəddəs Martin',
      PM: 'Sent-Pyer və Mikelon',
      VC: 'Sent-Vinsent və Qrenadinlər',
      WS: 'Samoa',
      SM: 'San-Marino',
      ST: 'San-Tome və Prinsipi',
      SA: 'Səudiyyə Ərəbistanı',
      SN: 'Seneqal',
      RS: 'Serbiya',
      SC: 'Seyşel Adaları',
      SL: 'Syerra Leone',
      SG: 'Sinqapur',
      SK: 'Slovakiya',
      SI: 'Sloveniya',
      SB: 'Solomon Adaları',
      SO: 'Somali',
      ZA: 'Cənubi Afrika',
      GS: 'Cənubi Corciya və Sandviç Adaları',
      ES: 'İspaniya',
      LK: 'Şri Lanka',
      SD: 'Sudan',
      SR: 'Surinam',
      SJ: 'Svalbard və Yan-Mayen',
      SZ: 'Esvatini',
      SE: 'İsveç',
      CH: 'İsveçrə',
      SY: 'Suriya Ərəb Respublikası',
      TW: 'Tayvan',
      TJ: 'Tacikistan',
      TZ: 'Tanzaniya',
      TH: 'Tayland',
      TL: 'Timor-Leste',
      TG: 'Toqo',
      TK: 'Tokelau',
      TO: 'Tonqa',
      TT: 'Trinidad və Tobaqo',
      TN: 'Tunis',
      TR: 'Türkiyə',
      TM: 'Türkmənistan',
      TC: 'Türks və Kaykos Adaları',
      TV: 'Tuvalu',
      UG: 'Uqanda',
      UA: 'Ukrayna',
      AE: 'Birləşmiş Ərəb Əmirlikləri',
      GB: 'Böyük Britaniya',
      US: 'Amerika Birləşmiş Ştatları',
      UM: 'ABŞ Uzaq Adaları',
      UY: 'Uruqvay',
      UZ: 'Özbəkistan',
      VU: 'Vanuatu',
      VE: 'Venesuela',
      VN: 'Vyetnam',
      VG: 'Britaniya Virgin Adaları',
      VI: 'ABŞ Virgin Adaları',
      WF: 'Uollis və Futuna',
      EH: 'Qərbi Saxara',
      YE: 'Yəmən',
      ZM: 'Zambiya',
      ZW: 'Zimbabve',
    },
  }
}
