import { baseApi } from 'shared/api'
import { ordersDTO } from '../lib/ordersDTO'
import { ORDERS_TAG, ORDERS_HISTORY_TAG } from 'shared/api/tags'
import { ordersHistoryDTO } from '../lib/ordersHistoryDTO'

export const ordersApi = baseApi.injectEndpoints({
  endpoints: build => ({
    orders: build.query<any, void>({
      query: accountId => ({
        url: `/orders/cfd/list`,
        params: {
          account_id: accountId,
          status: 1,
        },
      }),
      providesTags: [ORDERS_TAG],
      transformResponse: ordersDTO,
    }),
    ordersHistory: build.query<any, void>({
      query: accountId => ({
        url: `/orders/cfd/list`,
        params: {
          account_id: accountId,
          status: 2,
        },
      }),
      providesTags: [ORDERS_HISTORY_TAG],
      transformResponse: ordersHistoryDTO,
    }),
    createOrder: build.mutation<any, void>({
      query: body => ({
        url: `/orders/cfd/create`,
        method: 'POST',
        body,
      }),

      transformResponse: ordersHistoryDTO,
    }),
    updateOrder: build.mutation<string, void>({
      query: ({ orderId, body }) => ({
        url: `/orders/cfd/update?order_id=${orderId}`,
        method: 'PUT',
        body,
      }),
      transformResponse: ordersHistoryDTO,
    }),
  }),
})

export const {
  useOrdersQuery,
  useOrdersHistoryQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
} = ordersApi
